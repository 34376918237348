import React, { ReactElement, ReactNode, memo, useState } from 'react'
import { HiOutlinePencil } from 'react-icons/hi'

import { Skeleton } from 'antd/es'

import classNames from 'classnames'

import InputField from '@/atoms/InputField'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import Form from '../Form'

import classes from './Title.module.less'

type EditableTitleProps =
  | { editable?: false; onSubmit?: never; value?: never }
  | { editable: true; onSubmit: (value: string) => void; value?: string }

type CommonProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  fontWeight?: 'default' | 'semibold' | 'bold' | 'medium'
  loading?: boolean
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  spaceSize?: 'base' | 'small' | 'medium' | 'large'
  className?: string
  children: ReactNode
}

export type TitleProps = CommonProps & EditableTitleProps

const Title = ({
  size = 'md',
  className = '',
  children,
  loading = false,
  fontWeight = 'default',
  as = 'h2',
  editable = false,
  spaceSize = 'base',
  onSubmit,
}: TitleProps): ReactElement => {
  const classnames = classNames(classes.title, classes[size], classes[spaceSize], className, {
    [classes[fontWeight]]: fontWeight !== 'default',
  })
  const Tag = as || 'h2'

  const [editing, setEditing] = useState(false)
  const [form] = Form.useForm<{ title: string }>()

  const onFinishEditing = async (): Promise<void> => {
    try {
      const { title } = await form.validateFields()
      setEditing(false)
      onSubmit?.(title)
    } catch (error) {
      // nothing to do here but our linter requires something here
    }
  }

  const onBlur = (): void => {
    onFinishEditing()
  }

  const onCancelEditing = (): void => {
    setEditing(false)
    form.resetFields()
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
    if (key === 'Enter') {
      onFinishEditing()
    } else if (key === 'Escape') {
      onCancelEditing()
    }
  }

  if (loading) {
    return <Skeleton.Input className={classes.skeleton} active={loading} size="large" />
  }

  if (!editing && !loading && editable) {
    return (
      <div onClick={() => setEditing(true)} className={classes.editableTitle}>
        <Tag className={classnames}>{children}</Tag>
        <HiOutlinePencil size={16} className={classes.editPencil} />
      </div>
    )
  }

  if (editable && editing) {
    return (
      <Form
        category={AnalyticsCategories.REPORTS}
        form={form}
        initialValues={{ title: String(children) }}
      >
        <Form.Item
          name="title"
          validateTrigger="onChange"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputField onBlur={onBlur} onKeyUp={onKeyUp} type="text" />
        </Form.Item>
      </Form>
    )
  }

  return <Tag className={classnames}>{children}</Tag>
}

export default memo(Title)
