import { ReactNode, useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'

import { Tabs } from 'antd/es'
import TabPane from 'antd/lib/tabs/TabPane'

export type TabMenuItem = {
  uri: string
  title: string | ReactNode
  key: string
  disabled?: boolean
}

function NavigationTabs({ menuItems }: { menuItems: TabMenuItem[] }): JSX.Element {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // Ensure more specific paths are checked first incase paths overlap
  const sortedMenuItems = [...menuItems].sort((a, b) => b.uri.length - a.uri.length)

  const currentTab = useMemo(
    () => sortedMenuItems.find((item) => pathname.startsWith(item.uri)) ?? sortedMenuItems[0],
    [sortedMenuItems, pathname],
  )

  return useMemo(
    () => (
      <Tabs
        activeKey={currentTab.uri}
        defaultActiveKey={menuItems[0].uri}
        onTabClick={(key) => navigate(key)}
      >
        {menuItems.map(({ uri, title, disabled }) => (
          <TabPane key={uri} tab={<span>{title}</span>} disabled={disabled}>
            <Outlet />
          </TabPane>
        ))}
      </Tabs>
    ),
    [currentTab, menuItems, navigate],
  )
}

export default NavigationTabs
