import React, { ReactElement, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { NavLink, useSearchParams } from 'react-router-dom'

import classNames from 'classnames'

import Pill from '@/atoms/Pill'
import Tooltip from '@/atoms/Tooltip'

import PaywallPill from '../PaywallPill'

import classes from './NavMenuItem.module.less'

export interface NavMenuItemProps {
  title: string | ReactElement
  icon?: ReactElement
  route?: string
  showFocusDot?: boolean
  isChild?: boolean
  isParent?: boolean
  active?: boolean
  newFeature?: boolean
  isDisabled?: boolean
  disabledTooltip?: string
  isSideBar?: boolean
  counter?: number
  onClick?: () => void
  isComingSoon?: boolean
  isCounter?: boolean
  isPaywalled?: boolean
  isParentPaywalled?: boolean
}

const NavMenuItem = ({
  title,
  icon,
  route,
  isChild,
  isParent,
  active,
  showFocusDot,
  newFeature,
  isDisabled,
  disabledTooltip,
  isSideBar,
  counter,
  onClick,
  isCounter = false,
  isComingSoon = false,
  isPaywalled,
  isParentPaywalled,
  ...rest
}: NavMenuItemProps): JSX.Element => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const isUpdatesActive = searchParams.get('allUpdates') === 'true'

  if (!route) {
    return (
      <div
        className={classNames(classes.menuItem, {
          [classes.disabled]: isDisabled && !isParent,
          [classes.isChild]: isChild,
          [classes.isParent]: isParent,
          [classes.isCounter]: isCounter,
          [classes.active]: isUpdatesActive && isSideBar,
        })}
        onClick={onClick}
        {...rest}
      >
        <div className={classes.innerWrapper}>
          {icon && <div className={classes.icon}>{icon}</div>}

          <span className={classes.title}>{title}</span>

          {!isParent && isComingSoon && (
            <Pill size="sm" className={classes.badge}>
              {t('coming-soon')}
            </Pill>
          )}
          {counter && !isDisabled && <div className={classes.counter}>{counter}</div>}
          {isPaywalled && isParent && (
            <PaywallPill className={classes.paywallPill} variant="dark" />
          )}
        </div>

        {isParent && (
          <div className={classes.chevron}>{active ? <HiChevronUp /> : <HiChevronDown />}</div>
        )}
      </div>
    )
  }

  if (isDisabled) {
    return (
      <Tooltip
        subTitle={disabledTooltip}
        placement="right"
        showArrow
        disabled={!disabledTooltip || isPaywalled}
      >
        <NavLink
          onClick={(e) => {
            e.stopPropagation()
            if (disabledTooltip && !isPaywalled) {
              e.preventDefault()
              return
            }
            onClick?.()
          }}
          to={route}
          className={classNames(classes.menuItem, classes.disabled, {
            [classes.isChild]: isChild,
            [classes.isParent]: isParent,
            [classes.isPaywalled]: isPaywalled,
          })}
          {...rest}
        >
          <div className={classes.moduleInnerWrapper}>
            {icon && <div className={classes.icon}>{icon}</div>}

            <span className={classes.title}>{title}</span>

            {showFocusDot && (
              <div className={classes.focusDot}>
                <div className={classes.dot} />
              </div>
            )}

            {newFeature && (
              <Pill className={classes.badge} textClasses={classes.badgeText}>
                {t('new')}
              </Pill>
            )}
            {!isParentPaywalled && isPaywalled && (
              <PaywallPill className={classes.paywallPill} variant="dark" textVariant="none" />
            )}
          </div>
        </NavLink>
      </Tooltip>
    )
  }

  return (
    <NavLink
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation()
          e?.preventDefault()
          onClick()
        }
      }}
      to={route}
      className={({ isActive }) =>
        classNames(classes.menuItem, {
          [classes.isChild]: isChild,
          [classes.isParent]: isParent,
          [classes.active]: !isUpdatesActive && isActive,
        })
      }
      {...rest}
    >
      <div className={classes.innerWrapper}>
        {icon && <div className={classes.icon}>{icon}</div>}
        <span className={classes.title}>{title}</span>
        {showFocusDot && (
          <div className={classes.focusDot}>
            <div className={classes.dot} />
          </div>
        )}
        {newFeature && (
          <Pill className={classes.badge} textClasses={classes.badgeText}>
            {t('new')}
          </Pill>
        )}
      </div>
    </NavLink>
  )
}

export default memo(NavMenuItem)
