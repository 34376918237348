/* eslint react-hooks/exhaustive-deps: 2 */
import { useCallback } from 'react'

import { routes } from '@cozero/utils'

export const ALLOWED_SUPPLIER_ROUTES = [
  routes.log.factors.base,
  routes.log.customers,
  routes.log.suppliers,
  routes.settings.base,
]

export const useSupplierRoute = (): { isSupplierRoute: (route: string) => boolean } => {
  const isSupplierRoute = useCallback(
    (route: string) => ALLOWED_SUPPLIER_ROUTES.some((path) => route.startsWith(path)),
    [],
  )

  return { isSupplierRoute }
}
