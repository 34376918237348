import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiDotsHorizontal, HiOutlineBookOpen, HiOutlineLogout, HiOutlineUser } from 'react-icons/hi'
import { HiOutlinePuzzlePiece } from 'react-icons/hi2'
import { useNavigate } from 'react-router'
import { useIntercom } from 'react-use-intercom'

import { Col, Dropdown, Menu, Row } from 'antd/es'

import { routes } from '@cozero/utils'

import PaywallModal from '@/molecules/PaywallModal'
import PaywallPill from '@/molecules/PaywallPill'

import Avatar from '@/atoms/Avatar'
import MenuItem from '@/atoms/Menu/MenuItem'
import Text from '@/atoms/Text'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import useSWRClearCache from '@/hooks/useSWRClearCache'
import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'

import classes from './NavUserMenu.module.less'

const UserMenu = ({ onLogoutHandler }: { onLogoutHandler: () => void }): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { show } = useIntercom()

  return (
    <Menu className={classes.menu}>
      <MenuItem
        category={AnalyticsCategories.NAVIGATION_SIDEBAR}
        action="profile"
        key="profile"
        className={classes.menuItem}
        icon={<HiOutlineUser size={13} />}
        onClick={() => navigate(routes.settings.profile)}
      >
        {t('nav.profile')}
      </MenuItem>

      <MenuItem
        category={AnalyticsCategories.NAVIGATION_SIDEBAR}
        action="integrations"
        key="integrations"
        className={classes.menuItem}
        icon={<HiOutlinePuzzlePiece size={14} />}
        onClick={() => navigate(routes.settings.integrations)}
      >
        {t('nav.integrations')}
      </MenuItem>

      <MenuItem
        key="wiki"
        className={classes.menuItem}
        onClick={show}
        category={AnalyticsCategories.NAVIGATION_SIDEBAR}
        icon={<HiOutlineBookOpen size={13} />}
        action="wiki"
      >
        {t('nav.wiki')}
      </MenuItem>

      <MenuItem
        category={AnalyticsCategories.NAVIGATION_SIDEBAR}
        action="logout"
        key="logout"
        icon={<HiOutlineLogout size={13} />}
        className={`${classes.menuItem} ${classes.danger}`}
        onClick={onLogoutHandler}
      >
        {t('nav.sign-out')}
      </MenuItem>
    </Menu>
  )
}

const NavUserMenu = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { trackAction } = useMixpanel()
  const clearCache = useSWRClearCache()

  const user = useAppSelector(selectUser)

  const [openModal, setOpenModal] = useState(false)

  const _handleLogout = (): void => {
    clearCache()
    navigate(routes.logout)
  }

  const userPricingType = user?.organization?.pricing?.type
  const isFreeUser = userPricingType === 'free' || userPricingType === 'supplier'

  const userName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim()
  const userEmail = user?.email ?? ''

  return (
    <Row align="middle" gutter={8} data-cy="user-name-display">
      <Col span={4}>
        <Avatar
          firstName={user?.firstName ?? ''}
          lastName={user?.lastName ?? ''}
          image={user?.photo}
          showTooltip={false}
          outlined
        />
      </Col>

      <Col span={17}>
        <div className={classes.text}>
          <div className={classes.userNameContainer}>
            <Text
              className={classes.userName}
              color="white"
              size="xl"
              fontWeight="medium"
              ellipsis
              title={userName}
            >
              {userName}
            </Text>

            {isFreeUser && (
              <PaywallPill
                className={classes.paywallPill}
                variant="dark"
                iconVariant="none"
                onClick={() => {
                  trackAction(AnalyticsCategories.FEATURE_LOCK, 'open-modal')
                  setOpenModal(true)
                }}
              >
                {t('paywall.free-pill')}
              </PaywallPill>
            )}
          </div>

          <Text color="secondary" ellipsis title={userEmail}>
            {userEmail}
          </Text>
        </div>
      </Col>

      <Col span={3}>
        <Dropdown
          overlay={<UserMenu onLogoutHandler={_handleLogout} />}
          placement="topCenter"
          align={{ offset: [108, -5] }}
        >
          <div className={classes.menuContainer} data-cy="3dot-user-menu">
            <span className={classes.iconWrapper}>
              <HiDotsHorizontal size={20} className={classes.icon} />
            </span>
          </div>
        </Dropdown>
      </Col>

      <PaywallModal
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        modalTitle={t('paywall.free-account.title')}
        description={t('paywall.free-account.description')}
        onClick={() => window.open('https://cozero.io/demo', '_blank')}
      />
    </Row>
  )
}

export default NavUserMenu
