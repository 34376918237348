import { useTranslation } from 'react-i18next'
import { HiOutlineCalendar } from 'react-icons/hi'
import { useNavigate } from 'react-router'
import { useIntercom } from 'react-use-intercom'

import { ApiIntegration } from '@cozero/models'
import { routes } from '@cozero/utils'

import Button from '@/atoms/Button'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { usePricingFeature } from '@/hooks/usePricingFeature'

interface IntegrationActionsProps {
  integration: ApiIntegration
}

const IntegrationActions = ({ integration }: IntegrationActionsProps): JSX.Element => {
  const { t } = useTranslation('common')
  const { isFeatureEnabled } = usePricingFeature()
  const navigate = useNavigate()
  const { showNewMessages } = useIntercom()

  const renderActionButton = (): JSX.Element => {
    if (integration.key === 'sunhat') {
      if (isFeatureEnabled('sunhat') && integration.active && integration.url) {
        return (
          <Button key={'login-sunhat'} type="link" href={integration.url} target={'_blank'}>
            {t('settings.integrations.actions.login-to-sunhat')}
          </Button>
        )
      }
      return (
        <Button
          key="schedule-call"
          type="primary"
          onClick={() =>
            showNewMessages(
              t('paywall.intercom.message', {
                featureName: `${integration.name} integration`,
              }),
            )
          }
          prefixIcon={<HiOutlineCalendar size={16} />}
        >
          {t('settings.integrations.actions.schedule-call')}
        </Button>
      )
    }

    return (
      <div>
        {integration.url ? (
          <Button key={`visit-website`} type="link" href={integration.url} target="_blank">
            {t('settings.integrations.actions.visit-website')}
          </Button>
        ) : (
          <Button
            key={`schedule-call`}
            onClick={() =>
              showNewMessages(
                t('paywall.intercom.message', { featureName: `${integration.name} integration` }),
              )
            }
          >
            {t('settings.integrations.actions.schedule-call')}
          </Button>
        )}
        <Button
          type="primary"
          onClick={() =>
            navigate(routes.settings.installIntegration.replace(':id', integration.id.toString()))
          }
          action="configure"
          category={AnalyticsCategories.API_INTEGRATIONS}
        >
          {t('settings.integrations.actions.configure')}
        </Button>
      </div>
    )
  }

  return <>{renderActionButton()}</>
}

export default IntegrationActions
