import React from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import PageNotFound from '@/pages/404'
import AccountAndBilling from '@/pages/AccountAndBilling'
import ApiKeysSettings from '@/pages/AccountAndBilling/ApiKeys'
import Data from '@/pages/AccountAndBilling/Data'
import SubscriptionSettings from '@/pages/AccountAndBilling/Subscription'

import AppProvider from '@/contexts/app'

import AdminSettingsRoute from '../AdminSettingsRoute'
import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'

export const AdminSettingsWrapper = ({
  feature,
  children,
  level,
}: {
  feature?: string
  children: React.ReactNode
  level?: 'admin' | 'manager'
}): ReactElement => (
  <AppProvider>
    <AdminSettingsRoute level={level}>
      <PrivateRoute>
        <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
      </PrivateRoute>
    </AdminSettingsRoute>
  </AppProvider>
)

const accountAndBillingRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.accountBilling.base,
    element: <AccountAndBilling />,
    breadcrumb: t('layout.accountBilling'),
    children: [
      {
        path: routes.accountBilling.subscription,
        breadcrumb: t('layout.subscription'),
        element: (
          <AdminSettingsWrapper level="admin">
            <SubscriptionSettings />
          </AdminSettingsWrapper>
        ),
      },
      {
        path: routes.accountBilling.api,
        breadcrumb: t('layout.api'),
        element: (
          <AdminSettingsWrapper>
            <ApiKeysSettings />
          </AdminSettingsWrapper>
        ),
      },
      {
        path: routes.accountBilling.data,
        breadcrumb: t('layout.data'),
        element: (
          <AdminSettingsWrapper level="admin">
            <Data />
          </AdminSettingsWrapper>
        ),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
  {
    path: `${routes.accountBilling.base}/*`,
    element: <PageNotFound />,
  },
]

export default accountAndBillingRoutes
