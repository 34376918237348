import { ConsolidatedGraphDto, WaterfallGraphDto } from '@cozero/dtos'
import {
  ActGraphData,
  BusinessUnit,
  BusinessUnitsContributionResultDto,
  ClimateAction,
  CostGraphData,
  WaterfallGraphData,
} from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

import {
  TAG_CONSOLIDATED_GRAPH,
  TAG_CONTRIBUTION_WATERFALL_GRAPH,
  TAG_COST_GRAPH,
  TAG_WATERFALL_GRAPH,
} from './constants'

const climateActionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWaterfallGraph: builder.query<WaterfallGraphData[], WaterfallGraphDto>({
      query: (data) => ({
        url: actApiGatewayClient.graphs.GET_WATERFALL_GRAPH,
        method: 'POST',
        data,
      }),
      providesTags: () => [{ type: TAG_WATERFALL_GRAPH }],
    }),
    getCostGraph: builder.query<
      CostGraphData[],
      { selectedActions: ClimateAction['id'][]; userBusinessUnitId?: BusinessUnit['id'] }
    >({
      query: ({ selectedActions, userBusinessUnitId }) => ({
        url: actApiGatewayClient.graphs.GET_COST_GRAPH,
        method: 'GET',
        params: {
          selectedActions,
          userBusinessUnitId,
        },
      }),
      providesTags: () => [{ type: TAG_COST_GRAPH }],
    }),
    getConsolidatedGraph: builder.query<ActGraphData[], ConsolidatedGraphDto>({
      query: (data) => ({
        url: actApiGatewayClient.graphs.GET_CONSOLIDATED_GRAPH,
        method: 'POST',
        data,
      }),
      providesTags: () => [{ type: TAG_CONSOLIDATED_GRAPH }],
    }),
    getContributionWaterfallGraph: builder.query<BusinessUnitsContributionResultDto, void>({
      query: (data) => ({
        url: actApiGatewayClient.graphs.GET_CONTRIBUTION_WATERFALL_GRAPH_DATA,
        method: 'POST',
        data,
      }),
      providesTags: () => [{ type: TAG_CONTRIBUTION_WATERFALL_GRAPH }],
    }),
  }),
})

export const {
  useGetWaterfallGraphQuery,
  usePrefetch,
  useGetCostGraphQuery,
  useGetConsolidatedGraphQuery,
  useGetContributionWaterfallGraphQuery,
} = climateActionsApiSlice

export default climateActionsApiSlice
