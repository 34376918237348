import { useTranslation } from 'react-i18next'
import { HiOutlineRefresh } from 'react-icons/hi'

import Button from '@/atoms/Button'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useCreateLeoUpdatesMutation } from '@/redux/leoUpdates/api'

import { useLeoLastUpdatedTime } from '../hooks/useLeoLastUpdateTime'

import classes from './LastUpdateText.module.less'

export const LastUpdateText = (): JSX.Element | null => {
  const { t } = useTranslation('common')

  const { legend, icon, isFetching } = useLeoLastUpdatedTime()
  const [createLeoUpdates, { isLoading: isCreatingLeoUpdates }] = useCreateLeoUpdatesMutation()

  return (
    <div className={classes.container}>
      <Tooltip showArrow content={t('log.log-entries-overview.last-update.tooltip')}>
        <div className={classes.content}>
          <span>
            {icon}
            {legend}
          </span>
        </div>
      </Tooltip>

      <Button
        className={classes.refreshButton}
        prefixIcon={<HiOutlineRefresh />}
        loading={isFetching || isCreatingLeoUpdates}
        disabled={isFetching || isCreatingLeoUpdates}
        onClick={() => createLeoUpdates()}
        category={AnalyticsCategories.LOG_ENTRY_OVERVIEW}
        action="refresh-button-clicked"
      >
        {t('log.log-entries-overview.last-update.button')}
      </Button>
    </div>
  )
}
