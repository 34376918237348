import React from 'react'

import { OrganizationCarbonFootprintSearchDto } from '@cozero/dtos'

import { OrganizationCarbonFootprintFormValues } from '@/pages/Log/LocationLogs/OrganizationCarbonFootprintFiltersDrawer/hooks/useOrganizationCarbonFootprintFormConfiguration'

export const SLICE_NAME = 'logEntriesOverview'

export type SelectedRows = {
  keys: React.Key[]
  ids: number[]
  rows: OrganizationCarbonFootprintSearchDto[]
}

export type OrganizationEmissionsDateFilter = [string | null, string | null]

export const DEFAULT_STATE: {
  selectedRows: SelectedRows
  filters: OrganizationCarbonFootprintFormValues
  dateFilter: OrganizationEmissionsDateFilter
  currentPageResults?: OrganizationCarbonFootprintSearchDto[]
} = {
  filters: {},
  selectedRows: {
    keys: [],
    ids: [],
    rows: [],
  },
  dateFilter: [null, null],
}
