import { ReactElement, ReactNode, Suspense, lazy } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import NotFoundPage from '@/pages/404'
import ClosedPeriods from '@/pages/ClosedPeriods'
import AddClosedPeriod from '@/pages/ClosedPeriods/AddClosedPeriod'
import { CalculationChangesIndex } from '@/pages/Log/Factors/CalculationChangesIndex'
import EmissionFactors from '@/pages/Log/Factors/EmissionFactors'
import FactorRequests from '@/pages/Log/Factors/FactorRequests/RequestsOverview'
import LocationLogs from '@/pages/Log/LocationLogs'
import ProductDetails from '@/pages/Log/ProductDetails'
import ProductsCarbonFootPrint from '@/pages/Log/ProductsCarbonFootPrint'
import QuantitiesLogs from '@/pages/Log/Quantities'
import SupplierOnboarding from '@/pages/Onboarding/Supplier'

import CustomReportCrumb from '@/molecules/Breadcrumbs/CustomReportCrumbs'
import EditLogCrumb from '@/molecules/Breadcrumbs/EditLogCrumb'
import {
  SupplierBulkDetailsCrumb,
  SupplierRequestCrumb,
} from '@/molecules/Breadcrumbs/FactorRequestCrumbs'

import FactorFilterProvider from '@/contexts/factorFilter'
import FiltersProvider from '@/contexts/filters'
import LogProvider from '@/contexts/log'
import { useOrgIsSupplier } from '@/hooks/useOrgIsSupplier'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

const SupplierBulkRequestDetails = lazy(
  () =>
    import(
      '@/pages/Log/BulkImport/SupplierBulkRequest/SupplierBulkRequestDetails/SupplierBulkRequestDetails'
    ),
)
const SupplierBulkRequestOverview = lazy(
  () =>
    import(
      '@/pages/Log/BulkImport/SupplierBulkRequest/SupplierBulkRequestOverview/SupplierBulkRequestsOverview'
    ),
)
const CcfBulkImport = lazy(() => import('@/pages/Log/BulkImport/CcfBulkImport'))
const CcfBulkImportDetails = lazy(
  () => import('@/pages/Log/BulkImport/CcfBulkImport/CcfBulkImportDetails'),
)
const CreateBulkImport = lazy(() => import('@/pages/Log/BulkImport/CreateBulkImport'))
const PcfBulkImports = lazy(() => import('@/pages/Log/BulkImport/PcfBulkImports'))
const PcfBulkImportDetails = lazy(
  () => import('@/pages/Log/BulkImport/PcfBulkImports/PcfBulkImportDetails'),
)
const Suppliers = lazy(() => import('@/pages/Organization/Suppliers'))
const Customers = lazy(() => import('@/pages/Organization/Customers'))
const EditLog = lazy(() => import('@/pages/Log/EditLog'))
const Factors = lazy(() => import('@/pages/Log/Factors'))
const Tags = lazy(() => import('@/pages/Log/Tags'))
const CustomReport = lazy(() => import('@/pages/Share/Reports/CustomReport'))

const IncomingRequests = lazy(
  () => import('@/pages/Log/Factors/FactorRequests/pages/IncomingRequests'),
)
const OutgoingRequests = lazy(
  () => import('@/pages/Log/Factors/FactorRequests/pages/OutgoingRequests'),
)

const RedirectLocationLogs = (): ReactElement => {
  const { locationId } = useParams()

  return (
    <Navigate
      to={{ pathname: routes.organization.locations.logs.replace(':locationId', locationId || '') }}
    />
  )
}

const RedirectRequestsPage = (): ReactElement => {
  const orgIsSupplier = useOrgIsSupplier()
  return (
    <Navigate
      to={{
        pathname: orgIsSupplier
          ? routes.log.factors.requestsPage.incomingRequest
          : routes.log.factors.requestsPage.outgoingRequest,
      }}
    />
  )
}

const Wrapper = ({
  feature = 'log',
  children,
  excludeRoles,
}: {
  excludeRoles?: string[]
  feature?: string
  children: ReactNode
}): ReactElement => (
  <LogProvider>
    <PrivateRoute>
      <FeatureRoute requiredFeature={feature} excludeRoles={excludeRoles}>
        <Suspense fallback={<SuspenseSpinner />}>{children}</Suspense>
      </FeatureRoute>
    </PrivateRoute>
  </LogProvider>
)

const getLogRoutes = (t: TFunction): BreadcrumbsRoute[] =>
  [
    {
      path: routes.log.base,
      props: { preventNavigation: true },
      element: (
        <Wrapper>
          <Navigate to={{ pathname: routes.log.base }} />
        </Wrapper>
      ),
    },
    {
      path: routes.organization.locations.singleLocation,
      element: <RedirectLocationLogs />,
    },
    {
      path: routes.log.tags,
      element: (
        <Wrapper excludeRoles={['viewer']}>
          <Tags />
        </Wrapper>
      ),
      breadcrumb: t('layout.tags'),
    },
    {
      path: routes.organization.locations.logs,
      element: (
        <Wrapper>
          <FiltersProvider>
            <LocationLogs />
          </FiltersProvider>
        </Wrapper>
      ),
    },
    {
      path: routes.organization.locations.editLog,
      element: (
        <Wrapper>
          <EditLog />
        </Wrapper>
      ),
    },
    {
      path: routes.log.closedPeriods.customReport,
      element: (
        <Wrapper>
          <FiltersProvider saveQueryString={false}>
            <CustomReport />
          </FiltersProvider>
        </Wrapper>
      ),
      breadcrumb: CustomReportCrumb,
    },
    {
      path: routes.log.corporateFootprint.base,
      element: (
        <Wrapper>
          <FiltersProvider>
            <LocationLogs />
          </FiltersProvider>
        </Wrapper>
      ),
      breadcrumb: t('layout.corporate-footprint'),
    },
    {
      path: routes.log.corporateFootprint.bulkImport.base,
      element: (
        <Wrapper>
          <CcfBulkImport />
        </Wrapper>
      ),
      breadcrumb: t('log.bulkImport'),
    },
    {
      path: routes.log.corporateFootprint.bulkImport.details,
      element: (
        <Wrapper>
          <CcfBulkImportDetails />
        </Wrapper>
      ),
    },
    {
      path: routes.log.corporateFootprint.bulkImport.create,
      element: (
        <Wrapper>
          <CreateBulkImport />
        </Wrapper>
      ),
      breadcrumb: t('actions.bulk-import.create'),
    },
    {
      path: routes.log.corporateFootprint.bulkImport.list,
      element: (
        <Wrapper>
          <CreateBulkImport />
        </Wrapper>
      ),
      breadcrumb: t('actions.bulk-import.past-imports'),
    },
    {
      path: routes.log.corporateFootprint.quantities,
      element: (
        <Wrapper>
          <FiltersProvider>
            <QuantitiesLogs />
          </FiltersProvider>
        </Wrapper>
      ),
      breadcrumb: t('layout.products'),
    },
    {
      path: routes.log.products.base,
      element: (
        <Wrapper>
          <FiltersProvider>
            <ProductsCarbonFootPrint />
          </FiltersProvider>
        </Wrapper>
      ),
      breadcrumb: t('layout.product-footprint'),
    },
    {
      path: routes.log.corporateFootprint.edit,
      element: (
        <Wrapper>
          <EditLog />
        </Wrapper>
      ),
      breadcrumb: EditLogCrumb,
    },
    {
      path: routes.log.products.edit,
      element: (
        <Wrapper>
          <EditLog />
        </Wrapper>
      ),
    },
    {
      path: routes.log.products.bulkImports,
      element: (
        <Wrapper feature="pcf-bulk-import">
          <PcfBulkImports />
        </Wrapper>
      ),
      breadcrumb: t('log.bulkImport'),
    },
    {
      path: routes.log.products.productDetails,
      element: (
        <Wrapper>
          <FiltersProvider>
            <ProductDetails />
          </FiltersProvider>
        </Wrapper>
      ),
    },
    {
      path: routes.log.factors.base,
      element: (
        <Wrapper>
          <Factors />
        </Wrapper>
      ),
      children: [
        {
          index: true,
          element: (
            <Wrapper>
              <Navigate to={{ pathname: routes.log.factors.requestsPage.base }} />
            </Wrapper>
          ),
        },
        {
          path: routes.log.factors.emissions,
          element: (
            <Wrapper>
              <FiltersProvider>
                <FactorFilterProvider>
                  <EmissionFactors />
                </FactorFilterProvider>
              </FiltersProvider>
            </Wrapper>
          ),
        },
        {
          path: routes.log.factors.calculations.base,
          element: (
            <Wrapper>
              <CalculationChangesIndex />
            </Wrapper>
          ),
        },
        {
          path: routes.log.factors.requestsPage.base,
          element: (
            <Wrapper feature="custom-factors">
              <FactorRequests />
            </Wrapper>
          ),
          children: [
            {
              index: true,
              element: (
                <Wrapper>
                  <RedirectRequestsPage />
                </Wrapper>
              ),
            },
            {
              path: routes.log.factors.requestsPage.outgoingRequest,
              element: (
                <Wrapper feature="custom-factors">
                  <OutgoingRequests />
                </Wrapper>
              ),
              breadcrumb: t('customer-requests.outgoing-request'),
            },
            {
              path: routes.log.factors.requestsPage.incomingRequest,
              element: (
                <Wrapper feature="custom-factors">
                  <IncomingRequests />
                </Wrapper>
              ),
              breadcrumb: t('customer-requests.incoming-request'),
            },
          ],
        },
      ],
      breadcrumb: t('layout.factors-breadcrumb'),
    },
    {
      path: routes.log.factors.bulkRequest.base,
      element: (
        <Wrapper>
          <SupplierBulkRequestOverview />
        </Wrapper>
      ),
    },
    {
      path: routes.log.factors.bulkRequest.details,
      element: (
        <Wrapper>
          <SupplierBulkRequestDetails />
        </Wrapper>
      ),
      breadcrumb: SupplierBulkDetailsCrumb,
    },
    {
      path: routes.log.factors.requestsPage.details,
      element: (
        <Wrapper feature="custom-factors">
          <SupplierOnboarding />
        </Wrapper>
      ),
      breadcrumb: SupplierRequestCrumb,
    },
    {
      path: routes.log.closedPeriods.base,
      element: (
        <Wrapper feature="log">
          <ClosedPeriods />
        </Wrapper>
      ),
      breadcrumb: t('layout.closed-periods'),
    },
    {
      path: routes.log.closedPeriods.addClosedPeriod,
      element: (
        <Wrapper feature="log">
          <FiltersProvider>
            <AddClosedPeriod />
          </FiltersProvider>
        </Wrapper>
      ),
      breadcrumb: t('actions.create'),
    },
    {
      path: routes.log.closedPeriods.editClosedPeriod,
      element: (
        <Wrapper feature="log">
          <FiltersProvider>
            <AddClosedPeriod />
          </FiltersProvider>
        </Wrapper>
      ),
      breadcrumb: t('customer.edit-btn'),
    },
    {
      path: routes.log.suppliers,
      element: (
        <Wrapper excludeRoles={['viewer']}>
          <Suppliers />
        </Wrapper>
      ),
      breadcrumb: t('layout.suppliers'),
    },
    {
      path: routes.log.customers,
      element: (
        <Wrapper excludeRoles={['viewer']}>
          <Customers />
        </Wrapper>
      ),
      breadcrumb: t('layout.customers'),
    },
    {
      path: routes.log.products.bulkImportDetails,
      element: (
        <Wrapper feature="pcf-bulk-import">
          <PcfBulkImportDetails />
        </Wrapper>
      ),
    },
    {
      path: `${routes.log.base}/*`,
      element: (
        <Wrapper>
          <NotFoundPage />
        </Wrapper>
      ),
    },
  ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[]

export default getLogRoutes
