import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useNavigate } from 'react-router'

import { Col, Row } from 'antd/es'

import { BusinessUnit } from '@cozero/models'

import BusinessUnitsTable from '@/molecules/BusinessUnitsTable'

import Title from '@/atoms/Title'

import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin } from '@/redux/auth'
import { useGetBusinessUnitQuery } from '@/redux/businessUnits'

import classes from './classes.module.less'

const SubUnits = ({ id, disabled }: { id?: string; disabled: boolean }): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const { data: businessUnit, isLoading: businessUnitLoading } = useGetBusinessUnitQuery(
    { id: id ?? '' },
    { skip: !id },
  )

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-between" className={classes.header}>
          <Col>
            <Title size="xs" className={classes.tableTitle}>
              {t('settings.bu.subunits')}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <BusinessUnitsTable
              loading={businessUnitLoading}
              loadedBusinessUnits={(businessUnit?.children as BusinessUnit[]) ?? []}
              isManagerOrAdmin={isManagerOrAdmin}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SubUnits
