import { createSlice } from '@reduxjs/toolkit'

import { CalculationData, Page } from '@cozero/models'

import { SLICE_NAME_USER } from './constants'

export interface MethodologyChangelogState {
  methodologyChangelogs: Page<CalculationData>
  selectedCalculationChangelogRecord: CalculationData | undefined
}

const initialState: MethodologyChangelogState = {
  methodologyChangelogs: {
    total: 0,
    data: [],
  } as Page<CalculationData>,
  selectedCalculationChangelogRecord: undefined,
}

const methodlogyChangelogSlice = createSlice({
  name: SLICE_NAME_USER,
  initialState,
  reducers: {},
})

export default methodlogyChangelogSlice
