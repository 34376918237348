import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HiOutlineMinusCircle, HiTrash } from 'react-icons/hi'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { Col, Row, Spin, message } from 'antd/es'

import { routes } from '@cozero/utils'

import { AdvancedOption } from '@/molecules/AdvancedOption'
import BusinessUnitsDropdown from '@/molecules/BusinessUnitsDropdown'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import {
  selectSelectedBusinessUnit,
  useDeleteBusinessUnitMutation,
  useDisableBusinessUnitMutation,
  useGetActiveBusinessUnitsQuery,
  useGetBusinessUnitQuery,
  useTransferBusinessUnitMutation,
} from '@/redux/businessUnits'

import classes from './classes.module.less'

const Advanced = ({ id }: { id?: string }): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const [parentToTransferId, setParentToTransferId] = useState<number>()
  const [deleteBusinessUnit] = useDeleteBusinessUnitMutation()
  const [disableBusinessUnit] = useDisableBusinessUnitMutation()
  const [transferBusinessUnit, { isLoading: transferLoading }] = useTransferBusinessUnitMutation()
  const { data: businessUnits = [] } = useGetActiveBusinessUnitsQuery(
    { businessUnitScopeId: selectedBusinessUnit?.id ?? -1 },
    { skip: !selectedBusinessUnit },
  )

  const { data: businessUnit, isLoading: businessUnitLoading } = useGetBusinessUnitQuery(
    { id: id ?? '' },
    { skip: !id },
  )

  const onTransferBusinessUnit = async (): Promise<void> => {
    try {
      if (businessUnit && parentToTransferId) {
        const res = await transferBusinessUnit({
          businessUnitId: businessUnit.id,
          newParentId: parentToTransferId,
        }).unwrap()
        if (res) {
          message.success(t('settings.bu.success-edit'))
        }
      }
    } catch (error) {
      message.error(t('settings.bu.error-generic'))
    }
  }

  const onDeleteBusinessUnit = async (): Promise<void> => {
    try {
      if (businessUnit) {
        const result = await deleteBusinessUnit(`${businessUnit.id}`).unwrap()
        if (result) {
          navigate(routes.organizationSettings.businessUnits.base)
        }
      }
    } catch (error) {
      message.error(error.data.message, 5)
    }
  }

  const onDisableBusinessUnit = async (): Promise<void> => {
    try {
      if (businessUnit) {
        const result = await disableBusinessUnit(`${businessUnit.id}`).unwrap()
        if (result) {
          navigate(routes.organizationSettings.businessUnits.base)
          return message.success(t('settings.bu.disable.successful'))
        }
      }
    } catch (error) {
      message.error(t('settings.bu.disable.unsuccessful'))
    }
  }

  const businessUnitsWithoutCurrent = useMemo(
    () =>
      businessUnits.filter(
        (obj) =>
          obj.id.toString() !== id?.toString() &&
          businessUnit &&
          obj.id.toString() !== businessUnit?.parentUnitId?.toString() &&
          !obj.ancestorIds.includes(businessUnit?.id),
      ),
    [businessUnits],
  )

  return (
    <Spin spinning={businessUnitLoading}>
      <Row>
        <Col span={24}>
          <Row className={classes.header}>
            <Col span={24}>
              <Card className={classes.dangerZone}>
                <Title size="xs" className={classes.dangerZoneTitle}>
                  {t('settings.bu.transfer.title')}
                </Title>

                <Text size="xl" className={classes.subtitle}>
                  <ul>
                    <li>{t('settings.bu.transfer.subtitle-1')}</li>

                    <li>{t('settings.bu.transfer.subtitle-2')}</li>
                  </ul>
                </Text>
                <BusinessUnitsDropdown
                  onChange={(value) => setParentToTransferId(parseInt(value))}
                  businessUnits={businessUnitsWithoutCurrent}
                  showNoParent={!!businessUnit?.parentUnit}
                  defaultValue={parentToTransferId}
                />
                <Button
                  category={AnalyticsCategories.BUSINESS_UNIT}
                  action="transfer"
                  disabled={!parentToTransferId}
                  onClick={onTransferBusinessUnit}
                  type="secondary"
                  className={classes.dangerZoneButton}
                  loading={transferLoading}
                >
                  {t('settings.bu.transfer.title')}
                </Button>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      {businessUnit?.active && businessUnit?.parentUnitId !== null && (
        <AdvancedOption
          title={t('settings.bu.disable.title')}
          subtitle={
            <div>
              <Text size="xl">
                <Trans
                  t={t}
                  i18nKey="settings.bu.disable.subtitle.title"
                  values={{ title: businessUnit.title }}
                />
              </Text>
              <ul className={classes.deactivateList}>
                <li>
                  <span>{t('settings.bu.disable.subtitle.line-1')}</span>
                </li>
                <li>
                  <span>{t('settings.bu.disable.subtitle.line-2')}</span>
                </li>
                <li>
                  <span>{t('settings.bu.disable.subtitle.line-3')}</span>
                </li>
                <li>
                  <span>{t('settings.bu.disable.subtitle.line-4')}</span>
                </li>
              </ul>
            </div>
          }
          question={t('settings.bu.disable.subtitle.question')}
          popConfirmTitle={
            <Text size="xl">
              <Trans t={t} i18nKey="settings.bu.disable.warning" />
            </Text>
          }
          action={onDisableBusinessUnit}
          btnProperties={{
            category: AnalyticsCategories.BUSINESS_UNIT,
            style: 'ghost',
            icon: <HiOutlineMinusCircle />,
          }}
        />
      )}

      {businessUnit?.parentUnitId !== null && (
        <AdvancedOption
          title={t('settings.bu.delete.title')}
          subtitle={
            <>
              <Text size="xl">
                <Trans
                  t={t}
                  i18nKey="settings.bu.delete.subtitle.title"
                  values={{ title: businessUnit?.title }}
                />
              </Text>
              <ul className={classes.deactivateList}>
                <li>
                  <span>{t('settings.bu.delete.subtitle.line-1')}</span>
                </li>
                <li>
                  <span>{t('settings.bu.delete.subtitle.line-2')}</span>
                </li>
              </ul>
            </>
          }
          question={t('settings.bu.delete.warning')}
          popConfirmTitle={
            <Text size="xl">
              <Trans t={t} i18nKey="settings.bu.delete.warning" />
            </Text>
          }
          action={onDeleteBusinessUnit}
          btnProperties={{ category: AnalyticsCategories.BUSINESS_UNIT, icon: <HiTrash /> }}
        />
      )}
    </Spin>
  )
}

export default Advanced
