/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Tabs } from 'antd/es'

import { routes } from '@cozero/utils'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppSelector } from '@/redux'
import { getIsAdmin } from '@/redux/auth'

const AccountAndBilling = (): ReactElement => {
  const { t } = useTranslation()
  const { TabPane } = Tabs
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isAdmin = useAppSelector(getIsAdmin)
  const { isFeatureEnabled } = usePricingFeature()

  const MENU_ITEMS = useMemo(() => {
    const items: { uri: string; title: string }[] = []

    if (isAdmin) {
      items.push({
        uri: routes.accountBilling.subscription,
        title: t('layout.subscription'),
      })
    }

    if (isAdmin && isFeatureEnabled('api')) {
      items.push({
        uri: routes.accountBilling.api,
        title: t('layout.api'),
      })
    }

    if (isAdmin && isFeatureEnabled('data-backup')) {
      items.push({
        uri: routes.accountBilling.data,
        title: t('layout.data'),
      })
    }

    return items
  }, [isAdmin, isFeatureEnabled, t])

  const currentTab = useMemo(
    () => MENU_ITEMS.find((item) => pathname.startsWith(item.uri)) ?? MENU_ITEMS[0],
    [MENU_ITEMS, pathname],
  )

  if (routes.accountBilling.base === pathname) {
    return <Navigate to={MENU_ITEMS[0].uri} replace />
  }

  return (
    <Tabs
      activeKey={currentTab.uri}
      defaultActiveKey={MENU_ITEMS[0].uri}
      onTabClick={(key) => navigate(key)}
    >
      {MENU_ITEMS.map(({ uri, title }) => (
        <TabPane key={uri} tab={title}>
          <Outlet />
        </TabPane>
      ))}
    </Tabs>
  )
}

export default AccountAndBilling
