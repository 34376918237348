import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Skeleton } from 'antd/es'

import { WarningOutlined } from '@ant-design/icons'
import classNames from 'classnames'

import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import { selectEmissionsForNode } from '@/redux/organigram/slice'
import { formatCompact } from '@/utils/number'

import classes from './NodeEmissions.module.less'

const formatEmissionNumber = (value?: string | number | boolean): string => {
  const number = Number(value ?? 0)

  return formatCompact(number, {
    minimumFractionDigits: 0,
    maximumFractionDigits: number < 1000 ? 2 : 0,
  })
}

type NodeEmissionsProps = {
  isFetchingEmissions: boolean
  isErrorEmissions: boolean
  nodeId: number | string | undefined | boolean
  isLocation: boolean
}

export const NodeEmissions = ({
  isFetchingEmissions,
  isErrorEmissions,
  nodeId,
  isLocation,
}: NodeEmissionsProps): JSX.Element => {
  const { t } = useTranslation('common')
  const emissions = useSelector((state) =>
    selectEmissionsForNode(state, { nodeId: nodeId, isLocation: isLocation }),
  )
  const tonsUnit = t('log.units.co2')

  if (isFetchingEmissions || emissions.total === undefined) {
    return (
      <div>
        <div className={classes.totalEmissionsContainer}>
          {!isErrorEmissions ? (
            <Skeleton.Input className={classes.longSkeleton} active={true} size="small" />
          ) : (
            <Tooltip content={t('organigram.errorEmissions.tooltip')}>
              <Tag size="sm" type={'default'} icon={<WarningOutlined />}>
                {t(`organigram.errorEmissions.message`)}
              </Tag>
            </Tooltip>
          )}
        </div>

        <div className={classes.scopeContainer}>
          <div>
            <Tooltip title={t('organigram.scope', { number: 1 })}>
              <Skeleton.Input
                className={classes.skeletonScope1}
                active={!isErrorEmissions}
                size="small"
              />
            </Tooltip>
          </div>

          <div>
            <Tooltip title={t('organigram.scope', { number: 2 })}>
              <Skeleton.Input
                className={classes.skeletonScope2}
                active={!isErrorEmissions}
                size="small"
              />
            </Tooltip>
          </div>

          <div>
            <Tooltip title={t('organigram.scope', { number: 3 })}>
              <Skeleton.Input
                className={classes.skeletonScope3}
                active={!isErrorEmissions}
                size="small"
              />
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className={classes.totalEmissionsContainer}>
        <Tag showDot className={classes.tags}>
          <Text fontWeight="medium">{`${formatEmissionNumber(emissions.total)} ${tonsUnit}`}</Text>
        </Tag>
      </div>

      <div className={classes.scopeContainer}>
        <div>
          <Tooltip title={t('organigram.scope', { number: 1 })}>
            <Tag size="sm" className={classNames(classes.tags, classes.scope1)}>
              <Text fontWeight="medium">
                {`${formatEmissionNumber(emissions.scope1)} ${tonsUnit}`}
              </Text>
            </Tag>
          </Tooltip>
        </div>

        <div>
          <Tooltip title={t('organigram.scope', { number: 2 })}>
            <Tag size="sm" className={classNames(classes.tags, classes.scope2)}>
              <Text fontWeight="medium">
                {`${formatEmissionNumber(emissions.scope2)} ${tonsUnit}`}
              </Text>
            </Tag>
          </Tooltip>
        </div>

        <div>
          <Tooltip title={t('organigram.scope', { number: 3 })}>
            <Tag size="sm" className={classNames(classes.tags, classes.scope3)}>
              <Text fontWeight="medium">
                {`${formatEmissionNumber(emissions.scope3)} ${tonsUnit}`}
              </Text>
            </Tag>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
