import { ApiKey } from '@cozero/models'
import { authApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { CREATE_API_KEY, DELETE_API_KEY, GET_API_KEYS, TAG_API_KEYS } from './constants'

const apiKeysSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_API_KEYS]: builder.query<ApiKey[], void>({
      query: () => ({
        url: authApiGatewayClient.apiKeys.GET_MANY,
        method: 'GET',
      }),
      providesTags: (result) => providesList<ApiKey[], typeof TAG_API_KEYS>(result, TAG_API_KEYS),
    }),
    [CREATE_API_KEY]: builder.mutation<string, void>({
      query: () => ({
        url: authApiGatewayClient.apiKeys.CREATE,
        method: 'POST',
      }),
      invalidatesTags: [TAG_API_KEYS],
    }),
    [DELETE_API_KEY]: builder.mutation<ApiKey, ApiKey['id']>({
      query: (id) => ({
        url: authApiGatewayClient.apiKeys.DELETE_ONE.replace(':id', `${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [{ type: TAG_API_KEYS, id: result?.id }],
    }),
  }),
})

export const { useGetApiKeysQuery, useCreateApiKeyMutation, useDeleteApiKeyMutation, usePrefetch } =
  apiKeysSlice

export default apiKeysSlice
