import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { OrganizationCarbonFootprintSearchDto } from '@cozero/dtos'

import { OrganizationCarbonFootprintFormValues } from '@/pages/Log/LocationLogs/OrganizationCarbonFootprintFiltersDrawer/hooks/useOrganizationCarbonFootprintFormConfiguration'

import {
  DEFAULT_STATE,
  OrganizationEmissionsDateFilter,
  SLICE_NAME,
  SelectedRows,
} from './constants'

const logEntriesOverviewSlice = createSlice({
  name: SLICE_NAME,
  initialState: DEFAULT_STATE,
  reducers: {
    setOrganizationEmissionsFilters: (
      state,
      action: PayloadAction<OrganizationCarbonFootprintFormValues>,
    ) => {
      if (state.filters) {
        state.filters = action.payload
      }
    },
    setOrganizationEmissionsDateFilter: (
      state,
      action: PayloadAction<OrganizationEmissionsDateFilter>,
    ) => {
      if (state.dateFilter) {
        state.dateFilter = action.payload
      }
    },
    resetOrganizationEmissionsFilters: (state) => {
      state.filters = DEFAULT_STATE.filters
    },
    setSelectedRows: (state, action: PayloadAction<SelectedRows>) => {
      state.selectedRows = action.payload
      return state
    },
    resetSelectedRows: (state) => {
      state.selectedRows = DEFAULT_STATE.selectedRows
      return state
    },
    setCurrentPageResults: (
      state,
      action: PayloadAction<OrganizationCarbonFootprintSearchDto[]>,
    ) => {
      state.currentPageResults = action.payload
    },
  },
})

export const {
  setOrganizationEmissionsFilters,
  setOrganizationEmissionsDateFilter,
  resetOrganizationEmissionsFilters,
  setSelectedRows,
  resetSelectedRows,
  setCurrentPageResults,
} = logEntriesOverviewSlice.actions

export default logEntriesOverviewSlice
