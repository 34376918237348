import React, { Suspense } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import BusinessUnits from '@/pages/OrganizationSettings/BusinessUnits'
import Locations from '@/pages/OrganizationSettings/Locations'
import OrganizationInfo from '@/pages/OrganizationSettings/OrganizationInfo'
import { OrganizationSettings } from '@/pages/OrganizationSettings/OrganizationSettings'
import Revenue from '@/pages/OrganizationSettings/Revenue'
import UpsertBusinessUnit from '@/pages/OrganizationSettings/UpsertBusinessUnit'
import UpsertLocation from '@/pages/OrganizationSettings/UpsertLocation'
import UpsertUser from '@/pages/OrganizationSettings/UpsertUser'
import UserFunctions from '@/pages/OrganizationSettings/UserFunctions'
import Users from '@/pages/OrganizationSettings/Users'

import BusinessUnitCrumb from '@/molecules/Breadcrumbs/BusinessUnitCrumb'
import LocationCrumb from '@/molecules/Breadcrumbs/LocationCrumb'
import UserCrumb from '@/molecules/Breadcrumbs/UserCrumb'

import AppProvider from '@/contexts/app'
import FiltersProvider from '@/contexts/filters'
import LogProvider from '@/contexts/log'

import AdminSettingsRoute from '../AdminSettingsRoute'
import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

export const AdminSettingsWrapper = ({
  feature,
  children,
  level,
}: {
  feature?: string
  children: React.ReactNode
  level?: 'admin' | 'manager'
}): ReactElement => (
  <AppProvider>
    <AdminSettingsRoute level={level}>
      <PrivateRoute>
        <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
      </PrivateRoute>
    </AdminSettingsRoute>
  </AppProvider>
)

const Wrapper = ({
  feature = 'log',
  children,
  excludeRoles,
}: {
  excludeRoles?: string[]
  feature?: string
  children: React.ReactNode
}): ReactElement => (
  <LogProvider>
    <PrivateRoute>
      <FeatureRoute requiredFeature={feature} excludeRoles={excludeRoles}>
        <Suspense fallback={<SuspenseSpinner />}>{children}</Suspense>
      </FeatureRoute>
    </PrivateRoute>
  </LogProvider>
)

const organizationSettingsRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.organizationSettings.base,
    element: <OrganizationSettings />,
    breadcrumb: t('layout.organizationSettings'),
    children: [
      {
        path: routes.organizationSettings.locations.base,
        element: (
          <Wrapper>
            <FiltersProvider>
              <Locations />
            </FiltersProvider>
          </Wrapper>
        ),
        breadcrumb: t('layout.locations'),
      },
      {
        path: routes.organizationSettings.businessUnits.base,
        element: (
          <Wrapper>
            <FiltersProvider>
              <BusinessUnits />
            </FiltersProvider>
          </Wrapper>
        ),
        breadcrumb: t('layout.business-units'),
      },
      {
        path: routes.organizationSettings.revenue,
        element: (
          <Wrapper>
            <Revenue />
          </Wrapper>
        ),
        breadcrumb: t('layout.revenue'),
      },
      {
        path: routes.organizationSettings.users.base,
        breadcrumb: t('layout.users'),
        element: (
          <AdminSettingsWrapper level="manager">
            <Users />
          </AdminSettingsWrapper>
        ),
      },
      {
        path: routes.organizationSettings.userFunctions,
        breadcrumb: t('layout.tabs.functions'),
        element: (
          <AdminSettingsWrapper>
            <FeatureRoute requiredFeature="user-functions">
              <UserFunctions />
            </FeatureRoute>
          </AdminSettingsWrapper>
        ),
      },
      {
        breadcrumb: t('settings.organization.title'),
        path: routes.organizationSettings.organizationInfo,
        element: (
          <AdminSettingsWrapper>
            <OrganizationInfo />
          </AdminSettingsWrapper>
        ),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
  {
    path: routes.organizationSettings.users.addUser,
    element: (
      <AdminSettingsWrapper level="manager">
        <UpsertUser />
      </AdminSettingsWrapper>
    ),
  },
  {
    path: routes.organizationSettings.users.editUser,
    element: (
      <AdminSettingsWrapper level="manager">
        <UpsertUser />
      </AdminSettingsWrapper>
    ),
    breadcrumb: UserCrumb,
  },
  {
    path: routes.organizationSettings.businessUnits.add,
    element: (
      <Wrapper>
        <FiltersProvider>
          <AdminSettingsRoute level="manager">
            <UpsertBusinessUnit />
          </AdminSettingsRoute>
        </FiltersProvider>
      </Wrapper>
    ),
  },
  {
    path: routes.organizationSettings.businessUnits.edit,
    element: (
      <Wrapper>
        <FiltersProvider>
          <AdminSettingsRoute level="manager">
            <UpsertBusinessUnit />
          </AdminSettingsRoute>
        </FiltersProvider>
      </Wrapper>
    ),
    breadcrumb: BusinessUnitCrumb,
  },
  {
    path: routes.organizationSettings.locations.add,
    element: (
      <Wrapper>
        <AdminSettingsRoute level="manager">
          <UpsertLocation />
        </AdminSettingsRoute>
      </Wrapper>
    ),
  },
  {
    path: routes.organizationSettings.locations.edit,
    element: (
      <Wrapper>
        <AdminSettingsRoute level="manager">
          <UpsertLocation />
        </AdminSettingsRoute>
      </Wrapper>
    ),
    breadcrumb: LocationCrumb,
  },
]

export default organizationSettingsRoutes
