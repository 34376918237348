import React, { ReactNode } from 'react'
import { HiOutlineLockClosed } from 'react-icons/hi'
import { IconType } from 'react-icons/lib'
import { Link, LinkProps } from 'react-router-dom'

import { Col, Image, Row } from 'antd'

import classnames from 'classnames'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'

import { LifecycleStepCardProps } from '../LifecycleStepCard'

import classes from './LinkCard.module.less'

type Props = Pick<LifecycleStepCardProps, 'description' | 'title' | 'isDisabled'> & {
  to: LinkProps['to']
  btnLabel: string
  icon?: IconType
  imageSrc?: string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  badge?: ReactNode
}

const LinkCard = ({
  imageSrc,
  description,
  title,
  to,
  isDisabled,
  btnLabel,
  icon,
  onClick,
  badge,
}: Props): React.ReactElement => {
  const stepClassNames = classnames(classes.step, isDisabled && classes.disabled)

  const renderContent = (): React.ReactElement => {
    return (
      <Card
        className={stepClassNames}
        shadow="none"
        actions={[
          // eslint-disable-next-line react/jsx-key
          <Button prefixIcon={isDisabled ? <HiOutlineLockClosed /> : null} type="link">
            {btnLabel}
          </Button>,
        ]}
      >
        {badge && <div className={classes.badge}>{badge}</div>}
        <Row className={classes.contentWrapper}>
          <Col>
            <Row justify="center">
              {imageSrc && (
                <Image
                  preview={false}
                  className={`${classes.image} ${classes.textAlign}`}
                  src={imageSrc}
                />
              )}
              {icon && icon({ className: `${classes.image} ${classes.textAlign}`, size: 72 })}
            </Row>
            <Row>
              <Col className={`${classes.detailsContainer} ${classes.textAlign}`}>
                <Col className={classes.title}>{title}</Col>
                <Col className={classes.description}>{description}</Col>
              </Col>
              <div className={`${classes.detailsContainer} ${classes.textAlign}`}></div>
            </Row>
          </Col>
        </Row>
      </Card>
    )
  }

  if (isDisabled) {
    return renderContent()
  }

  return (
    <Link
      to={to}
      onClick={(e) => onClick?.(e)}
      style={{ textDecoration: 'none', color: 'inherit' }}
      aria-disabled={isDisabled}
    >
      {renderContent()}
    </Link>
  )
}

export default React.memo(LinkCard)
