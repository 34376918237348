export const ErrorCodes = {
  // General
  GENERIC_ERROR: {
    message: 'There was an error with your request.',
    status: 400,
    statusCode: 'GN1',
  },
  NOT_FOUND: {
    message: 'Resource not found.',
    status: 404,
    statusCode: 'GN2',
  },
  FORBIDDEN: {
    message: 'Access Denied',
    status: 403,
    statusCode: 'GN3',
  },
  MISSING_PARAMETERS: {
    message: 'Your request is missing required parameters',
    status: 400,
    statusCode: 'GN4',
  },
  MAX_PAGE_SIZE_EXCEEDED: {
    message: 'Your request is exceeding the max page size!',
    status: 400,
    statusCode: 'GN5',
  },
  ALREADY_EXISTS: {
    message: 'Resource Already Exists',
    status: 409,
    statusCode: 'GN6',
  },

  // Auth
  INVITER_NON_EXISTENT: { statusCode: 'AU1', message: 'Inviter does not exist' },
  USER_ALREADY_EXISTS: { statusCode: 'AU2', message: 'User already exists' },
  USER_UNAUTHORIZED: {
    statusCode: 'AU3',
    message: 'User unauthorized',
  },
  TOKEN_MISSING: { statusCode: 'AU4', message: 'The token is missing' },
  MISSING_ORGANISATION: { statusCode: 'AU5', message: 'Organization is missing in the request' },
  USER_BLOCKED: { statusCode: 'AU6', message: 'User is blocked' },
  LOGIN_FAILED: { statusCode: 'AU7', message: 'Login failed' },
  USER_NON_EXISTENT: { statusCode: 'AU8', message: 'User does not exist' },
  ACTIVATE_ACCOUNT_FAILED: { statusCode: 'AU9', message: 'Activate account failed' },
  MAGIC_LINK_EXPIRED: {
    statusCode: 'AU10',
    message: 'Please check the new link we sent to your email',
  },

  // Calculations
  BULK_IMPORT_NOT_EXISTING: { statusCode: 'CC1', message: 'Bulk import does not exist' },
  LOG_TYPE_UNEXISTING: { statusCode: 'CC2', message: 'Log type does not exist' },
  CHANGELOG_ID_UNEXISTING: {
    statusCode: 'CC3',
    message: 'No changelog IDs passed in',
    status: 401,
  },

  // Central
  BUSINESS_UNIT_OUT_OF_SCOPE: { statusCode: 'CT1', message: 'Business unit out of scope' },
  CREATE_BUSINESS_UNIT_MANAGER_ERROR: {
    statusCode: 'CT2',
    message: 'A manager cannot create a business unit without a parent one',
  },
  MISSING_USER_BUSINESS_UNIT: {
    statusCode: 'CT3',
    message: 'Missing user business unit',
  },
  MANAGER_NOT_ADMIN: {
    statusCode: 'CT4',
    message: 'The manager is not an admin of this parent Business Unit',
  },
  MANAGER_NOT_MANAGER: {
    statusCode: 'CT5',
    message: 'Managers can only transfer to a Business Unit they manage',
  },
  TRANSFER_BUSINESS_UNIT_ERROR: {
    statusCode: 'CT6',
    message:
      'Cannot transfer to a Business Unit that is a child of this one. Please select a valid Business Unit.',
  },
  ADDRESS_NOT_VALID: {
    statusCode: 'CT7',
    message: 'Address is not valid',
  },
  MISSING_BUSINESS_UNIT: {
    statusCode: 'CT8',
    message: 'Resource has no business unit',
  },
  USER_NOT_FOUND: {
    statusCode: 'CT9',
    message: 'User not found',
  },
  TEMPLATE_NOT_FOUND: {
    statusCode: 'CT10',
    message: 'Template not found',
  },
  EMAIL_NOT_FOUND: {
    statusCode: 'CT11',
    message: 'Email not found',
  },
  CREATE_ORGANIZATION: {
    statusCode: 'CT12',
    message: 'There was a problem creating the organization. Please contact our support.',
  },
  CREATE_ORGANIZATION_URL_TAKEN: {
    statusCode: 'CT13',
    message: 'The organization URL you chose is already taken',
  },
  TRANSFER_PRODUCT: {
    statusCode: 'CT14',
    message:
      'Cannot transfer to a Product that is a child of this one. Please select a valid Product.',
  },
  ORGANIZATION_NOT_FOUND: {
    statusCode: 'CT14',
    message: 'Organization not found.',
  },
  PAYMENT_FAILED: {
    statusCode: 'CT15',
    message: 'Failed to process payment.',
  },
  SAME_PASSWORD: {
    statusCode: 'CT16',
    message: 'Cannot be the same password as the previous one.',
  },
  WRONG_PASSWORD: {
    statusCode: 'CT17',
    message: 'Current password is wrong',
  },
  USER_UPDATE: {
    statusCode: 'CT18',
    message: 'There was a problem updating your profile. Please try again or contact support.',
  },
  DELETE_BUSINESS_UNIT_FORBIDDEN_LOCATION: {
    statusCode: 'CT19',
    message:
      'This business unit cannot be deleted since it has locations assigned. Please unassign those locations first.',
  },
  DELETE_BUSINESS_UNIT_FORBIDDEN_PRODUCT: {
    statusCode: 'CT20',
    message:
      'This business unit cannot be deleted since it has products assigned. Please unassign those products first.',
  },
  DELETE_BUSINESS_UNIT_FORBIDDEN_PARENT: {
    statusCode: 'CT20',
    message:
      'This business unit cannot be deleted since it has child business units. Please delete those first.',
  },
  USER_EXISTS: {
    statusCode: 'CT21',
    message: 'User already exists',
    status: 409,
  },
  INVALID_FILE: {
    statusCode: 'CT22',
    message: 'Invalid file name',
  },
  VAT_MISSING: {
    statusCode: 'CT23',
    message: 'VAT ID is missing',
  },
  OVER_LIMIT: {
    statusCode: 'CT24',
    message: 'Over limit',
  },
  CREATE_BUSINESS_UNIT_ROOT_EXISTS: {
    statusCode: 'CT25',
    message:
      'Root business unit already exists. Please select a parent unit for this business unit.',
  },
  PRODUCT_NAME_ALREADY_EXISTS: {
    statusCode: 'CT26',
    message: 'The name already exists. Please try with another name.',
  },
  SUPPLIER_NOT_FOUND: {
    statusCode: 'CT27',
    message: 'Supplier not found.',
  },
  ROLE_NOT_FOUND: {
    statusCode: 'CT27',
    message: 'Role not found.',
  },
  MISSING_PARENT_BUSINESS_UNIT: {
    statusCode: 'CT28',
    message: 'Missing parent business unit',
  },
  DUPLICATE_PRODUCT_CODE_VERSION: {
    statusCode: 'CT29',
    message: 'Product code and version pair already exists',
  },
  PRODUCT_TAG_INVALID_CHARACTERS: {
    statusCode: 'CT30',
    message: 'Product tag contains invalid characters',
  },
  // Log
  MISSING_LOCATION: {
    statusCode: 'LS1',
    message: 'Location is missing in the request',
  },
  MISSING_PRODUCT: {
    statusCode: 'LS2',
    message: 'Product is missing in the request',
  },
  BAD_LOG_PARAMETER: {
    statusCode: 'LS3',
    status: 401,
    message: 'parameter is missing or not valid.',
  },
  MISSING_LOCATION_OR_PRODUCT: {
    statusCode: 'LS4',
    status: 401,
    message: 'Either locationId or productId are necessary in the request',
  },
  MISSING_DATES: {
    statusCode: 'LS5',
    status: 401,
    message: 'Dates startDate and endDate are missing in the request',
  },
  MISSING_VERSION: {
    statusCode: 'LS6',
    status: 401,
    message: 'The log entry inputs need to have a version',
  },
  LOG_ENTRY_TAG_EXISTING: {
    statusCode: 'LS7',
    status: 400,
    message: 'That log entry tag already exists',
  },
  PRODUCT_MISSING_DATES: {
    statusCode: 'LS8',
    status: 401,
    message: 'Product date interval is necessary before setting the quantity date interval.',
  },
  QUANTITIES_WRONG_DATES: {
    statusCode: 'LS9',
    status: 401,
    message: 'Quantity date interval needs to be the within the product date interval',
  },
  QUANTITIES_TAG_INVALID_CHARACTERS: {
    statusCode: 'LS10',
    status: 401,
    message: 'Quantity tag contains invaid characters',
  },
  LOG_ENTRY_TAG_INVALID_CHARACTERS: {
    statusCode: 'LS11',
    status: 400,
    message: 'Log entry tag contains invaid characters',
  },
  EXPERIMENTAL_CATEGORY: {
    statusCode: 'LS12',
    status: 400,
    message: 'Category can only be created with bulk import',
  },

  // Reports
  MAXIMUM_REPORTS_EXCEEDED_ERROR: {
    statusCode: 'R1',
    message: 'You can only select 10 reports at most',
  },
  INVALID_COMPUTED_REPORT_TYPE: {
    statusCode: 'R2',
    status: 400,
    message: 'Invalid computed report type',
  },

  // Exports
  EXPORT_ERROR: {
    statusCode: 'E1',
    message: 'Error exporting data',
  },
  // Act
  ORDER_NOT_FOUND: {
    statusCode: 'AS1',
  },
  TARGET_NOT_FOUND: {
    statusCode: 'AS2',
    message: 'Target not found',
  },
  FORECAST_IN_PROGRESS: {
    statusCode: 'AS3',
    message: 'Forecast already in progress',
  },
  FORECAST_ERROR: {
    statusCode: 'AS3',
    message: 'Error requesting forecast',
  },
  FORECAST_FILE_KEY_MISSING: {
    statusCode: 'AS3',
    message: 'file_key missing from request',
  },
  NO_EMISSIONS_FOR_TARGET_REFERENCE_YEAR: {
    statusCode: 'AS4',
    message: 'Could not find any emissions for the given reference year',
  },
  // Share
  DELETING_STORY_IMAGE_ERROR: {
    statusCode: 'S1',
    message: 'Error deleting image',
  },
  // Factor Requests
  SAME_ORG: {
    status: 422,
    statusCode: 'FR1',
    message: 'suppliers.factor.error.same-org-target',
  },

  // Task management
  MILESTONE_OWNER_NOT_AUTHORIZED: {
    status: 400,
    statusCode: 'TM1',
    message: 'Milestone owner is not authorized to create a milestone for this business unit',
  },
  MILESTONE_SUPPORTERS_NOT_AUTHORIZED: {
    status: 400,
    statusCode: 'TM2',
    message: 'Milestone supporters are not authorized to create a milestone for this business unit',
  },
  TASK_OWNER_NOT_AUTHORIZED: {
    status: 400,
    statusCode: 'TM3',
    message: 'Task owner is not authorized to create a task for this business unit',
  },
  TASK_BUSINESS_UNIT_FORBIDDEN: {
    status: 403,
    statusCode: 'TM4',
    message: 'Cannot create task for this business unit',
  },
} as const
