import { LeoUpdateResponseDto } from '@cozero/dtos'
import { logApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

const TAG_LEO_UPDATES = 'LEO_UPDATES'

const leoUpdatesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createLeoUpdates: builder.mutation<LeoUpdateResponseDto, void>({
      query: () => ({
        method: 'POST',
        url: logApiGatewayClient.logEntriesUpdates.CREATE_DATA_UPDATE,
      }),
      invalidatesTags: [TAG_LEO_UPDATES],
    }),
    getLeoUpdates: builder.query<LeoUpdateResponseDto, void>({
      query: () => ({
        method: 'GET',
        url: logApiGatewayClient.logEntriesUpdates.GET_DATA_UPDATES,
      }),
      providesTags: () => [{ type: TAG_LEO_UPDATES }],
    }),
  }),
})

export const { useGetLeoUpdatesQuery, useCreateLeoUpdatesMutation } = leoUpdatesSlice

export default leoUpdatesSlice
