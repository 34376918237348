import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineDuplicate } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { Col, Row, Space, Spin, Tag } from 'antd/es'

import { omit } from 'lodash-es'

import { BusinessUnit, Product } from '@cozero/models'
import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { copyToClipboard } from '@/utils/clipboard'
import { truncate } from '@/utils/string'

import classes from './ProductsTable.module.less'

interface Props {
  products: Product[]
  getProduct: (id: number) => Promise<Product | void>
  businessUnitsAllowed: boolean
  isManagerOrAdmin: boolean
  loading?: boolean
}

function ProductsTable({
  products: loadedProducts,
  getProduct,
  businessUnitsAllowed,
  isManagerOrAdmin,
  loading = false,
}: Props): JSX.Element {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [products, setProducts] = useState<Product[]>(loadedProducts || [])

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a: Product, b: Product) => a.name.localeCompare(b.name),
      width: 350,
    },
    ...(businessUnitsAllowed
      ? [
          {
            title: t('business-unit.name'),
            dataIndex: ['businessUnit', 'title'],
            key: 'businessUnit',
            sorter: (a: Product, b: Product) =>
              (a.businessUnit as BusinessUnit)?.title.localeCompare(
                (b.businessUnit as BusinessUnit)?.title,
              ),
            width: 100,
          },
        ]
      : []),
    {
      title: t('product.responsible'),
      dataIndex: ['responsible', 'email'],
      key: 'responsible',
      sorter: (a: Product, b: Product) =>
        a.responsible?.email.localeCompare(b.responsible?.email || '') || 0,
      width: 100,
    },
    {
      title: t('product.active'),
      dataIndex: 'active',
      key: 'active',
      render(_text: string, record: Product) {
        return record.active ? <Tag color="green">{t('yes')}</Tag> : <Tag>{t('no')}</Tag>
      },
    },
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render(text: string) {
        const idString = `${text}`
        return (
          <Row align="middle" justify="start">
            <Col>
              <Tooltip title={idString}>
                <Text size="xl" code>
                  {truncate(idString, 5)}
                </Text>
              </Tooltip>
            </Col>
            <Col className={classes.copyIcon} onClick={() => copyToClipboard(idString, t)}>
              <Tooltip title={t('actions.copy.subtitle')}>
                <HiOutlineDuplicate />
              </Tooltip>
            </Col>
          </Row>
        )
      },
    },
    {
      title: t('actions.title'),
      key: 'action',
      render(text: string, record: Product) {
        return (
          <Space className={classes.actions}>
            {isManagerOrAdmin && (
              <Button
                onClick={(event) => {
                  event.stopPropagation()
                  navigate(
                    routes.log.products.upsert.stepsEdit.replace(':id', record.id.toString()),
                  )
                }}
                category={AnalyticsCategories.PRODUCTS}
                action="Go to edit product page"
                type="primary"
              >
                {t('actions.edit.title')}
              </Button>
            )}
          </Space>
        )
      },
    },
  ]

  // This function was taken from Ant Design docs
  function updateTreeData(list: Product[], id: React.Key, children: Product[]): Product[] {
    const cleanChildren = children.map((child) => {
      if (child.children?.length === 0) {
        child = omit(child, 'children') as Product
      }
      return child
    })
    return list.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          children: cleanChildren,
        }
      } else if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children as Product[], id, cleanChildren),
        }
      }
      return node
    })
  }

  async function getChildrenRows(record: Product): Promise<void> {
    const parentProduct = await getProduct(record.id)
    if (parentProduct) {
      const newTree = updateTreeData(
        products,
        record.id,
        (parentProduct.children as Product[]) || [],
      )
      setProducts(newTree)
    }
  }

  useEffect(() => {
    setProducts(loadedProducts)
  }, [loadedProducts])

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={products}
        expandable={{
          onExpand: (_expanded: boolean, record: Product) => getChildrenRows(record),
        }}
        className={classes.table}
        rowKey="id"
        onRow={(row) => ({
          onClick: () => {
            navigate(routes.log.products.update.base.replace(':id', row.id.toString()))
          },
        })}
      />
    </Spin>
  )
}

export default ProductsTable
