import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineBookOpen, HiOutlineFilter, HiPlus } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { Col, Row } from 'antd/es'

import { debounce, isEmpty } from 'lodash-es'

import { PageFilter, PageType } from '@cozero/models'
import { routes } from '@cozero/utils'

import { EmptyStateCard } from '@/organisms/Onboarding/EmptyStateCard'
import ResourceCenterCard from '@/organisms/ResourceCenterCard'

import BusinessUnitsTable from '@/molecules/BusinessUnitsTable'
import FiltersDrawer from '@/molecules/FiltersDrawer'

import Button from '@/atoms/Button'
import InputField from '@/atoms/InputField'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin } from '@/redux/auth'
import { selectSelectedBusinessUnit, useGetBusinessUnitsQuery } from '@/redux/businessUnits'

import classes from './classes.module.less'

const BusinessUnits = (): ReactElement => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const [searchTerm, setSearchTerm] = useState('')
  const [filters, setFilters] = useState<PageFilter[]>([])
  const {
    data: businessUnits = [],
    isLoading,
    isError,
  } = useGetBusinessUnitsQuery(
    {
      businessUnitScopeId: selectedBusinessUnit?.id ?? -1,
      search: searchTerm,
      populate: true,
      root: isEmpty(searchTerm),
      filters,
    },
    { skip: !selectedBusinessUnit },
  )
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)

  function onSearchBusinessUnits(filters: PageFilter[]): void {
    setFilters(filters)
  }

  async function openDrawer(): Promise<void> {
    setFilterDrawerOpen(true)
  }

  if (!isLoading && !isError && !businessUnits) {
    return (
      <Row className={classes.businessUnitEmptyState}>
        <Col span={24}>
          <EmptyStateCard
            title={t('businessunits.onboarding.title')}
            description={t('businessunits.onboarding.description')}
            buttonText={t('businessunits.onboarding.title')}
            type={t('businessunits.onboarding.business-unit')}
            onClick={() => navigate(routes.organizationSettings.businessUnits.add)}
            onClose={function (): void {
              throw new Error('Function not implemented.')
            }}
            className={classes.onboardingCard}
          />
        </Col>
        <ResourceCenterCard title={t('onboarding.demo.resource-card-title')} />
      </Row>
    )
  }

  return (
    <Row>
      <Col span={24} data-cy="view-business-units">
        <Row className={classes.section} gutter={16}>
          <Col span={24}>
            <Title as="h1" size="sm">
              {t('settings.bu.title')}
            </Title>
          </Col>
          <Col xl={12} lg={16} span={24}>
            <Text size="xl" color="secondary">
              {t('settings.bu.subtitle')}
            </Text>
          </Col>
          <Col span={24}>
            <Button
              prefixIcon={<HiOutlineBookOpen size={20} />}
              href={t('intercom.onboarding.businessUnits')}
              type="link"
              target="_blank"
              rel="noreferrer"
              className={classes.learnLink}
            >
              {t('settings.bu.learn')}
            </Button>
          </Col>
        </Row>
        <Row align="middle" justify="end" gutter={16} className={classes.section}>
          <Col xs={8} lg={6}>
            <InputField
              type="search"
              placeholder={t('settings.bu.search')}
              loading={isLoading}
              size="middle"
              onChange={debounce((event) => setSearchTerm(event.target.value), 500)}
            />
          </Col>
          <Col>
            <Button
              onClick={openDrawer}
              category={AnalyticsCategories.BUSINESS_UNIT}
              action={'open-filter'}
              prefixIcon={<HiOutlineFilter />}
            >
              {t('log.filter.add')}
            </Button>
          </Col>
          {isManagerOrAdmin && (
            <Col>
              <Button
                type="primary"
                action={'add'}
                category={AnalyticsCategories.BUSINESS_UNIT}
                onClick={() => navigate(routes.organizationSettings.businessUnits.add)}
                prefixIcon={<HiPlus />}
                fillParent
                data-cy="create-business-unit-btn"
              >
                {t('business-unit.create-businessunit-btn')}
              </Button>
            </Col>
          )}
        </Row>
        <Row className={classes.section}>
          <Col span={24}>
            <BusinessUnitsTable
              loading={isLoading}
              loadedBusinessUnits={businessUnits}
              isManagerOrAdmin={isManagerOrAdmin}
            />
          </Col>
        </Row>
      </Col>
      <FiltersDrawer
        visible={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        search={onSearchBusinessUnits}
        pageType={PageType.LOCATION_LIST}
        filterOptions={[]}
      />
    </Row>
  )
}

export default BusinessUnits
