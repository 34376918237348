import { use } from 'i18next'
import moment, { Moment } from 'moment'

import i18n from '@/i18n'

import { dateFormat } from './config'

interface DateRange {
  startDate: Date
  endDate: Date
}

export function isWithinDateRange<T extends DateRange>(
  array: T[],
  dateRange: [Moment | null, Moment | null],
): T[] {
  const mStartDate = moment(dateRange[0])
  const mEndDate = moment(dateRange[1])
  return array.filter((obj) => {
    let valid: boolean
    const objStartDate = moment(obj.startDate)
    if (obj.endDate) {
      const objEndDate = moment(obj.endDate)
      valid = mEndDate.diff(objStartDate) >= 0 && mStartDate.diff(objEndDate) <= 0
    } else {
      valid = mStartDate.diff(objStartDate) <= 0 && mEndDate.diff(objStartDate) >= 0
    }
    return valid
  })
}

/**
 * @deprecated Use `@/hooks/useFormatDateTime` instead for date formatting.
 *
 * @param date The date to format, either as a `Date` object or a string.
 * @param format Optional string to specify the desired date format.
 * @param options Options to control whether to use local time. Defaults to `true`.
 * @returns The formatted date string.
 */
export const prettifyDate = (
  date: Date | string,
  format?: string,
  { useLocalTime }: { useLocalTime?: boolean } = { useLocalTime: true },
): string => {
  return useLocalTime
    ? moment(date)
        .local()
        .format(format ?? dateFormat ?? 'll')
    : moment(date)
        .utc()
        .format(format ?? dateFormat ?? 'll')
}

/**
 * Returns an array of dates between the start and end date
 * @param difference - how many days between the start and end date
 * @returns an array of dates
 */
export const generateListOfYears = (difference = 10): number[] => {
  const currentYear = moment().year()
  const startYear = moment(currentYear, 'YYYY').subtract(difference, 'years').year()
  const years = []
  for (let i = currentYear; i >= startYear; i--) {
    years.push(i)
  }
  return years
}

/**
 * Format a date string to a human readable format
 * @param date - The date string to format
 * @returns The formatted date string in the format 'Month Year'
 * @example
 * formatDate('2021-01-01') // 'January 2021'
 **/
export const formatDateToMonthYear = (date: string): string => {
  return new Intl.DateTimeFormat('en', {
    month: 'long',
    year: 'numeric',
  }).format(new Date(date))
}

/**
 * Format a date string to a human readable format
 * @param date - The date string to format
 * @returns The formatted date string in the format 'Year'
 * @example
 * formatDate('2021-01-01') // '2021'
 */
export const formatDateToYear = (date: Date): string => moment(date).utc().format('YYYY')

/**
 * Format a date string to a human readable format
 * @param date - The date string to format
 * @returns The formatted date string in the format 'Short Month Year'
 * @example
 * formatDate('2021-01-01') // 'Jan 2021'
 */
export const formatDateToShortMonthYear = (date: Date): string =>
  moment(date).utc().format('MMM YYYY').replace('.', '')

/**
 * Calculate the start and end date of a given year
 * @param date - The date containing the year that we calculate upon
 * @param useUtc - Optional flag to determine if dates should be in UTC (default: false)
 * @returns The start and end date of the given year as Moment objects
 * @example
 * // Local timezone
 * getYearStartEnd('2023-01-15') // returns [moment('2023-01-01 00:00:00'), moment('2023-12-31 23:59:59')]
 *
 * // UTC timezone
 * getYearStartEnd('2023-01-15', true) // returns [moment.utc('2023-01-01 00:00:00'), moment.utc('2023-12-31 23:59:59')]
 */
export const getYearStartEnd = function (
  date: Date | string | null,
  useUtc = false,
): [Moment, Moment] | null {
  if (date === null || date === undefined) {
    return null
  }

  if (typeof date === 'string') {
    date = new Date(date)
  }

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return null
  }

  const year: number = date.getFullYear()

  if (useUtc) {
    return [moment.utc([year, 0, 1]).startOf('year'), moment.utc([year, 11, 31]).endOf('year')]
  }

  return [moment([year, 0, 1]).startOf('year'), moment([year, 11, 31]).endOf('year')]
}

/**
 *
 * @param moments
 * @returns
 */
export const disabledDates = (
  disabledRange: [Moment, Moment] | null,
): ((current: Moment) => boolean) => {
  if (!disabledRange) {
    return () => false
  }

  const [startMoment, endMoment] = disabledRange

  return (current: Moment): boolean => {
    return !current.isBetween(startMoment, endMoment, 'day', '[]')
  }
}

/**
 * Get the time ago in a short format
 * @param date - The date to get the time ago for
 * @returns The time ago in the format '1h' or '3m' or '20s' or 'Jan 2' or '01/02/2021'
 */
export const getTimeAgoShort = (date: Date | string | Moment): string => {
  const mDate = moment(date)
  const now = moment()
  const diffDays = now.diff(mDate, 'days')
  const diffHours = now.diff(mDate, 'hours')
  const diffMinutes = now.diff(mDate, 'minutes')
  const isLastYear = mDate.year() !== now.year()
  if (diffMinutes < 1) {
    return now.diff(mDate, 'seconds') + 's'
  }
  if (diffHours < 1) {
    return now.diff(mDate, 'minutes') + 'm'
  }
  if (diffDays < 1) {
    return now.diff(mDate, 'hours') + 'h'
  }
  if (isLastYear) {
    return mDate.format('l')
  }
  return mDate.format('MMM D')
}

/**
 * Get the locale
 * @returns The locale from local storage and if not found from browser
 */
export function getLocale(): string {
  return (
    localStorage.getItem('i18nextLng') ??
    i18n.language ??
    Intl.DateTimeFormat().resolvedOptions().locale
  )
}

/**
 * Get the localized time unit
 * @param unit - The unit to get the time unit for
 * @param value - The value to get the time unit for
 * @returns The localized time unit
 */
export function getLocalizedTimeUnit(unit: Intl.RelativeTimeFormatUnit, value = 1): string {
  const locale = getLocale()
  const rtf = new Intl.RelativeTimeFormat(locale)
  return (
    rtf
      .formatToParts(value, unit)
      .filter((part) => part.type === 'literal')
      .reverse()?.[0]?.value ?? unit
  )
}

/**
 * Get the localized days word
 * @param singular - Whether to get the singular or plural word
 * @returns The localized days word
 */
export function getLocalizedDaysWord(singular = false): string {
  return getLocalizedTimeUnit('day', singular ? 1 : 2)
}

/**
 * Get the localized hours word
 * @param singular - Whether to get the singular or plural word
 * @returns The localized hours word
 */
export function getLocalizedHoursWord(singular = false): string {
  return getLocalizedTimeUnit('hour', singular ? 1 : 2)
}

/**
 * Get the localized minutes word
 * @param singular - Whether to get the singular or plural word
 * @returns The localized minutes word
 */
export function getLocalizedMinutesWord(singular = false): string {
  return getLocalizedTimeUnit('minute', singular ? 1 : 2)
}

/**
 * Get the localized seconds word
 * @param singular - Whether to get the singular or plural word
 * @returns The localized seconds word
 */
export function getLocalizedSecondsWord(singular = false): string {
  return getLocalizedTimeUnit('second', singular ? 1 : 2)
}

/**
 * Get the localized time
 * @param date - The date to get the localized time for
 * @returns The localized time
 */
export function getLocalizedTime(date: Moment | Date | string): string {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return moment(date).tz(userTimeZone).format('LT (z)')
}

/**
 * Get the language name for the given locale
 * @param locale - The locale to get the language name for
 * @returns The language name for the given locale in the target language
 */
export function languageShortToLongInLocale(locale: string): string {
  const targetLanguage = getLocale()
  return new Intl.DisplayNames([targetLanguage], { type: 'language' }).of(locale) ?? locale
}

/**
 * Get the localized short month day
 * @param date - The date to get the localized short month day for
 * @returns The localized short month day. Example: 'Jan 2'
 */
export function getLocalizedShortMonthDay(date: Date | string | Moment): string {
  const userLocale = getLocale()
  return new Intl.DateTimeFormat(userLocale, {
    month: 'short',
    day: 'numeric',
  }).format(moment(date).toDate())
}

/**
 * Get the short name of the month
 * @param date - The date to get the short name of the month for
 * @returns The short name of the month
 */
export function getShortNameOfMonth(date: Date | string | Moment): string {
  return new Intl.DateTimeFormat(getLocale(), {
    month: 'short',
  }).format(moment(date).toDate())
}
