import React, { ReactElement, ReactNode, lazy } from 'react'
import { TFunction } from 'react-i18next'

import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import Organigram from '@/pages/Organization/Organigram/Organigram'

import FiltersProvider from '@/contexts/filters'
import LogProvider from '@/contexts/log'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'

const DashboardHome = lazy(() => import('@/pages/DashboardHome'))
const Home = lazy(() => import('@/pages/Home'))
const Wrapper = ({
  feature = 'log',
  children,
}: {
  feature?: string
  children: ReactNode
}): ReactElement => (
  <PrivateRoute>
    <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
  </PrivateRoute>
)

/**
 * All routes in the /share/ namespace
 */
const getHomeRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.dashboardHome.home,
    element: (
      <Wrapper>
        <LogProvider>
          <FiltersProvider saveQueryString={true}>
            <DashboardHome />
          </FiltersProvider>
        </LogProvider>
      </Wrapper>
    ),
  },
  {
    path: routes.overview.digitalTwin,
    element: (
      <Wrapper>
        <LogProvider>
          <Organigram />
        </LogProvider>
      </Wrapper>
    ),
    breadcrumb: t('layout.organigram'),
  },
  {
    path: routes.overview.dashboard,
    element: (
      <Wrapper>
        <LogProvider>
          <FiltersProvider saveQueryString={true}>
            <Home />
          </FiltersProvider>
        </LogProvider>
      </Wrapper>
    ),
  },
]

export default getHomeRoutes
