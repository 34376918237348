/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineExclamation } from 'react-icons/hi'

import { LeoUpdateStatus } from '@cozero/models'

import { useAppDispatch, useAppSelector } from '@/redux'
import api from '@/redux/api'
import { selectUser } from '@/redux/auth'
import { useGetLeoUpdatesQuery } from '@/redux/leoUpdates/api'
import { TAG_LOG_ENTRY_OVERVIEW_ROWS_SUMS, TAG_SEARCH_LOG_ENTRIES } from '@/redux/logEntries/tags'

import { formatTime } from '../utils/time'

type UseLastUpdatedTimeReturn = {
  isFetching: boolean
  isError: boolean
  legend: string | null
  icon: React.ReactNode | null
}

const TRANSLATION_KEY = 'log.log-entries-overview.last-update'
const POLLING_INTERVAL_IN_MS = 5000

export const useLeoLastUpdatedTime = (): UseLastUpdatedTimeReturn => {
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [isPollingStatus, setIsPollingStatus] = useState(true)

  const {
    data: leoUpdate,
    isError: isLeoUpdateError,
    isFetching: isFetchingLeoUpdate,
  } = useGetLeoUpdatesQuery(undefined, {
    pollingInterval: isPollingStatus ? POLLING_INTERVAL_IN_MS : undefined,
  })

  const isFetching = isFetchingLeoUpdate || isPollingStatus
  const isError = isLeoUpdateError || leoUpdate?.status === LeoUpdateStatus.FAILED

  useEffect(() => {
    if (
      isError ||
      (leoUpdate &&
        (leoUpdate.status === undefined || leoUpdate.status === LeoUpdateStatus.COMPLETED))
    ) {
      setIsPollingStatus(false)
      if (leoUpdate?.status === LeoUpdateStatus.COMPLETED) {
        dispatch(
          api.util.invalidateTags([TAG_SEARCH_LOG_ENTRIES, TAG_LOG_ENTRY_OVERVIEW_ROWS_SUMS]),
        )
      }

      return
    }
    setIsPollingStatus(true)

    return () => {
      setIsPollingStatus(false)
    }
  }, [leoUpdate, dispatch, isError])

  const determineLegend = (): string | null => {
    const time = leoUpdate?.lastLeoUpdate
      ? formatTime(new Date(leoUpdate.lastLeoUpdate), user?.locale)
      : null

    const errorLegend = t(`${TRANSLATION_KEY}.failed`)
    const loadingLegend = t(`${TRANSLATION_KEY}.loading`)
    const lastUpdateLegend = t(`${TRANSLATION_KEY}.time`, { time })

    if (isError) {
      return errorLegend
    }

    if (isFetching) {
      return loadingLegend
    }

    if (time) {
      return lastUpdateLegend
    }

    return null
  }

  const legend = determineLegend()
  const icon = isError ? <HiOutlineExclamation /> : null

  return {
    isFetching,
    isError,
    icon,
    legend,
  }
}
