import React, { ReactElement, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Form, Row, Spin } from 'antd/es'
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select'

import { FilterKey } from '..'
import { debounce } from 'lodash-es'

import { PageFilter } from '@cozero/models'

import Select, { Option } from '@/atoms/Select'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useSelectedBusinessUnit } from '@/hooks/useSelectedBusinessUnit'
import { useGetActiveBusinessUnitsQuery } from '@/redux/businessUnits'
import { useGetActiveLocationsQuery } from '@/redux/locations'
import { useGetActiveProductsQuery } from '@/redux/products'

import classes from './../FiltersDrawer.module.less'

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Select {
  export type value = number[]
  export type option = BaseOptionType | DefaultOptionType | (BaseOptionType | DefaultOptionType)[]
}

interface Props {
  filters: (PageFilter & { key: FilterKey })[]
  filterOptions: (PageFilter & {
    options: {
      key?: string
      value: string
      label: string
    }[]
  })[]
  values: {
    [FilterKey.CATEGORY]: number[]
    [FilterKey.LOCATION]: number[]
    [FilterKey.BUSINESS_UNIT]: number[]
    [FilterKey.PRODUCT]: number[]
    [FilterKey.RESPONSIBLE]: number[]
    [FilterKey.DESCRIPTION]: string
  }
  onChange: (filter: PageFilter & { key: FilterKey }, value: Select.value | string) => void
}

const QuantitiesFiltersForm = ({ filterOptions, values, onChange }: Props): ReactElement => {
  const { t } = useTranslation('')

  const { isFeatureEnabled } = usePricingFeature()
  const { selectedBusinessUnit } = useSelectedBusinessUnit()

  const handleChange = (value: Select.value | string, key: string): void => {
    const selectedFilter = filterOptions?.find((x) => x.key === key)
    if (selectedFilter) {
      onChange(selectedFilter as PageFilter & { key: FilterKey }, value)
    }
  }

  const [searchTerms, setSearchTerms] = useState({
    [FilterKey.LOCATION]: '',
    [FilterKey.BUSINESS_UNIT]: '',
    [FilterKey.PRODUCT]: '',
  })
  const { data: paginatedLocations } = useGetActiveLocationsQuery(
    {
      selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1,
      search: searchTerms[FilterKey.LOCATION],
    },
    { skip: !selectedBusinessUnit || !searchTerms[FilterKey.LOCATION] },
  )
  const { data: paginatedProducts, isLoading: productLoading } = useGetActiveProductsQuery(
    {
      businessUnitKey: selectedBusinessUnit?.key ?? '',
      search: searchTerms[FilterKey.PRODUCT],
    },
    { skip: !selectedBusinessUnit || !searchTerms[FilterKey.PRODUCT] },
  )
  const { data: paginatedBusinessUnits } = useGetActiveBusinessUnitsQuery(
    {
      businessUnitScopeId: selectedBusinessUnit?.id ?? -1,
      search: searchTerms[FilterKey.BUSINESS_UNIT],
    },
    { skip: !selectedBusinessUnit || !searchTerms[FilterKey.BUSINESS_UNIT] },
  )

  const { productId: productOptions } = useMemo(() => {
    const options = filterOptions.reduce(
      (acc, value) => {
        return {
          ...acc,
          [value.key as string]:
            value.options?.map((x) => ({
              ...x,
              value: +x.value,
              key: x.key ? +x.key : x.key,
            })) || [],
        }
      },
      {
        [FilterKey.CATEGORY]: [],
        [FilterKey.TAG]: [],
        [FilterKey.CUSTOMER]: [],
        [FilterKey.SUPPLIER]: [],
        [FilterKey.RESPONSIBLE]: [],
        [FilterKey.SCOPE]: [],
        [FilterKey.DECARBONIZATION_LEVER]: [],
      },
    )
    return {
      ...options,
      [FilterKey.LOCATION]:
        paginatedLocations?.map((d) => (
          <Option key={d.id} value={d.id} label={d.name}>
            {d.name}
          </Option>
        )) || [],
      [FilterKey.PRODUCT]:
        paginatedProducts?.map((d) => (
          <Option key={d.id} value={d.id} label={d.name}>
            {d.name}
          </Option>
        )) || [],
      [FilterKey.BUSINESS_UNIT]:
        paginatedBusinessUnits?.map((d) => (
          <Option key={d.id} value={d.id} label={d.title}>
            {d.title}
          </Option>
        )) || [],
    }
  }, [filterOptions, paginatedLocations, paginatedBusinessUnits, paginatedProducts])

  const onSearch = (value: string, key: string): void => {
    setSearchTerms({ ...searchTerms, [key]: value })
  }

  return (
    <>
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t('log.product')}>
              <Select
                showSearch
                defaultActiveFirstOption={false}
                filterOption={false}
                mode={isFeatureEnabled('multiple-filtering') ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.search')}
                value={values.productId}
                onChange={(value) => {
                  handleChange(value, FilterKey.PRODUCT)
                }}
                onSearch={debounce((term) => onSearch(term, FilterKey.PRODUCT), 500)}
                style={{ width: '100%' }}
                dropdownRender={(menu) => {
                  if (productLoading) {
                    return (
                      <span className={classes.dropdownContainer}>
                        <Spin />
                      </span>
                    )
                  }
                  return productOptions?.length ? (
                    menu
                  ) : (
                    <span className={classes.dropdownContainer}>{t('log.filter.search')}</span>
                  )
                }}
              >
                {productOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default React.memo(QuantitiesFiltersForm)
