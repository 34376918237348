import { ActivityDataSourceQuery } from '@cozero/dtos'
import { ActivityDataSource } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { RtkEndpointBuilderArg } from '@/utils/redux'

import apiSlice from '../api'

import { GET_ACTIVITY_DATA_WITHOUT_STRUCTURE } from './constants'

export const getAdsWithoutStructureStorageKey = (
  q: Parameters<typeof apiSlice.useGetActivityDataSourcesWithoutStructureQuery>[0],
): string => {
  const storageId = q ? JSON.stringify(q) : 'void'
  return `ads-without-structure:${storageId}`
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const buildAdsMainSliceEndpoints = (builder: RtkEndpointBuilderArg) => ({
  [GET_ACTIVITY_DATA_WITHOUT_STRUCTURE]: builder.query<
    Array<ActivityDataSource>,
    ActivityDataSourceQuery | void
  >({
    query: (params) => ({
      method: 'GET',
      url: logApiGatewayClient.activity_data_sources.GET_MANY_WITHOUT_STRUCTURE,
      params,
    }),
    keepUnusedDataFor: Infinity,
    onQueryStarted: async (args, { queryFulfilled }) => {
      try {
        const { data } = await queryFulfilled
        if (!data) {
          return
        }

        localStorage.setItem(getAdsWithoutStructureStorageKey(args), JSON.stringify(data))
      } catch (error) {
        return error
      }
    },
  }),
})
