import { EmissionsSumGroupResultItem, OrganigramBusinessUnit } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

const organigramSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    ['getBusinessUnitForOrganigram']: builder.query<OrganigramBusinessUnit, number>({
      query: (id) => ({
        url: centralApiGatewayClient.organigram.GET_BUSINESS_UNIT_FOR_ORGANIGRAM.replace(
          ':id',
          `${id}`,
        ),
        method: 'GET',
      }),
    }),
    ['getEmissionsForOrganigram']: builder.query<
      EmissionsSumGroupResultItem[],
      {
        id: number
        datesFilter?: { startDate: string; endDate: string }
        locationIds: number[]
      }
    >({
      query: ({ id, datesFilter, locationIds: locationIds }) => ({
        url: centralApiGatewayClient.organigram.GET_EMISSIONS_FOR_ORGANIGRAM.replace(
          ':businessUnitId',
          `${id}`,
        ),
        params: datesFilter,
        method: 'POST',
        data: locationIds,
      }),
    }),
  }),
})

export const { useGetBusinessUnitForOrganigramQuery, useLazyGetEmissionsForOrganigramQuery } =
  organigramSlice

export default organigramSlice
