import { ActivateLoginDto, InternalFeaturesDto } from '@cozero/dtos'
import { LoginMethod, User } from '@cozero/models'
import { authApiGatewayClient, centralApiGatewayClient } from '@cozero/uris'

import i18n from '@/i18n'

import apiSlice from '../api'

import {
  ACTIVATE_USER,
  GET_INTERNAL_FEATURES,
  GET_LOGIN_METHOD,
  GET_USER_DATA,
  LOGIN,
  LOGIN_WITH_JWT_TOKEN,
  LOGIN_WITH_TOKEN,
  LOGOUT,
  TAG_USER,
} from './constants'
import { AuthStateExtraClaim } from './slice'

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_LOGIN_METHOD]: builder.query<LoginMethod, string>({
      query: (email) => ({
        url: authApiGatewayClient.auth.GET_LOGIN_METHOD,
        method: 'GET',
        params: {
          email,
        },
      }),
    }),
    [LOGIN]: builder.mutation<
      { user: User; accessToken: string },
      { email: string; password: string }
    >({
      query: (credentials) => {
        window.localStorage.removeItem('FORCED_LOGOUT')
        window.localStorage.removeItem('EXPIRED_LINK')
        return {
          url: authApiGatewayClient.auth.LOGIN_BASIC,
          method: 'POST',
          data: { ...credentials },
        }
      },
    }),
    [LOGIN_WITH_TOKEN]: builder.mutation<{ user: User; accessToken: string }, string>({
      query: (token) => {
        window.localStorage.removeItem('FORCED_LOGOUT')
        window.localStorage.removeItem('EXPIRED_LINK')
        return { url: authApiGatewayClient.auth.LOGIN_SSO, method: 'POST', data: { token } }
      },
    }),
    [LOGIN_WITH_JWT_TOKEN]: builder.mutation<
      { user: User; accessToken: string; extraClaim: AuthStateExtraClaim },
      string
    >({
      query: (token) => {
        {
          window.localStorage.removeItem('FORCED_LOGOUT')
          window.localStorage.removeItem('EXPIRED_LINK')
          return { url: authApiGatewayClient.auth.LOGIN_JWT_TOKEN, method: 'POST', data: { token } }
        }
      },
    }),
    [LOGOUT]: builder.query<void, void>({
      query: () => ({
        url: authApiGatewayClient.auth.LOGOUT,
        method: 'GET',
      }),
    }),
    [GET_USER_DATA]: builder.query<User, string | void>({
      query: (businessUnit) => ({
        url: centralApiGatewayClient.users.GET_ME,
        method: 'GET',
        params: {
          businessUnit,
        },
      }),
      providesTags: (res) => [{ type: TAG_USER, id: res?.id }],
    }),
    [ACTIVATE_USER]: builder.query<User, ActivateLoginDto & { locale: string }>({
      query: (data) => ({
        url: authApiGatewayClient.auth.ACTIVATE_LOGIN,
        method: 'POST',
        data,
      }),
      transformErrorResponse: (res) => {
        if (res.data?.statusCode && res?.data?.statusCode === 403) {
          return {
            ...res,
            message: i18n.t('activate.expired'),
          }
        }
        return res
      },
    }),
    [GET_INTERNAL_FEATURES]: builder.query<InternalFeaturesDto[], void>({
      query: () => ({
        url: centralApiGatewayClient.features.GET_MANY_INTERNAL,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetLoginMethodQuery,
  useLazyGetLoginMethodQuery,
  useLoginMutation,
  useLoginWithTokenMutation,
  useLoginWithJwtTokenMutation,
  useLogoutQuery,
  useLazyLogoutQuery,
  useGetUserDataQuery,
  useLazyGetUserDataQuery,
  useActivateUserQuery,
  useLazyActivateUserQuery,
  usePrefetch,
  useGetInternalFeaturesQuery,
} = authApiSlice

export default authApiSlice
