/* eslint react-hooks/exhaustive-deps: 2 */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineBookOpen, HiOutlineDocumentReport, HiOutlineLightningBolt } from 'react-icons/hi'

import { PieChartOutlined } from '@ant-design/icons'

import { routes } from '@cozero/utils'

import { useOrgIsSupplier } from '@/hooks/useOrgIsSupplier'
import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useSupplierRoute } from '@/hooks/useSupplierRoute'

import { MenuItem, PageItem } from './NavSidebar'
import { useMenuFeature } from './NavSidebar.useMenuFeature'
import { useMenuItemChildren } from './NavSidebar.useMenuItemChildren'

export const useMenuItems = (): { menuItems: MenuItem[] } => {
  const { t } = useTranslation()

  const { canUseMenuFeature } = useMenuFeature()
  const { isFeatureInternal } = usePricingFeature()
  const { isFeatureEnabled } = usePricingFeature()
  const orgIsSupplier = useOrgIsSupplier()
  const { isSupplierRoute } = useSupplierRoute()
  const { getMappedActItemChildren } = useMenuItemChildren()

  const menuItems: MenuItem[] = useMemo(() => {
    const items: PageItem[] = [
      {
        key: 'overview',
        title: t('layout.overview'),
        icon: <PieChartOutlined />,
        feature: 'log',
        children: [
          {
            key: 'organigram',
            title: t('layout.organigram'),
            route: routes.overview.digitalTwin,
            cy: 'organigram',
            feature: 'log',
          },
          {
            key: 'home',
            title: t('layout.dashboard.title'),
            route: routes.overview.dashboard,
            feature: 'log',
          },
        ],
      },
      {
        key: 'log',
        title: 'Log',
        icon: <HiOutlineBookOpen />,
        feature: 'log',
        children: [
          {
            key: 'carbonFootprint',
            title: t('layout.corporate-footprint'),
            route: routes.log.corporateFootprint.base,
            cy: 'carbon-footprint',
            feature: 'log',
          },
          {
            key: 'productCarbonFootprint',
            title: t('layout.product-footprint'),
            route: routes.log.products.base,
            cy: 'product-carbon-footprint',
            feature: 'log',
          },
          {
            key: 'factors',
            title: t('layout.factors'),
            route: routes.log.factors.base,
            feature: 'log',
          },
          {
            key: 'customers',
            title: t('layout.customers'),
            route: routes.log.customers,
            feature: 'log',
            excludeRoles: ['viewer'],
          },
          {
            key: 'suppliers',
            title: t('layout.suppliers'),
            route: routes.log.suppliers,
            feature: 'log',
            excludeRoles: ['viewer'],
          },
          {
            key: 'tags',
            title: t('location.tags'),
            route: routes.log.tags,
            feature: 'log',
            excludeRoles: ['viewer'],
          },
          {
            key: 'closedPeriods',
            title: t('layout.closed-periods'),
            route: routes.log.closedPeriods.base,
            feature: 'log',
          },
        ],
      },
      {
        key: 'act',
        title: 'Act',
        icon: <HiOutlineLightningBolt />,
        feature: 'act',
        children: [
          {
            key: 'analytics',
            title: t('act.analytics.title'),
            route: routes.act.analytics,
            feature: 'act',
          },
          {
            key: 'customActions',
            title: t('act.climateActions.title'),
            route: routes.act.climateActions,
            feature: 'act',
          },
          {
            key: 'targets',
            title: t('act.targets.nav-title'),
            route: routes.act.targets,
            feature: 'act',
          },
        ],
      },
      {
        key: 'share',
        title: 'Share',
        icon: <HiOutlineDocumentReport />,
        feature: 'reports',
        children: [
          {
            key: 'reports',
            title: t('layout.reports'),
            route: routes.share.reports.base,
            feature: 'reports',
          },
        ],
      },
    ]

    return items
      .filter((item) => canUseMenuFeature(item) || !isFeatureInternal(item.key))
      .map((item) => {
        if (item.key === 'act') {
          item.children = getMappedActItemChildren(item)
        }

        if (orgIsSupplier) {
          const children = item?.children?.map((child) => {
            const isPaywalled = !isSupplierRoute(child.route)
            return {
              ...child,
              isDisabled: isPaywalled,
              isPaywalled,
            }
          })

          return {
            ...item,
            children,
            isPaywalled: children?.every((child) => child.isPaywalled),
          }
        }

        const children = item?.children?.map((child) => {
          const isPaywalled = !isFeatureEnabled(child.feature)
          return { ...child, isPaywalled, isDisabled: child.isDisabled || isPaywalled }
        })

        return {
          ...item,
          children: children,
          isPaywalled:
            !isFeatureEnabled(item.feature) || children?.every((child) => child.isPaywalled),
        }
      })
  }, [
    canUseMenuFeature,
    getMappedActItemChildren,
    isFeatureEnabled,
    isFeatureInternal,
    isSupplierRoute,
    orgIsSupplier,
    t,
  ])

  return { menuItems }
}
