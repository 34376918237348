import React, { useMemo } from 'react'
import { CustomNodeElementProps } from 'react-d3-tree/lib/types/common'
import { AiOutlineHome } from 'react-icons/ai'
import { FiSettings } from 'react-icons/fi'
import { HiOutlineBookOpen, HiOutlineSwitchHorizontal } from 'react-icons/hi'
import { TbChartBar } from 'react-icons/tb'

import classNames from 'classnames'

import { routes } from '@cozero/utils'

import { NodeEmissions } from '@/pages/Organization/Organigram/NodeEmissions'

import Card from '@/atoms/Card'
import Text from '@/atoms/Text'

import i18n from '@/i18n'
import { NodeType } from '@/utils/tree'

import classes from './RectSvgNode.module.less'
import { ThreeDotsMenu } from './ThreeDotsMenu'

const settingPaths = (
  isLocation: boolean,
  id: string,
): {
  createLog: string
  viewDashboard: string
  settings: string
} => {
  return {
    createLog: routes.organization.locations.logs.replace(':locationId', id),
    viewDashboard: routes.overview.dashboard,
    settings: isLocation
      ? routes.organizationSettings.locations.edit.replace(':id', id)
      : routes.organizationSettings.businessUnits.edit.replace(':id', id),
  }
}

const RectSvgNode = ({
  nodeDatum,
  toggleNode,
  isFetchingEmissions,
  isErrorEmissions,
  onBusinessUnitChange,
}: CustomNodeElementProps & {
  isFetchingEmissions: boolean
  isErrorEmissions: boolean
  onBusinessUnitChange: (businessUnitId: number) => Promise<void>
}): JSX.Element => {
  const { attributes } = nodeDatum
  const isLocation = attributes?.type === NodeType.LOCATION
  const id = attributes?.id

  const isRoot = nodeDatum.__rd3t.depth === 0
  const nodeCircleText = i18n.t(isLocation ? 'organigram.location' : 'organigram.businessUnit')

  const threeDotsMenuItems = useMemo(
    () => [
      {
        title: i18n.t('organigram.threeDotsMenu.create-log'),
        onClick: () => window.open(settingPaths(isLocation, `${id}`).createLog),
        icon: <HiOutlineBookOpen />,
        visible: true,
      },
      {
        title: i18n.t('organigram.threeDotsMenu.view-dashboard'),
        onClick: () => {
          onBusinessUnitChange(Number(id))
          window.open(settingPaths(isLocation, `${id}`).viewDashboard)
        },
        icon: <TbChartBar />,
        visible: !isLocation,
      },
      {
        title: i18n.t('organigram.threeDotsMenu.settings', {
          name: nodeDatum.name,
        }),
        onClick: () => window.open(settingPaths(isLocation, `${id}`).settings),
        icon: <FiSettings />,
        visible: true,
      },
      {
        title: i18n.t('organigram.threeDotsMenu.switch-to', {
          name: nodeDatum.name,
        }),
        onClick: () => onBusinessUnitChange(Number(id)),
        icon: <HiOutlineSwitchHorizontal />,
        visible: !isLocation,
      },
    ],
    [isLocation, onBusinessUnitChange, nodeDatum],
  )

  const description = [attributes?.organizationName, attributes?.responsibleName]
    .filter((x) => x)
    .join(' | ')

  return (
    <foreignObject width={350} height={400} x={-175} y={-110}>
      <div className={classes.cardWrapper}>
        <Card className={classNames(classes.nodeCard, { [classes.rootNode]: isRoot })}>
          <div className={classes.row}>
            <div className={classes.iconColumn}>
              <div className={classes.circle}>
                {isRoot ? (
                  <AiOutlineHome size={20} />
                ) : (
                  <Text
                    fontWeight="medium"
                    size="xl"
                    color="default"
                    className={classes.circleText}
                  >
                    {nodeCircleText}
                  </Text>
                )}
              </div>
            </div>

            <div className={classes.informationColumn}>
              <div className={classes.informationHeader}>
                <div>
                  <Text fontWeight="medium" size="xl" color="default" className={classes.nodeTitle}>
                    {nodeDatum.name}
                  </Text>

                  {description && (
                    <div>
                      <Text className={classes.nodeDescription}>{description}</Text>
                    </div>
                  )}

                  {attributes?.externalId && (
                    <div>
                      <Text className={classes.nodeDescription}>ID: {attributes?.externalId}</Text>
                    </div>
                  )}
                </div>

                <ThreeDotsMenu
                  threeDotsConfig={{
                    color: 'blue',
                    orientation: 'vertical',
                  }}
                  items={threeDotsMenuItems}
                />
              </div>

              <NodeEmissions
                isFetchingEmissions={isFetchingEmissions}
                isErrorEmissions={isErrorEmissions}
                nodeId={id}
                isLocation={isLocation}
              />
            </div>
          </div>
        </Card>

        {!isLocation && (nodeDatum.children?.length || 0) > 0 && (
          <div className={classes.toggleNodes} onClick={toggleNode} id="zoom">
            {nodeDatum.__rd3t.collapsed ? '+' : '–'}
          </div>
        )}
      </div>
    </foreignObject>
  )
}

export default RectSvgNode
