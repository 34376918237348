import React, { ReactElement, memo, useEffect, useMemo, useState } from 'react'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HiOutlinePhone } from 'react-icons/hi'

import { Col, Divider, Row, Skeleton, Spin } from 'antd/es'

import moment from 'moment'

import { Limit, Usage } from '@cozero/models'
import { Organization } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { ContactModal } from '@/organisms/ContactModal'

import Alert from '@/atoms/Alert'
import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useSubscriptionContext } from '@/contexts/subscription'
import { useAppSelector } from '@/redux'
import { selectUserOrganization } from '@/redux/auth'
import axios from '@/utils/axios'

import FreeSubscriptionExpired from './FreeSubscriptionExpired'
import StatCard from './StatCard'
import classes from './classes.module.less'

const StatCards = memo(({ limits }: { limits?: Limit[] }): ReactElement => {
  StatCards.displayName = 'StatCards'
  const { t } = useTranslation()
  const [usage, setUsage] = useState<Usage | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const fetchUsage = async (): Promise<void> => {
    try {
      const { data: usage } = await axios.get<Usage>(
        centralApiGatewayClient.organizations.GET_USAGE,
      )
      setUsage(usage)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!error && !usage && !loading) {
      fetchUsage()
    }
  }, [limits])

  const cards = useMemo(
    () =>
      limits?.map((limit) => (
        <Col key={limit.id} xs={12} lg={8} xxl={6}>
          <StatCard
            progressMax={limit.max}
            feature={limit.feature}
            breakdown={limit.usageBreakdown as Record<string, number>}
            usage={usage}
          />
        </Col>
      )),
    [limits, usage],
  )

  if (error) {
    return (
      <Col span={12}>
        <Alert type="warning">{t('settings.subscription.usage.error')}</Alert>
      </Col>
    )
  }
  return <>{cards}</>
})

const Subscription = (): ReactElement => {
  const { t } = useTranslation('common')
  const { subscription, getSubscription, getSubscriptionProducts, subscriptionProducts, loading } =
    useSubscriptionContext()
  const [currentPrice, setCurrentPrice] = useState<{ currency?: string; amount?: number } | null>(
    null,
  )
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>()
  const organization = useAppSelector(selectUserOrganization)

  async function fetchData(): Promise<void> {
    await getSubscription()
    await getSubscriptionProducts()
  }

  const upgradeMessage = useMemo(() => {
    switch (organization?.pricing?.key) {
      case 'supplier':
        return {
          title: t('settings.subscription.supplier-plan.title'),
          description: t('settings.subscription.supplier'),
        }
      case 'free-6m':
      case 'free':
        return {
          title: t('settings.subscription.free.title'),
        }
      case 'cozero-business-1':
        return {
          title: t('settings.subscription.light.title'),
          description: t('settings.subscription.upgrade-description', {
            plan: t('settings.subscription.light.title'),
          }),
        }
      case 'cozero-business-2':
        return {
          title: t('settings.subscription.growth.title'),
          description: t('settings.subscription.upgrade-description', {
            plan: t('settings.subscription.growth.title'),
          }),
        }
      case 'cozero-business-3':
        return {
          title: t('settings.subscription.impact.title'),
          description: t('settings.subscription.upgrade-description', {
            plan: t('settings.subscription.impact.title'),
          }),
        }
      default:
        return null
    }
  }, [subscription?.pricing.key])

  const openContactModal = async (): Promise<void> => {
    setSelectedOrganization(organization)
  }

  const closeContactModal = (): void => {
    setSelectedOrganization(undefined)
  }

  const goToCustomerPortal = useCallback(async (): Promise<void> => {
    window.open(subscription?.checkoutPortalUrl, '_self')
  }, [])

  useEffect(() => {
    if (subscriptionProducts) {
      const price = subscriptionProducts
        .find((product) => organization?.pricing?.key === product.key)
        ?.prices?.find((price) => price.interval === 'month')

      if (price) {
        setCurrentPrice(price)
      } else {
        setCurrentPrice({ currency: '€', amount: 0 })
      }
    }
  }, [subscriptionProducts])

  useEffect(() => {
    if (
      (!subscription || subscriptionProducts?.length < 1) &&
      organization?.pricing?.key !== 'supplier' &&
      !loading
    ) {
      fetchData()
    }
  }, [])

  const isFree = organization?.pricing?.type === 'free'
  const isFreeExpired = isFree && subscription?.expirationDate && subscription.status === 'Expired'

  if (isFreeExpired) {
    return (
      <>
        <FreeSubscriptionExpired onContactUsClicked={openContactModal} />
        <ContactModal
          selectedOrganization={selectedOrganization}
          handleCancel={closeContactModal}
        />
      </>
    )
  }

  const isEnterprise = organization?.pricing?.type === 'enterprise'
  const hasPricingLimits =
    organization?.pricing?.limits && organization?.pricing?.limits?.length > 0

  return (
    <Row justify="center">
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Spin spinning={loading}>
              <Row>
                <Col span={24}>
                  <Row className={classes.headerSection}>
                    <Col span={24}>
                      <Title size="sm">{t('settings.subscription.title')}</Title>
                      <Text size="xl" className={classes.subtitle}>
                        {t(
                          isEnterprise
                            ? 'settings.subscription.enterprise-subtitle'
                            : 'settings.subscription.subtitle',
                        )}
                      </Text>
                    </Col>
                  </Row>
                  <Row className={classes.headerSection} gutter={16}>
                    <Col xs={12} md={8} xxl={6}>
                      <Card shadow="none" className={classes.statCard}>
                        <Title size="sm">{organization?.pricing?.name as string}</Title>
                        {organization?.pricing?.type !== 'enterprise' && (
                          <Text size="xl">
                            <Trans
                              i18nKey={
                                isFree
                                  ? 'settings.subscription.expiring'
                                  : 'settings.subscription.renewal'
                              }
                              values={{
                                date: moment(subscription?.expirationDate).format('ll'),
                              }}
                              components={{
                                1: <strong />,
                              }}
                            />
                          </Text>
                        )}
                        {organization?.pricing?.type !== 'enterprise' ||
                        currentPrice?.amount === 0 ? null : currentPrice ? (
                          <Title size="sm">
                            {t('currency-price-month', {
                              currency: '€',
                              price: currentPrice.amount,
                            })}
                          </Title>
                        ) : (
                          <Title size="sm">
                            <Skeleton.Button active />
                          </Title>
                        )}
                        {subscription?.checkoutPortalUrl && (
                          <Button
                            type="primary"
                            action="manage-subscription"
                            category={AnalyticsCategories.PLANS}
                            className={classes.cardButton}
                            onClick={goToCustomerPortal}
                          >
                            {t('settings.subscription.manage.subscription')}
                          </Button>
                        )}
                        {isFree && <div>{t('settings.subscription.contact-sales')}</div>}
                      </Card>
                    </Col>

                    {upgradeMessage &&
                      organization?.pricing?.type !== 'enterprise' &&
                      organization?.pricing?.type !== 'free' && (
                        <Col xs={12} md={8} xxl={6}>
                          <Card shadow="none" className={`${classes.darkCard}`}>
                            <div className={classes.darkCardBody}>
                              <div className={classes.content}>
                                <Title className={classes.title}>{upgradeMessage?.title}</Title>
                                <Text className={classes.description} size="xl">
                                  {upgradeMessage?.description}
                                </Text>
                              </div>
                              <Button
                                type="primary"
                                action={'contact-sale'}
                                category={AnalyticsCategories.SUBSCRIPTIONS}
                                className={classes.cardButton}
                                onClick={openContactModal}
                              >
                                <HiOutlinePhone /> {t('settings.subscription.upgrade')}
                              </Button>
                            </div>
                          </Card>
                        </Col>
                      )}
                  </Row>
                  {hasPricingLimits && (
                    <>
                      <Row>
                        <Col span={24}>
                          <Title size="sm">{t('settings.subscription.usage.title')}</Title>
                          <Text size="xl" className={classes.subtitle}>
                            {t('settings.subscription.usage.subtitle')}
                          </Text>
                        </Col>
                      </Row>
                      <Divider type="horizontal" className={classes.divider} />
                      <Row gutter={[16, 16]} className={classes.cardContainer}>
                        <StatCards limits={organization?.pricing?.limits} />
                      </Row>
                    </>
                  )}
                </Col>
                <ContactModal
                  selectedOrganization={selectedOrganization}
                  handleCancel={closeContactModal}
                />
              </Row>
            </Spin>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default memo(Subscription)
