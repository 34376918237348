import React from 'react'
import { HiOutlineInformationCircle } from 'react-icons/hi'

import classNames from 'classnames'

import Tooltip, { TooltipProps } from '@/atoms/Tooltip'

import classes from './LabelWithTooltip.module.less'

type LabelWithTooltipProps = {
  label: React.ReactNode
  tooltipProps: Omit<TooltipProps, 'children'>
  showIcon?: boolean
  iconSize?: number
  className?: string
}

export const LabelWithTooltip = ({
  label,
  tooltipProps,
  showIcon = true,
  className,
}: LabelWithTooltipProps): React.ReactElement => {
  return (
    <div className={classNames(className, classes.labelWithTooltip)}>
      <div className={classes.truncate}>{label}</div>
      {showIcon && (
        <Tooltip {...tooltipProps} showArrow={true}>
          <HiOutlineInformationCircle className={classes.infoCircleIcon} />
        </Tooltip>
      )}
    </div>
  )
}
