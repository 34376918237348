import React, { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HiOutlineStatusOffline } from 'react-icons/hi'

import { Spin } from 'antd/es'

import Text from '@/atoms/Text'

import classes from './OrganigramWrapper.module.less'

const OrganigramError = ({ refetch }: { refetch: () => void }): ReactElement => {
  const { t } = useTranslation()
  return (
    <div className={classes.errorWrapper}>
      <HiOutlineStatusOffline className={classes.errorIcon} />

      <Text size="xl" color="secondary">
        <Trans
          t={t}
          i18nKey="organigram.error"
          components={{
            1: <span className={classes.tryAgainLegend} onClick={refetch} />,
          }}
        />
      </Text>
    </div>
  )
}

export const OrganigramWrapper = ({
  children,
  isLoadingBusinessUnit,
  isErrorBusinessUnit,
  refetchBusinessUnit,
}: {
  children: ReactElement
  isLoadingBusinessUnit: boolean
  isErrorBusinessUnit: boolean
  refetchBusinessUnit: () => void
}): JSX.Element => {
  if (isErrorBusinessUnit) {
    return <OrganigramError refetch={refetchBusinessUnit} />
  }

  if (isLoadingBusinessUnit) {
    return <Spin spinning />
  }

  return children
}
