import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setSelectedBusinessUnit, useLazyGetBusinessUnitQuery } from '@/redux/businessUnits'

export const useBusinessUnits = (): {
  onBusinessUnitChange: (businessUnitId: number) => Promise<void>
} => {
  const dispatch = useDispatch()

  const [getBusinessUnit] = useLazyGetBusinessUnitQuery()

  const onBusinessUnitChange = useCallback(async (businessUnitId: number): Promise<void> => {
    const newBusinessUnit = await getBusinessUnit({
      id: `${businessUnitId}`,
      active: true,
    }).unwrap()

    dispatch(setSelectedBusinessUnit(newBusinessUnit))
  }, [])

  return { onBusinessUnitChange }
}
