import i18n from '../../i18n'

export const useFormatNumber = (
  defaultOptions: Intl.NumberFormatOptions = {},
): ((number: number, options?: Intl.NumberFormatOptions) => string) => {
  const locale = localStorage.getItem('i18nextLng') ?? i18n.language

  return (number: number, options: Intl.NumberFormatOptions = {}): string => {
    return new Intl.NumberFormat(locale, { ...defaultOptions, ...options }).format(number)
  }
}
