import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Empty, Row } from 'antd/es'

import classNames from 'classnames'

import defaultEmptyTableImage from '@/assets/empty-table.svg'

import classes from './EmptyDataPlaceholder.module.less'

export const EmptyDataPlaceholder = ({
  emptyTitle,
  emptyDescription,
  emptyCTA,
  emptyImage,
  showWrapper = false,
}: {
  emptyTitle?: string
  emptyDescription?: string | ReactElement
  emptyCTA?: ReactElement
  emptyImage?: ReactElement
  showWrapper?: boolean
}): ReactElement => {
  const { t } = useTranslation('common')
  return (
    <Empty
      image={emptyImage ?? defaultEmptyTableImage}
      imageStyle={{ height: 108 }}
      className={classNames({ [classes.wrapper]: showWrapper })}
      description={
        <div>
          <Row justify={'center'}>
            <span className={classes.emptyTitle}>{emptyTitle ?? t('table.emptyTitle')}</span>
          </Row>

          <Row justify={'center'}>
            <span className={classes.emptyDescription}>
              {emptyDescription ?? t('table.emptyDescription')}
            </span>
          </Row>

          {emptyCTA ?? <Row>{emptyCTA}</Row>}
        </div>
      }
    />
  )
}
