import { useTranslation } from 'react-i18next'
import { HiOutlineBookOpen } from 'react-icons/hi'

import { Col, Row } from 'antd/es'

import { routes } from '@cozero/utils'

import NavigationTabs from '@/molecules/NavigationTabs/NavigationTabs'

import Button from '@/atoms/Button'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import classes from './classes.module.less'

function CalculationsTabs(): JSX.Element {
  const { t } = useTranslation('common')

  const menuItems = [
    {
      uri: `${routes.log.factors.calculations.base}`,
      title: t('layout.corporate-footprint'),
      key: 'requests',
    },
    {
      uri: `${routes.log.factors.calculations.pcf}`,
      title: (
        <div className={classes.pcfTab}>
          <div>{t('layout.product-footprint')}</div>
          <Tag type="info">{t('coming-soon')}</Tag>
        </div>
      ),
      key: 'requests',
      disabled: true,
    },
  ]

  const items = t('calculation-changes-v2.explainer-items', {
    returnObjects: true,
  }) as string[]

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <Row className={classes.headerRow}>
          <Col span={24}>
            <Text size="xl" color="secondary">
              {t('calculation-changes-v2.explainer-title')}
              <ul className={classes.list}>
                {items.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button
              prefixIcon={<HiOutlineBookOpen size={20} />}
              href={t(`intercom.recalculations.calculation-changes`)}
              type="link"
              target="_blank"
              rel="noreferrer"
              className={classes.learnLink}
            >
              {t('calculation-changes-v2.learn-more-button.title')}
            </Button>
          </Col>
        </Row>
      </div>
      <NavigationTabs menuItems={menuItems} />
    </div>
  )
}

export default CalculationsTabs
