import React from 'react'

import { Menu } from 'antd/es'

import DotsMenu from '@/molecules/DotsMenu'

import MenuItem from '@/atoms/Menu/MenuItem'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { COZERO_BLUE_80 } from '@/styles/variables'

interface IMenuItem {
  icon: JSX.Element
  onClick: () => void
  className?: string
  title: string
  dangerStyle?: boolean
  visible?: boolean
}

interface ThreeDotsConfig {
  orientation?: 'vertical' | 'horizontal'
  color?: 'black' | 'blue'
  iconSize?: number
}

interface Props {
  items: IMenuItem[]
  threeDotsOrientation?: 'vertical' | 'horizontal'
  threeDotsConfig: ThreeDotsConfig
  classes?: string
}

export const ThreeDotsMenu = ({
  classes,
  items,
  threeDotsConfig: { orientation = 'vertical', color = 'black', iconSize = 24 },
}: Props): JSX.Element => {
  const menuOptions = items
    .filter((item) => item.visible)
    .map((item, index) => {
      return (
        <MenuItem
          key={index}
          icon={item.icon}
          onClick={item.onClick}
          className={item.className}
          danger={item.dangerStyle}
          category={AnalyticsCategories.ORGANIGRAM}
          action={item.title}
        >
          {item.title}
        </MenuItem>
      )
    })

  const menu = <Menu className={[classes].join(' ')}>{menuOptions}</Menu>

  return (
    <DotsMenu
      iconColor={color === 'blue' ? COZERO_BLUE_80 : undefined}
      dotType={orientation}
      overlay={menu}
      placement="bottom"
      iconSize={iconSize}
    />
  )
}
