import React, { ReactElement, ReactNode, Suspense, lazy } from 'react'
import { TFunction } from 'react-i18next'

import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

const OverviewWrapperPage = lazy(() => import('@/pages/Overview/OverviewWrapperPage'))
const NotFoundPage = lazy(() => import('@/pages/404'))

const Wrapper = ({
  feature = 'log',
  children,
}: {
  feature?: string
  children: ReactNode
}): ReactElement => (
  <PrivateRoute>
    <FeatureRoute requiredFeature={feature}>
      <Suspense fallback={<SuspenseSpinner />}>{children}</Suspense>
    </FeatureRoute>
  </PrivateRoute>
)

/**
 * All routes in the /share/ namespace
 */
const getOverviewRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.overview.base,
    element: (
      <Wrapper>
        <OverviewWrapperPage />
      </Wrapper>
    ),
    children: [
      {
        path: `${routes.overview.base}/*`,
        element: (
          <Wrapper>
            <NotFoundPage />
          </Wrapper>
        ),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
]

export default getOverviewRoutes
