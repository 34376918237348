import { ReactElement } from 'react'

import { useOrgIsSupplier } from '@/hooks/useOrgIsSupplier'
import { usePricingFeature } from '@/hooks/usePricingFeature'

import CalculationChanges from './CalculationChanges'
import { CalculationChangesV2 } from './CalculationsChangesV2/CalculationChangesV2'
import CalculationsTabs from './CalculationsChangesV2/CalculationsTabs'

export const CalculationChangesIndex = (): ReactElement => {
  const { isFeatureEnabled } = usePricingFeature()
  const orgIsSupplier = useOrgIsSupplier()

  if (isFeatureEnabled('methodology-changelogs-v2') && !orgIsSupplier) {
    return (
      <>
        <CalculationsTabs />
        <CalculationChangesV2 />
      </>
    )
  } else if (isFeatureEnabled('methodology-changelogs') && !orgIsSupplier) {
    return <CalculationChanges />
  }

  return <></>
}
