import { useTranslation } from 'react-i18next'

import { Col, List, Row } from 'antd/es'

import { ApiIntegration } from '@cozero/models'

import IntegrationStatusTag from '@/molecules/IntegrationStatusTag'

import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import { WHITE_NEUTRAL } from '@/styles/variables'

import { useGetIntegrationsQuery } from '../../../redux/apiIntegrations/api'

import IntegrationActions from './IntegrationActions'
import classes from './classes.module.less'

function Integrations(): JSX.Element {
  const { t } = useTranslation('common')
  const { isFeatureEnabled } = usePricingFeature()

  const { data: apiIntegrations = [], isLoading: apiIntegrationsIsLoading } =
    useGetIntegrationsQuery()

  const renderTitleLabel = (integration: ApiIntegration): JSX.Element => {
    const isPremium = integration.key === 'sunhat' && !isFeatureEnabled('sunhat')

    return <IntegrationStatusTag active={integration.active} isPremium={isPremium} />
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Row className={classes.section}>
            <Col span={24}>
              <Title size="sm">{t('settings.integrations.title')}</Title>
            </Col>
            <Col span={24}>
              <Text size="xl" color="secondary">
                {t('settings.integrations.subtitle')}
              </Text>
            </Col>
          </Row>
          <List
            className="demo-loadmore-list"
            loading={apiIntegrationsIsLoading}
            itemLayout="horizontal"
            dataSource={apiIntegrations}
            renderItem={(integration) => (
              <List.Item
                actions={[<IntegrationActions key="actions" integration={integration} />]}
                className={classes.listItem}
              >
                <List.Item.Meta
                  avatar={
                    integration.logoUrl && (
                      <div
                        style={{
                          background: integration.logoBg ?? WHITE_NEUTRAL,
                          borderRadius: 5,
                        }}
                      >
                        <img
                          src={integration.logoUrl}
                          width={170}
                          height={105}
                          style={{ margin: 25 }}
                        />
                      </div>
                    )
                  }
                  title={
                    <div className={classes.titleContainer}>
                      <span>{integration.name}</span>
                      {renderTitleLabel(integration)}
                    </div>
                  }
                  description={integration.description as string}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  )
}

export default Integrations
