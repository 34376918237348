import {
  CompanyEmissionOverviewDTO,
  CreateLogDto,
  GetUserOptionsDto,
  SearchLogsResultDto,
  UpdateLogDto,
} from '@cozero/dtos'
import { ClientUserOption, Log, LogSorter, PageFilter, TableViewLog } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { PARTIAL_LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'
import { TAG_SEARCH_LOG_ENTRIES } from '../logEntries/tags'

import { TAG_LOG, TAG_PAGINATED_LOGS } from './tags'

export const GET_LOG = 'getLog'
export const CREATE_LOG = 'createLog'
export const EDIT_LOG = 'editLog'
export const HAS_LOG_ENTRIES = 'hasLogEntries'
export const DELETE_LOG = 'deleteLog'
export const GET_PAGINATED_LOGS = 'getPaginatedLogs'
export const GET_FULL_LOG_RANGE = 'getFullLogRange'
export const GET_BUSINESS_UNIT_OVERVIEW = 'getBusinessUnitOverview'
const logsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_LOG]: builder.query<Log | undefined, number | string>({
      query: (id) => ({
        url: logApiGatewayClient.logs.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
        params: {
          id,
        },
      }),
      providesTags: (result) => [{ type: TAG_LOG, id: result?.id }],
    }),
    [CREATE_LOG]: builder.mutation<Log, Partial<CreateLogDto>>({
      query: (log) => ({
        url: logApiGatewayClient.logs.CREATE,
        method: 'POST',
        data: log,
      }),
      invalidatesTags: (result) => [
        { type: TAG_LOG, id: result?.id },
        { type: TAG_PAGINATED_LOGS, id: PARTIAL_LIST },
      ],
    }),
    [GET_FULL_LOG_RANGE]: builder.query<
      { startDate?: Date; endDate?: Date },
      { selectedBusinessUnitId: number }
    >({
      query: (params) => ({
        url: logApiGatewayClient.logs.GET_RANGE,
        method: 'GET',
        params,
      }),
    }),
    [EDIT_LOG]: builder.mutation<Log, { id: number; log: Partial<UpdateLogDto> }>({
      query: ({ id, log }) => ({
        url: logApiGatewayClient.logs.UPDATE_ONE.replace(':id', id.toString()),
        method: 'PUT',
        data: log,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TAG_LOG, id: arg.id },
        { type: TAG_PAGINATED_LOGS, id: PARTIAL_LIST },
      ],
    }),
    [DELETE_LOG]: builder.mutation<{ count: number } | undefined, Log>({
      query: (log) => ({
        url: logApiGatewayClient.logs.DELETE_ONE.replace(':id', log.id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TAG_LOG, id: arg.id },
        { type: TAG_PAGINATED_LOGS, id: PARTIAL_LIST },
        TAG_SEARCH_LOG_ENTRIES,
      ],
    }),
    [GET_PAGINATED_LOGS]: builder.query<
      SearchLogsResultDto,
      {
        query: {
          type: string
          page: number
          pageSize: number
          filters?: Omit<PageFilter, 'options'>[]
        }
        businessUnitId?: number
        sort?: LogSorter[]
      }
    >({
      query: (log) => ({
        url: logApiGatewayClient.logs.SEARCH,
        method: 'POST',
        data: log,
      }),
      providesTags: (result) =>
        providesList<TableViewLog[], typeof TAG_PAGINATED_LOGS>(
          result?.logs,
          TAG_PAGINATED_LOGS,
          PARTIAL_LIST,
        ),
    }),
    [HAS_LOG_ENTRIES]: builder.query<
      boolean,
      { businessUnitId?: number; startDate?: Date; endDate?: Date }
    >({
      query: (params) => ({
        url: logApiGatewayClient.logEntries.EXISTS,
        method: 'GET',
        params,
      }),
    }),
    [GET_BUSINESS_UNIT_OVERVIEW]: builder.query<
      CompanyEmissionOverviewDTO,
      { businessUnitId: number; filters?: Omit<PageFilter, 'options'>[] }
    >({
      query: ({ businessUnitId, filters }) => ({
        url: logApiGatewayClient.logs.GET_BUSINESS_UNIT_OVERVIEW.replace(
          ':businessUnitId',
          businessUnitId.toString(),
        ),
        method: 'POST',
        data: { query: filters },
      }),
      extraOptions: {
        retryCondition: (_error, _queryArgs, { attempt }) => {
          return attempt < 5
        },
        backoff: async (attempt) => {
          const timeout = Math.pow(2, attempt) * 1000 // Increases delay exponentially between retries
          await new Promise((resolve) => setTimeout(resolve, timeout))
        },
      },
    }),
    getUserOptions: builder.query<ClientUserOption[], GetUserOptionsDto>({
      query: (params) => ({
        url: logApiGatewayClient.userOptions.GET_FOR_LOG_CREATION,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  useGetLogQuery,
  useLazyGetLogQuery,
  useCreateLogMutation,
  useHasLogEntriesQuery,
  useLazyHasLogEntriesQuery,
  useEditLogMutation,
  useDeleteLogMutation,
  useGetPaginatedLogsQuery,
  useGetFullLogRangeQuery,
  useLazyGetFullLogRangeQuery,
  useGetBusinessUnitOverviewQuery,
  useGetUserOptionsQuery,
} = logsApiSlice

export default logsApiSlice
