import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { Json2CsvOptions, json2csvAsync } from 'json-2-csv'

import { PageFilter } from '@cozero/models'

import { NodeToEmissionsMapType } from '@/redux/organigram/slice'
import { prettifyDate } from '@/utils/date'
import { NodeType, OrganigramNodeDatum } from '@/utils/tree'

import { getDatesFromPageFilters } from '../Organigram.utils'

export type CsvRow = {
  name: string
  path: string
  depth: number
  type: NodeType
  scope1: number
  scope2: number
  scope3: number
  total: number
} & Record<string, string | number>

// Recursive function that traverses the Organigram tree from root to children and returns a flat list of CSV Rows.
// While traversing, it also adds a `path` property to each row, which is a string representation of the path to the current node.
export const flattenTree = (
  data: OrganigramNodeDatum[],
  nodeToEmissionsMap: NodeToEmissionsMapType,
  path: string[] = [],
): CsvRow[] => {
  if (data.length === 0) {
    return []
  }

  return data.flatMap((node) => {
    const children = flattenTree(node.children || [], nodeToEmissionsMap, [...path, node.name])

    // Add a column for each level in the path ({ level_1: 'Root', level_2: 'Business unit', level_3: 'Location' })
    const levelColumns = [...path, node.name].reduce((acc, curr, index) => {
      return { ...acc, [`level_${index + 1}`]: curr }
    }, {})

    const nodeEmissions =
      node.attributes.type === NodeType.LOCATION
        ? nodeToEmissionsMap.locations[node.attributes.id]
        : nodeToEmissionsMap.businessUnits[node.attributes.id]

    return [
      {
        name: node.name,
        path: [...path, node.name].join(' > '),
        depth: path.length,
        type: node.attributes.type,
        scope1: Math.round(nodeEmissions.scope1 * 10) / 10,
        scope2: Math.round(nodeEmissions.scope2 * 10) / 10,
        scope3: Math.round(nodeEmissions.scope3 * 10) / 10,
        total: Math.round(nodeEmissions.total * 10) / 10,
        ...levelColumns,
      },
      ...children,
    ]
  })
}

const EXPORT_COLUMNS = ['path', 'depth', 'name', 'type', 'scope1', 'scope2', 'scope3', 'total']

export const getHeadersFromData = (data: CsvRow[]): string[] => {
  const longestRow = data.reduce(
    (acc, curr) => (Object.keys(curr).length > Object.keys(acc).length ? curr : acc),
    {},
  )

  const levelKeys = Object.keys(longestRow).filter((key) => key.startsWith('level_'))

  return [...EXPORT_COLUMNS, ...levelKeys]
}

export const getCsvBlob = async (data: CsvRow[], keys: Json2CsvOptions['keys']): Promise<Blob> => {
  const content = await json2csvAsync(data, { keys, emptyFieldValue: '' })

  return new Blob([content], { type: 'text/csv' })
}

export const getXlsxBlob = async (
  data: CsvRow[],
  columns: Partial<ExcelJS.Column>[],
): Promise<Blob> => {
  const workbook = new ExcelJS.Workbook()

  const worksheet = workbook.addWorksheet()
  worksheet.columns = columns
  worksheet.addRows(data)

  const buffer = await workbook.xlsx.writeBuffer()
  return new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
}

export const getExportFilename = ({
  filters,
  extension,
}: {
  filters: PageFilter[]
  extension: 'csv' | 'xlsx'
}): string => {
  const dates = getDatesFromPageFilters(filters)

  if (!dates) {
    return 'digital-twin-export.csv'
  }

  const prettyDates = dates.map((date) => prettifyDate(date, 'yyyy-MM-DD')).join('_')

  return `${`digital-twin-export_${prettyDates}`}.${extension}`
}

export const downloadBlob = (blob: Blob, filename: string): void => {
  saveAs(blob, filename)
}
