/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineBell, HiOutlineHome, HiOutlineLightBulb } from 'react-icons/hi'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Space } from 'antd'

import { routes } from '@cozero/utils'

import { UpdatesDrawer } from '@/pages/DashboardHome/UpdatesDrawer/UpdatesDrawer'

import NavMenuItem from '@/molecules/NavMenuItem'
import NavSubMenu from '@/molecules/NavSubMenu'

import Divider from '@/atoms/Divider'

import { useOrgIsSupplier } from '@/hooks/useOrgIsSupplier'
import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin } from '@/redux/auth'
import { useCountUnreadQuery } from '@/redux/userUpdates/api'
import { getDataCy } from '@/utils/string'

import { selectIsFinished } from '../../redux/onboarding'
import NavCompanyHeader from '../NavCompanyHeader'
import NavUserMenu from '../NavUserMenu'

import classes from './NavSidebar.module.less'
import { useMenuItems } from './NavSidebar.useMenuItems'
import { OrganizationMenu } from './OrganizationMenu/OrganizationMenu'

type Base = {
  key: string
  title: string
  feature: string
  icon?: ReactElement
  cy?: string
  newFeature?: boolean
  isDisabled?: boolean
  disabledTooltip?: string
  isPaywalled?: boolean
  excludeRoles?: string[]
}

export type PageItem = Base & {
  children?: ModuleItem[]
  route?: never
  excludeRoles?: string[]
}

export type ModuleItem = Base & {
  children?: never
  route: string
}

export type MenuItem = PageItem | ModuleItem

export const NavSidebar = ({
  trialNotification,
  supplierOnboarding,
  hideUserMenu,
}: {
  trialNotification: ReactNode
  supplierOnboarding?: boolean
  hideUserMenu?: boolean
}): ReactElement => {
  const { t } = useTranslation()
  const { isFeatureEnabled } = usePricingFeature()

  const [openSubMenuKey, setOpenSubMenuKey] = useState<string | null>(null)

  const isOnboardingFinished = useAppSelector(selectIsFinished)

  const [searchParams, setSearchParams] = useSearchParams()
  const [showAllUpdates, setShowAllUpdates] = useState(searchParams.get('allUpdates') === 'true')

  const toggleAllUpdates = (isOpen: boolean): void => {
    setSearchParams({ allUpdates: isOpen ? 'true' : 'false' })
    setShowAllUpdates(isOpen)
  }

  useEffect(() => {
    if (searchParams.get('allUpdates') !== 'true') {
      setShowAllUpdates(false)
    }
  }, [searchParams])

  const orgIsSupplier = useOrgIsSupplier()

  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const shouldSeeTrialNotification = isManagerOrAdmin

  const { menuItems } = useMenuItems()

  const { data: allCount } = useCountUnreadQuery()
  const navigate = useNavigate()

  const MenuItems = useMemo((): ReactNode => {
    return menuItems.map((item) => {
      if (item.children) {
        return (
          <NavSubMenu
            key={item.key}
            base={item.key}
            title={item.title}
            icon={item.icon}
            menuOpen={openSubMenuKey === item.key}
            onOpen={(base: string | null) => setOpenSubMenuKey(base)}
            items={item.children}
            isDisabled={item.isDisabled}
            isPaywalled={item.isPaywalled}
          />
        )
      }

      return (
        <NavMenuItem
          key={item.route ?? item.key}
          title={item.title}
          route={item.route}
          icon={item.icon}
          isDisabled={item.isDisabled}
          data-cy={getDataCy(item.title, item.key)}
          isPaywalled={item.isPaywalled}
        />
      )
    })
  }, [menuItems, openSubMenuKey])

  const handleSidebarClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (showAllUpdates) {
      setShowAllUpdates(false)
      e.stopPropagation()
    }
  }

  return (
    <>
      <aside className={classes.sider} id="nav-sider" onClick={handleSidebarClick}>
        <div className={classes.siderInnerContainer}>
          <NavCompanyHeader />

          <div className={classes.siderOnboardingSection}>
            <NavMenuItem
              title={t('onboarding.demo.get-started')}
              showFocusDot={!isOnboardingFinished}
              icon={<HiOutlineLightBulb />}
              route={routes.onboarding}
              onClick={
                supplierOnboarding
                  ? () => navigate(routes.log.factors.requestsPage.incomingRequest)
                  : undefined
              }
            />
            {isFeatureEnabled('log') && !orgIsSupplier && (
              <NavMenuItem
                title={t('dashboard.home.home.title')}
                icon={<HiOutlineHome />}
                route={routes.dashboardHome.home}
              />
            )}
            <NavMenuItem
              title={t('dashboard.home.all-updates.title')}
              icon={<HiOutlineBell />}
              isSideBar={true}
              counter={allCount?.count ? allCount?.count : undefined}
              onClick={() => toggleAllUpdates(!showAllUpdates)}
              isCounter={true}
              active={showAllUpdates}
            />

            <Divider />
          </div>

          <nav>{MenuItems}</nav>
        </div>

        <div>
          {shouldSeeTrialNotification && <div>{trialNotification}</div>}

          <Divider className={classes.settingsDivider} />

          <Space direction="vertical" size={8} className={classes.settingsContainer}>
            <OrganizationMenu />

            {!hideUserMenu && <NavUserMenu />}
          </Space>
        </div>
      </aside>
      <UpdatesDrawer isOpen={showAllUpdates} onClose={() => toggleAllUpdates(false)} />
    </>
  )
}
