import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { PageFilter } from '@cozero/models'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import {
  useGetBusinessUnitForOrganigramQuery,
  useLazyGetEmissionsForOrganigramQuery,
} from '@/redux/organigram'
import { setNodeToEmissionsMap } from '@/redux/organigram/slice'
import { OrganigramNodeDatum, computeGraphData, createNodeToEmissionsMap } from '@/utils/tree'

import { getDatesFromPageFilters } from './Organigram.utils'

type DateFilter =
  | {
      startDate: string
      endDate: string
    }
  | undefined

const getDateFilterFromPageFilters = (filters: PageFilter[]): DateFilter => {
  const dates = getDatesFromPageFilters(filters)

  if (!dates) {
    return undefined
  }

  const [start, end] = dates

  return {
    startDate: start.toISOString(),
    endDate: end.toISOString(),
  }
}

export const useOrganigramData = (): {
  isFetchingBusinessUnit: boolean
  isFetchingEmissions: boolean
  isErrorBusinessUnit: boolean
  isErrorEmissions: boolean
  refetchBusinessUnit: () => void
  graphData: OrganigramNodeDatum[]
  filters?: PageFilter[]
  setFilters: (filters: PageFilter[]) => void
} => {
  const dispatch = useDispatch()
  const [graphData, setGraphData] = useState<OrganigramNodeDatum[]>([])
  const [filters, setFilters] = useState<PageFilter[]>()

  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const businessUnitId = selectedBusinessUnit?.id

  const {
    data: organigramBusinessUnit,
    isFetching: isFetchingBusinessUnit,
    isError: isErrorBusinessUnit,
    refetch: refetchBusinessUnit,
  } = useGetBusinessUnitForOrganigramQuery(Number(businessUnitId), { skip: !businessUnitId })

  const [
    getEmissionsForOrganigram,
    { data: locationEmissions, isFetching: isFetchingEmissions, isError: isErrorEmissions },
  ] = useLazyGetEmissionsForOrganigramQuery()

  useEffect(() => {
    if (organigramBusinessUnit) {
      const computedGraphData = computeGraphData(organigramBusinessUnit.businessUnit)
      setGraphData([computedGraphData])
    }
  }, [organigramBusinessUnit])

  useEffect(() => {
    if (organigramBusinessUnit && filters) {
      getEmissionsForOrganigram(
        {
          id: Number(organigramBusinessUnit.businessUnit.id),
          datesFilter: getDateFilterFromPageFilters(filters as PageFilter[]),
          locationIds: organigramBusinessUnit.locationIds,
        },
        true,
      )
    }
  }, [organigramBusinessUnit, filters])

  useEffect(() => {
    if (graphData && locationEmissions) {
      const nodeToEmissionsMap = createNodeToEmissionsMap(graphData[0], locationEmissions)
      dispatch(setNodeToEmissionsMap(nodeToEmissionsMap))
    }
  }, [graphData, locationEmissions])

  return {
    isFetchingBusinessUnit,
    isFetchingEmissions,
    isErrorBusinessUnit,
    isErrorEmissions,
    graphData,
    filters,
    setFilters,
    refetchBusinessUnit,
  }
}
