import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaChevronDown } from 'react-icons/fa'

import { Collapse } from 'antd'

import classNames from 'classnames'

import classes from './ListCard.module.less'

interface HeaderProps {
  children: React.ReactNode
  onClick?: () => void
}

interface TitleProps {
  children: React.ReactNode
}

interface ContentProps {
  children: React.ReactNode
}

interface CollapsableProps {
  children: React.ReactNode
  customHeaderLabel?: {
    open?: string
    close?: string
  }
  isExpanded?: boolean
  defaultExpanded?: boolean
  onChange?: (expanded: boolean) => void
}

interface ListCardProps {
  children: React.ReactNode
  selected?: boolean
  selectable?: boolean
  onClick?: () => void
}

const Header: React.FC<HeaderProps> = React.memo(function Header({ children, onClick }) {
  return (
    <div className={classes.header} onClick={onClick}>
      {children}
    </div>
  )
})

const Title: React.FC<TitleProps> = React.memo(function Title({ children }) {
  return <span className={classes.title}>{children}</span>
})

const Content: React.FC<ContentProps> = React.memo(function Content({ children }) {
  return <div>{children}</div>
})

const Collapsable: React.FC<CollapsableProps> = React.memo(function Collapsable(props) {
  const { t } = useTranslation()
  const [internalExpanded, setInternalExpanded] = React.useState(
    props.isExpanded ?? props.defaultExpanded ?? false,
  )
  const isControlled = props.isExpanded !== undefined
  const activeKey = internalExpanded ? 'panel' : undefined

  const handleChange = (): void => {
    if (!isControlled) {
      setInternalExpanded(!internalExpanded)
    }

    if (props.onChange) {
      props.onChange(!internalExpanded)
    }
  }

  const header = (() => {
    if (props.customHeaderLabel?.open && !internalExpanded) {
      return props.customHeaderLabel.open
    } else if (props.customHeaderLabel?.close && internalExpanded) {
      return props.customHeaderLabel.close
    }

    return internalExpanded ? t('actions.show-less') : t('actions.show-more')
  })()

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Collapse
        className={classes.collapsable}
        activeKey={activeKey}
        ghost={true}
        onChange={handleChange}
        expandIcon={({ isActive }) => (
          <FaChevronDown
            style={{
              transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease',
            }}
          />
        )}
      >
        <Collapse.Panel header={header} key="panel">
          {props.children}
        </Collapse.Panel>
      </Collapse>
    </div>
  )
})

const ListCard: React.FC<ListCardProps> & {
  Header: React.FC<HeaderProps>
  Title: React.FC<TitleProps>
  Content: React.FC<ContentProps>
  Collapsable: React.FC<CollapsableProps>
} = (props) => {
  return (
    <div
      className={classNames(classes.listCard, {
        [classes.selectable]: props.selectable,
        [classes.selected]: props.selected,
      })}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  )
}

ListCard.Header = Header
ListCard.Title = Title
ListCard.Content = Content
ListCard.Collapsable = Collapsable

export { ListCard }
