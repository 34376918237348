import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiPlus } from 'react-icons/hi'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useNavigate } from 'react-router'

import { Col, Row } from 'antd/es'

import { Product } from '@cozero/models'
import { routes } from '@cozero/utils'

import ProductsTable from '@/molecules/ProductsTable'

import Button from '@/atoms/Button'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useLogContext } from '@/contexts/log'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin } from '@/redux/auth'
import { useGetBusinessUnitQuery } from '@/redux/businessUnits'

import classes from './classes.module.less'

const Products = ({ id, disabled }: { id?: string; disabled: boolean }): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const { getProduct, loading: logLoading } = useLogContext()
  const { data: businessUnit, isLoading: businessUnitLoading } = useGetBusinessUnitQuery(
    { id: id ?? '' },
    { skip: !id },
  )

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-between" className={classes.header}>
          <Col>
            <Title size="xs" className={classes.tableTitle}>
              {t('settings.bu.products')}
            </Title>
          </Col>
        </Row>
        <Row justify="end" className={classes.header}>
          <Col>
            <Button
              category={AnalyticsCategories.BUSINESS_UNIT}
              action="create-product"
              type="primary"
              prefixIcon={<HiPlus />}
              disabled={disabled}
              onClick={() => navigate(routes.log.products.upsert.base)}
            >
              {t('settings.bu.create-product')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ProductsTable
              loading={businessUnitLoading || logLoading}
              products={(businessUnit?.products as Product[]) ?? []}
              getProduct={getProduct}
              businessUnitsAllowed={true}
              isManagerOrAdmin={isManagerOrAdmin}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Products
