import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { Col, Row, Spin, Tabs, message } from 'antd/es'
import { Store } from 'antd/es/form/interface'

import { AxiosError } from 'axios'

import { ErrorCodes } from '@cozero/errors/error-codes'
import { BusinessUnit } from '@cozero/models'
import { routes } from '@cozero/utils'

import ConfirmationModal from '@/organisms/ConfirmationModal'

import BusinessUnitsDropdown from '@/molecules/BusinessUnitsDropdown'
import TagInput from '@/molecules/TagInput'

import Alert from '@/atoms/Alert'
import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAdminContext } from '@/contexts/admin'
import { useDelayedLoading } from '@/hooks/useDelayedLoading'
import {
  selectSelectedBusinessUnit,
  useCreateBusinessUnitMutation,
  useEditBusinessUnitMutation,
  useGetActiveBusinessUnitsQuery,
  useGetBusinessUnitQuery,
} from '@/redux/businessUnits'

import Advanced from './Advanced'
import Locations from './Locations'
import Products from './Products'
import SubUnits from './SubUnits'
import Users from './Users'
import classes from './classes.module.less'

const { TabPane } = Tabs

function handleResponseMessage(statusCode: string | undefined): string {
  if (!statusCode) {
    return 'settings.bu.success-insert'
  }

  switch (statusCode) {
    case ErrorCodes.OVER_LIMIT.statusCode:
      return 'settings.bu.error-limit'
    case ErrorCodes.CREATE_BUSINESS_UNIT_ROOT_EXISTS.statusCode:
      return 'settings.bu.error-root-exists'
    default:
      return 'settings.bu.error-generic'
  }
}

export interface BusinessUnitDetails {
  title: string
  tags: string[]
  parentUnit: string | undefined
  active: boolean
}

const UpsertBusinessUnit = (): ReactElement => {
  const { t } = useTranslation('common')
  const [form] = Form.useForm()
  const { id } = useParams()
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const [createBusinessUnit] = useCreateBusinessUnitMutation()
  const [updateBusinessUnit] = useEditBusinessUnitMutation()

  const navigate = useNavigate()
  const { error } = useAdminContext()
  const { data: businessUnit, isLoading: businessUnitLoading } = useGetBusinessUnitQuery(
    { id: id ?? '' },
    { skip: !id },
  )
  const { data: businessUnits = [] } = useGetActiveBusinessUnitsQuery(
    { businessUnitScopeId: selectedBusinessUnit?.id ?? -1, populate: true },
    { skip: !selectedBusinessUnit },
  )
  const [parentUnitId, setParentUnitId] = useState<number | undefined>(undefined)
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [formValues, setFormValues] = useState<Store>()
  const [businessUnitDetails, setBusinessUnitDetails] = useState<BusinessUnitDetails>()
  const [delayedLoading] = useDelayedLoading(businessUnitLoading)
  const [activeTab, setActiveTab] = useState('settings')
  const upsertBusinessUnit = async (values: Store): Promise<void> => {
    const tags = values.tags
    if (tags) {
      delete values.tags
    }
    const metadata = Object.assign({}, businessUnit?.metadata)
    metadata.tags = tags
    const changes: Partial<BusinessUnit> = {
      ...values,
      ...(parentUnitId ? ({ parentUnit: { id: parentUnitId } } as BusinessUnit) : {}),
      metadata,
    }

    if (id) {
      await updateBusinessUnit({ id, data: changes }).unwrap()
      message.success(t('settings.bu.success-edit'))
    } else {
      try {
        const newBusinessUnit = await createBusinessUnit({
          ...changes,
          ...(parentUnitId ? { parentUnitId } : {}),
        }).unwrap()
        const error = (newBusinessUnit as unknown as AxiosError<{ statusCode?: string }>)?.response
          ?.data
        const popup = error ? message.error : message.success
        const messageKey = handleResponseMessage(error?.statusCode)
        const text = t(messageKey)

        popup(text)
        navigate(routes.organizationSettings.businessUnits.base)
      } catch (error) {
        message.error(error.data.message, 5)
      }
    }
  }

  async function onFormFinish(values: Store): Promise<void> {
    const valid = await form.validateFields()
    if (id) {
      await upsertBusinessUnit(values)
    } else {
      if (valid) {
        setIsFormValid(true)
        setFormValues(values)
        setBusinessUnitDetails({
          title: values.title ?? '',
          tags: values.tags !== undefined ? values.tags : [],
          parentUnit:
            values.parentUnit !== 'root'
              ? businessUnits.find((obj) => obj.id === values.parentUnit)?.title
              : undefined,
          active: values.active,
        })
        setOpenConfirmationModal(!openConfirmationModal)
      }
    }
  }

  const businessUnitsWithoutCurrent = useMemo(
    () =>
      businessUnits.filter(
        (obj) =>
          obj.id.toString() !== id?.toString() &&
          obj.id.toString() !== businessUnit?.parentUnitId?.toString() &&
          (!businessUnit || !obj.ancestorIds.includes(businessUnit.id)),
      ),
    [businessUnits.length, id],
  )

  useEffect(() => {
    form.setFieldsValue(businessUnit)
  }, [businessUnit])

  useEffect(() => {
    setActiveTab('settings')
  }, [id])

  return (
    <Spin spinning={delayedLoading}>
      <Row>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={24}>
              <Title className={classes.formTitle}>
                {businessUnit
                  ? `${t('business-unit.edit', { name: businessUnit.title })}`
                  : t('business-unit.create')}
              </Title>
            </Col>
            <Col span={24}>
              <Text size="xl" color="secondary">
                {t('settings.bu.create-subtitle')}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Tabs defaultActiveKey={'settings'} activeKey={activeTab} onChange={setActiveTab}>
                <TabPane tab={t('business-unit.tabs.settings')} key={'settings'}>
                  <Row>
                    {businessUnit && !businessUnit.active && (
                      <Col span={24}>
                        <Alert type={'warning'} className={classes.alert}>
                          <Text size="xl">{t('settings.bu.disable.alert')}</Text>
                        </Alert>
                      </Col>
                    )}
                    <Col span={12}>
                      <Form
                        category={AnalyticsCategories.BUSINESS_UNIT}
                        layout="vertical"
                        onFinish={onFormFinish}
                        className={classes.formContainer}
                        form={form}
                        disabled={businessUnit && !businessUnit.active}
                        initialValues={{
                          ...businessUnit,
                          tags: businessUnit?.metadata?.tags,
                          active: businessUnit?.active ?? true,
                        }}
                      >
                        <Row gutter={20}>
                          <Form.Item id="id" name="id" hidden />
                          <Col span={24}>
                            <Form.Item
                              label={t('settings.bu.title-field')}
                              id="title"
                              name="title"
                              rules={[
                                {
                                  required: true,
                                  message: t('settings.bu.title-msg'),
                                },
                              ]}
                              data-cy="bussiness-unit-title"
                            >
                              <InputField />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <div className={classes.activeHintContainer}>
                              <Text size="xl" color="secondary">
                                {t('settings.bu.active.subtitle')}
                              </Text>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              name="tags"
                              label={t('location.tags')}
                              valuePropName="tags"
                              data-cy="business-unit-tags"
                            >
                              <TagInput />
                            </Form.Item>
                          </Col>
                        </Row>
                        {businessUnit?.metadata?.externalId && (
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                className={classes.formItem}
                                label={t('settings.bu.product-code')}
                              >
                                <InputField disabled value={businessUnit?.metadata?.externalId} />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        {!businessUnit && (
                          <Col span={24}>
                            <Form.Item
                              label={t('settings.bu.parent-field')}
                              id="parentUnit"
                              name="parentUnit"
                              data-cy="business-unit-parentUnit"
                              extra={t('settings.bu.descriptions.parent-unit')}
                            >
                              <BusinessUnitsDropdown
                                businessUnits={businessUnitsWithoutCurrent}
                                showNoParent={true}
                                defaultValue={parentUnitId}
                                onChange={(value) => setParentUnitId(parseInt(value))}
                              />
                            </Form.Item>
                          </Col>
                        )}
                        <Row justify="end">
                          <Form.Item shouldUpdate={true}>
                            {() => (
                              <Button
                                category={AnalyticsCategories.BUSINESS_UNIT}
                                action="save"
                                type="primary"
                                htmlType="submit"
                                disabled={
                                  (!form.isFieldTouched('title') &&
                                    !form.isFieldTouched('active') &&
                                    !form.isFieldTouched('tags')) ||
                                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                                    .length
                                }
                                data-cy="save-business-unit-btn"
                              >
                                {t('actions.save')}
                              </Button>
                            )}
                          </Form.Item>
                          {isFormValid && formValues && (
                            <ConfirmationModal
                              openModal={openConfirmationModal}
                              setOpenModal={setOpenConfirmationModal}
                              title={t('business-unit.modal.title')}
                              onOk={() => upsertBusinessUnit(formValues)}
                              businessUnitDetails={businessUnitDetails}
                              confirmationText={t(
                                'business-unit.modal.create-businessUnit-confirm',
                              )}
                            />
                          )}
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab={t('business-unit.tabs.children')} key={'children'}>
                  <SubUnits id={id} disabled={!!businessUnit && !businessUnit.active} />
                </TabPane>
                <TabPane tab={t('business-unit.tabs.locations')} key={'locations'}>
                  <Locations id={id} disabled={!!businessUnit && !businessUnit.active} />
                </TabPane>
                <TabPane tab={t('business-unit.tabs.products')} key={'products'}>
                  <Products id={id} disabled={!!businessUnit && !businessUnit.active} />
                </TabPane>
                <TabPane tab={t('business-unit.tabs.users')} key={'users'}>
                  <Users id={id} disabled={!!businessUnit && !businessUnit.active} />
                </TabPane>
                <TabPane tab={t('business-unit.tabs.advanced')} key={'advanced'}>
                  <Advanced id={id} />
                </TabPane>
              </Tabs>
            </Col>
          </Row>

          {error && <Alert message="Error" type="error" className={classes.error} />}
        </Col>
      </Row>
    </Spin>
  )
}

export default UpsertBusinessUnit
