import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiPlus } from 'react-icons/hi'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useNavigate } from 'react-router'

import { Col, Row, Spin } from 'antd/es'

import { User } from '@cozero/models'
import { routes } from '@cozero/utils'

import UsersTable from '@/molecules/UsersTable'

import Button from '@/atoms/Button'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import { useGetBusinessUnitQuery } from '@/redux/businessUnits'

import classes from './classes.module.less'

const Users = ({ id, disabled }: { id?: string; disabled: boolean }): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const { data: businessUnit, isLoading: businessUnitLoading } = useGetBusinessUnitQuery(
    { id: id ?? '' },
    { skip: !id },
  )

  return (
    <Spin spinning={businessUnitLoading}>
      <Row>
        <Col span={24}>
          <Row justify="space-between" className={classes.header}>
            <Col>
              <Title size="xs" className={classes.tableTitle}>
                Users
              </Title>
            </Col>
          </Row>
          <Row justify="end" className={classes.header}>
            <Col>
              <Button
                category={AnalyticsCategories.BUSINESS_UNIT}
                action="create-user"
                type="primary"
                prefixIcon={<HiPlus />}
                disabled={disabled}
                onClick={() =>
                  navigate(
                    `${routes.organizationSettings.users.addUser}?businessUnitId=${businessUnit?.id}`,
                  )
                }
              >
                {t('settings.bu.create-user')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <UsersTable
                userRole={user?.role?.type}
                users={(businessUnit?.users as User[]) || []}
                businessUnitsAllowed={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  )
}

export default Users
