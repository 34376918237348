/* eslint react-hooks/exhaustive-deps: 2 */

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

/**
 * A utility hook to make delaying loading states easier
 * @param [loading = false] - the loading state needed to be delayed
 * @param [delay = 500] - Amount of ms the loading state should be delayed for
 * @returns a tuple containing the loading state and a function to change the loading state
 *
 * @example
 *
 * import { useDelayedLoading } from "@/hooks/useDelayedLoading";
 * export const MyComponent = (): ReactElement => {
 *   const [loading, setLoading] = useDelayedLoading(false, 2500);
 *   const loadDataFromApi = async () => {
 *    try{
 *      setLoading(true);
 *      const result = await fetch(...);
 *      ...
 *    }
 *    finally{
 *      setLoading(false);
 *    }
 *   }
 *   return loading ? "Loading" : "You are a wizard, Harry.";
 * }
 */
export const useDelayedLoading = (
  loading = false,
  delay = 500,
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [delayedLoading, setDelayedLoading] = useState(loading)
  const timeoutRef = useRef<number | null>(null)

  useEffect(() => {
    if (!loading) {
      timeoutRef.current = window.setTimeout(() => setDelayedLoading(false), delay)
    }
    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current)
      }
    }
  }, [loading, delay])

  useEffect(() => {
    if (loading) {
      setDelayedLoading(true)
    }
  }, [loading])

  return [delayedLoading, setDelayedLoading]
}
