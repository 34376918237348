import React, { HTMLAttributes, ReactElement, ReactNode, memo, useMemo } from 'react'
import {
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
  HiOutlineLightBulb,
  HiOutlineStar,
  HiOutlineX,
  HiOutlineXCircle,
} from 'react-icons/hi'

import { Col, Row } from 'antd'

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import classNames from 'classnames'

import { COZERO_BLUE_80 } from '@/styles/variables'

import Title from '../Title'

import classes from './Alert.module.less'

export interface AlertProps {
  type?: 'default' | 'info' | 'danger' | 'error' | 'warning' | 'success' | 'loading'
  children?: ReactNode
  title?: string
  message?: string | ReactElement
  textColor?: string
  icon?: 'info' | 'exclamation' | 'times' | 'no-icon' | 'star' | 'lightbulb'
  alignIcon?: 'center-vertically' | 'center-at-title'
  className?: string
  onClose?: () => void
  action?: ReactNode
}

const Icon = memo(
  ({
    className,
    type,
    icon,
  }: {
    className?: string
    icon: AlertProps['icon']
    type: AlertProps['type']
  }): JSX.Element => {
    switch (type) {
      case 'info':
        if (icon === 'star') {
          return <HiOutlineStar color={COZERO_BLUE_80} className={className} />
        }
        if (icon === 'lightbulb') {
          return <HiOutlineLightBulb color={COZERO_BLUE_80} className={className} />
        }
        return <HiOutlineInformationCircle color={COZERO_BLUE_80} className={className} />
      case 'loading':
        return <SyncOutlined color={COZERO_BLUE_80} className={className} />
      case 'warning':
        return icon === 'times' ? (
          <WarningOutlined className={className} />
        ) : (
          <HiOutlineExclamationCircle className={className} />
        )
      case 'danger':
        return icon === 'times' ? (
          <HiOutlineXCircle className={className} />
        ) : (
          <CloseCircleOutlined className={className} />
        )
      case 'success':
        return <CheckCircleOutlined />
      default:
        return icon === 'no-icon' ? <></> : <HiOutlineInformationCircle className={className} />
    }
  },
)

Icon.displayName = 'AlertIcon'

const Alert = ({
  type = 'default',
  children,
  message,
  textColor,
  icon,
  alignIcon = 'center-vertically',
  title,
  onClose,
  className,
  action,
  ...rest
}: AlertProps & HTMLAttributes<HTMLDivElement>): ReactElement | null => {
  const alertClasses = useMemo(
    () => [classes.alert, classes[type], className].join(' '),
    [type, className],
  )
  if (title && alignIcon === 'center-vertically') {
    return (
      <div className={classNames(alertClasses, classes.flexStart)} role="alert" {...rest}>
        <div className={classes.iconWrapper}>
          <Icon type={type} icon={icon} className={classes.iconSmall} />
        </div>
        <div>
          <Title className={classes.title} size="xs">
            {title}
          </Title>
          <div style={{ color: textColor }}>{message}</div>
        </div>
        {action && <div className={classes.action}>{action}</div>}
        {onClose && (
          <div className={classes.buttonWrapper}>
            <button className={classes.button} onClick={onClose}>
              <HiOutlineX className={classNames(classes.closable, classes.iconSmall)} />
            </button>
          </div>
        )}
      </div>
    )
  }
  if (title && alignIcon === 'center-at-title') {
    return (
      <div className={alertClasses} role="alert" {...rest}>
        <Row align="middle" wrap={false} className={classes.marginBottom}>
          <Col flex="40px" className={classes.buttonWrapper}>
            <Icon
              type={type}
              icon={icon}
              className={classNames(classes.marginRight, classes.iconMedium)}
            />
          </Col>
          <Col flex="auto">
            <h2 className={classes.title}>{title}</h2>
          </Col>
          <Col flex="none">
            {onClose && (
              <button className={classes.button} onClick={onClose}>
                <HiOutlineX className={classNames(classes.closable, classes.iconMedium)} />
              </button>
            )}
            {action && <div className={classes.action}>{action}</div>}
          </Col>
        </Row>
        <Row wrap={false}>
          <Col flex="40px"></Col>
          <Col flex="auto">
            <div style={{ color: textColor }}>{message}</div>
          </Col>
          <Col flex="none"></Col>
        </Row>
      </div>
    )
  }
  if (!title && (message || children)) {
    return (
      <div className={classNames(alertClasses, classes.flexStart)} role="alert" {...rest}>
        <div className={classes.iconWrapper}>
          <Icon type={type} icon={icon} className={classes.iconSmall} />
        </div>

        <div style={{ color: textColor }}>{children || message}</div>
        {action && <div className={classes.action}>{action}</div>}

        {onClose && (
          <div className={classes.buttonWrapper}>
            <button className={classes.button} onClick={onClose}>
              <HiOutlineX className={classNames(classes.closable, classes.iconSmall)} />
            </button>
          </div>
        )}
      </div>
    )
  }
  throw new Error('Set an alert message, please')
}

export default memo(Alert)
