import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { HiDownload, HiOutlineDotsHorizontal } from 'react-icons/hi'
import { useSelector } from 'react-redux'

import { Dropdown } from 'antd/es'

import { PageFilter } from '@cozero/models'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { selectNodeToEmissionsMap } from '@/redux/organigram/slice'
import { CINDER_BLUE_50 } from '@/styles/variables'
import { OrganigramNodeDatum } from '@/utils/tree'

import classes from './OrganigramExportDropdown.module.less'
import {
  downloadBlob,
  flattenTree,
  getCsvBlob,
  getExportFilename,
  getHeadersFromData,
  getXlsxBlob,
} from './OrganigramExportDropdown.utils'

interface Props {
  disabled: boolean
  data: OrganigramNodeDatum[]
  filters: PageFilter[]
}

export const OrganigramExportDropdown = ({ disabled, data, filters }: Props): ReactElement => {
  const { t } = useTranslation('common')
  const { trackAction } = useMixpanel()
  const nodeToEmissionsMap = useSelector(selectNodeToEmissionsMap)

  const handleExport = useCallback(
    async (extension: 'csv' | 'xlsx') => {
      trackAction(AnalyticsCategories.ORGANIGRAM, 'export', {
        key: 'organigram',
        format: extension,
      })

      if (data) {
        const flattenedData = flattenTree(data, nodeToEmissionsMap)
        const headers = getHeadersFromData(flattenedData)

        const columns = headers.map((field) => {
          if (field.startsWith('level_')) {
            const [key, index] = field.split('_')
            const title = t(`organigram.export.columns.${key}`, { index })
            return [field, title] as const
          }

          const title = t(`organigram.export.columns.${field}`)
          return [field, title] as const
        })

        const filename = getExportFilename({ filters, extension })

        if (extension === 'csv') {
          const options = columns.map(([field, title]) => ({ field, title }))

          const blob = await getCsvBlob(flattenedData, options)
          downloadBlob(blob, filename)
        }

        if (extension === 'xlsx') {
          const options = columns.map(([key, header]) => ({ key, header }))

          const blob = await getXlsxBlob(flattenedData, options)
          downloadBlob(blob, filename)
        }
      }
    },
    [data, nodeToEmissionsMap],
  )

  return (
    <Dropdown
      trigger={['hover']}
      placement="bottomRight"
      overlayClassName={classes.dotsMenu}
      menu={{
        items: [
          {
            key: 'csv-export',
            label: t('organigram.export.button.csv'),
            icon: <HiDownload />,
            disabled: disabled,
            onClick: () => handleExport('csv'),
          },
          {
            key: 'xlsx-export',
            label: t('organigram.export.button.xlsx'),
            icon: <HiDownload />,
            disabled: disabled,
            onClick: () => handleExport('xlsx'),
          },
        ],
      }}
    >
      <HiOutlineDotsHorizontal className={classes.dotsIcon} color={CINDER_BLUE_50} size={18} />
    </Dropdown>
  )
}
