import React, { ReactElement, memo } from 'react'

import classNames from 'classnames'

import Text from '@/atoms/Text'

import classes from './LoadingSpinner.module.less'

type Size = '2xs' | 'xs' | 'sm' | 'md' | 'lg'

interface SpinnerProps {
  size: Size
  color: 'grey' | 'white' | 'dark'
  className?: string
}

export const SpinnerElement = memo(({ color, size, className }: SpinnerProps): ReactElement => {
  SpinnerElement.displayName = 'SpinnerElement'

  return (
    <div
      className={classNames(
        classes.spinner,
        classes[size],
        classes[color],
        className,
        'spinner-element',
      )}
    >
      <div />
      <div />
      <div />
    </div>
  )
})

type LoadingSpinnerProps = {
  title?: string
  text?: string
  size?: Size
  color?: 'grey' | 'white' | 'dark'
  className?: string
}

const LoadingSpinner = ({
  title,
  text,
  size = 'sm',
  color = 'grey',
  className = '',
}: LoadingSpinnerProps): ReactElement => {
  return (
    <div className={classNames(classes.root, className, classes[size], classes[color])}>
      <div className={classes.row}>
        <SpinnerElement size={size} color={color} />

        {title && (
          <Text
            className={classes.title}
            fontWeight="semibold"
            mode="title"
            size="xs"
            color="secondary"
          >
            {title}
          </Text>
        )}
      </div>

      {text && (
        <Text
          className={classes.text}
          fontWeight="semibold"
          mode="paragraph"
          color="disabled"
          size="lg"
        >
          {text}
        </Text>
      )}
    </div>
  )
}

export default memo(LoadingSpinner)
