/* eslint react-hooks/exhaustive-deps: 2 */
import { useEffect, useMemo } from 'react'

import store, { useAppDispatch } from '..'

import { ActivityDataSourceQuery, SelectOptionDto } from '@cozero/dtos'
import { ActivityDataSourceWithChildren } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import {
  GET_ACTIVITY_DATA_SOURCES_TREE,
  TAG_ACTIVITY_DATA_SOURCES,
  TAG_ACTIVITY_DATA_SOURCES_FIND_MANY,
} from './constants'
import { getAdsWithoutStructureStorageKey } from './mainSliceAdsEndpoints'

const activityDataSourceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_ACTIVITY_DATA_SOURCES_TREE]: builder.query<
      ActivityDataSourceWithChildren[],
      { subcategoryId: number; calculationMethodId?: number }
    >({
      query: ({ subcategoryId, calculationMethodId }) => ({
        url: logApiGatewayClient.activity_data_sources.GET_MANY,
        method: 'GET',
        params: { subcategoryId, calculationMethodId },
      }),
      providesTags: (result) =>
        providesList<ActivityDataSourceWithChildren[], typeof TAG_ACTIVITY_DATA_SOURCES>(
          result,
          TAG_ACTIVITY_DATA_SOURCES,
        ),
    }),
    getActivityDataSourceOptions: builder.query<SelectOptionDto[], void>({
      query: () => ({
        url: logApiGatewayClient.activity_data_sources.GET_OPTIONS,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<SelectOptionDto[], typeof TAG_ACTIVITY_DATA_SOURCES_FIND_MANY>(
          result,
          TAG_ACTIVITY_DATA_SOURCES_FIND_MANY,
        ),
    }),
  }),
})

export const { useGetActivityDataSourcesTreeQuery, useGetActivityDataSourceOptionsQuery } =
  activityDataSourceSlice

/**
 * A custom hook that wraps `useGetActivityDataSourcesWithoutStructureQuery` to populate the initial response
 * with data from `localStorage` before fetching from the API.
 *
 * This ensures that the UI has immediate access to cached data while a background request
 * revalidates the latest data.
 *
 * @param {ActivityDataSourceQuery} queryParams - The query parameters for fetching activity data sources.
 * @param {Parameters<typeof apiSlice.useGetActivityDataSourcesWithoutStructureQuery>[1]} options - Optional configuration for the query.
 * @returns {ReturnType<typeof apiSlice.useGetActivityDataSourcesWithoutStructureQuery>} The query result, including `data`, `isFetching`, and `error`.
 */
export const useGetActivityDataSourcesWithoutStructureQuery: typeof apiSlice.useGetActivityDataSourcesWithoutStructureQuery =
  (queryParams, options) => {
    const query = activityDataSourceSlice.useGetActivityDataSourcesWithoutStructureQuery(
      queryParams,
      {
        ...options,
        refetchOnMountOrArgChange: options?.refetchOnMountOrArgChange ?? true,
      },
    )

    const dispatch = useAppDispatch()

    const memoizedParams = useMemo(
      () => queryParams,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [getAdsWithoutStructureStorageKey(queryParams)],
    )

    useEffect(() => {
      const rtkQueryCacheEntry = apiSlice.endpoints.getActivityDataSourcesWithoutStructure.select(
        memoizedParams,
      )(store.getState())
      if (rtkQueryCacheEntry.data) {
        return
      }

      try {
        const localStorageEntry = localStorage.getItem(
          getAdsWithoutStructureStorageKey(memoizedParams),
        )
        if (!localStorageEntry) {
          return
        }

        dispatch(
          apiSlice.util.upsertQueryData(
            'getActivityDataSourcesWithoutStructure',
            memoizedParams as void | ActivityDataSourceQuery,
            JSON.parse(localStorageEntry),
          ),
        )
      } catch (error) {
        return
      }
    }, [dispatch, memoizedParams])

    return query
  }
