import { PERMISSIONS } from '@cozero/models'

import { useHasPermissionsQuery } from '@/redux/permissions'

export const usePermissions = (
  permissions: PERMISSIONS[],
): { isLoading: boolean; hasPermissions: boolean } => {
  const { data, isLoading } = useHasPermissionsQuery({
    permissions,
  })

  return {
    isLoading,
    hasPermissions: Boolean(data?.hasPermissions),
  }
}
