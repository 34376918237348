import { GetManyTargetsDto, TargetWithEmissionsDetailsDto, UpsertTargetDto } from '@cozero/dtos'
import { ActGraphData, Target, TargetLevel, TargetTemplate } from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import { LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import { TAG_REDUCTION_EFFORT_GRAPH, TAG_YEAR_ON_YEAR_DATA } from '../actAnalytics/constants'
import { TAG_CONSOLIDATED_GRAPH } from '../actGraphs'
import apiSlice from '../api'
import {
  TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES,
  TAG_GET_STRATEGY_BUILDER_STATE,
} from '../strategyBuilder'

import { TAG_TARGETS, TAG_TARGET_GRAPH, TAG_TARGET_TEMPLATES, TARGETS_WITH_EMISSIONS } from './tags'

const targetsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTargets: builder.query<TargetWithEmissionsDetailsDto[], GetManyTargetsDto>({
      query: (params) => ({
        url: actApiGatewayClient.targets.GET_MANY,
        method: 'GET',
        params: params,
      }),
      providesTags: (result) =>
        providesList<TargetWithEmissionsDetailsDto[], typeof TARGETS_WITH_EMISSIONS>(
          result,
          TARGETS_WITH_EMISSIONS,
        ),
    }),

    getTarget: builder.query<Target, Target['id']>({
      query: (id) => ({
        url: actApiGatewayClient.targets.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_TARGETS, id: result?.id }],
    }),

    createTarget: builder.mutation<Target, UpsertTargetDto>({
      query: (target) => ({
        url: actApiGatewayClient.targets.CREATE,
        method: 'POST',
        data: target,
      }),
      invalidatesTags: [
        { type: TARGETS_WITH_EMISSIONS, id: LIST },
        TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES,
        TAG_GET_STRATEGY_BUILDER_STATE,
      ],
      extraOptions: { maxRetries: 0 },
    }),

    editTarget: builder.mutation<Target, UpsertTargetDto>({
      query: (target) => ({
        url: actApiGatewayClient.targets.UPDATE_ONE.replace(':id', target?.id?.toString() ?? ''),
        method: 'PUT',
        data: target,
      }),
      invalidatesTags: (result) => [
        { type: TAG_TARGETS, id: result?.id },
        { type: TARGETS_WITH_EMISSIONS, id: LIST },
        TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES,
        TAG_GET_STRATEGY_BUILDER_STATE,
        TAG_YEAR_ON_YEAR_DATA,
        TAG_REDUCTION_EFFORT_GRAPH,
        TAG_TARGET_GRAPH,
        TAG_CONSOLIDATED_GRAPH,
      ],
      extraOptions: { maxRetries: 0 },
    }),

    deleteTarget: builder.mutation<
      Target,
      { id: Target['id']; level: TargetLevel; businessUnitId?: number }
    >({
      query: ({ id, businessUnitId, level }) => ({
        url: actApiGatewayClient.targets.DELETE_ONE.replace(':id', id?.toString() ?? ''),
        method: 'DELETE',
        params: {
          businessUnitId,
          level,
        },
      }),
      invalidatesTags: (result) => [
        { type: TARGETS_WITH_EMISSIONS, id: LIST },
        { type: TAG_TARGETS, id: result?.id },
        TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES,
        TAG_GET_STRATEGY_BUILDER_STATE,
        TAG_TARGET_GRAPH,
      ],
    }),

    getTargetTemplates: builder.query<TargetTemplate[], void>({
      query: () => ({
        url: actApiGatewayClient.targets.GET_TEMPLATES,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<TargetTemplate[], typeof TAG_TARGET_TEMPLATES>(result, TAG_TARGET_TEMPLATES),
    }),

    getTargetGraph: builder.query<
      ActGraphData[][],
      { selectedTargets: number[]; selectedBusinessUnitId: number }
    >({
      query: ({ selectedTargets, selectedBusinessUnitId }) => ({
        url: actApiGatewayClient.targets.GET_PLOT,
        method: 'POST',
        data: {
          selectedTargets,
          selectedBusinessUnitId,
        },
      }),
      providesTags: () => [TAG_TARGET_GRAPH],
    }),
  }),
})

export const {
  useGetTargetsQuery,
  useGetTargetQuery,
  useCreateTargetMutation,
  useEditTargetMutation,
  useDeleteTargetMutation,
  useGetTargetTemplatesQuery,
  useGetTargetGraphQuery,
  usePrefetch,
} = targetsApiSlice

export default targetsApiSlice
