/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiChevronDown } from 'react-icons/hi'

import { orderBy } from 'lodash'

import { ActivityDataSourceWithChildren } from '@cozero/models'

import Cascader from '@/atoms/Cascader'
import LoadingSpinner from '@/atoms/LoadingSpinner'

import { CINDER_BLUE_60 } from '@/styles/variables'

import classes from './ActivityDataSourceLazyLoadedCascader.module.less'

interface ActivityDataSourceLazyLoadedCascaderProps {
  activityDataSources: ActivityDataSourceWithChildren[] | undefined
  disabled?: boolean
  value?: number
  onChange?: (ads: number[]) => void
  placeholder?: string
  dropdownMatchSelectWidth?: boolean
  loading: boolean
}

interface CascaderOption {
  label: string
  value: number
  children: CascaderOption[]
  isLeaf: boolean
}

const ActivityDataSourceLazyLoadedCascader = ({
  activityDataSources,
  disabled,
  placeholder,
  dropdownMatchSelectWidth = true,
  loading,
  onChange,
}: ActivityDataSourceLazyLoadedCascaderProps): JSX.Element => {
  const { t } = useTranslation()
  const [adsStructurePath, setAdsStructurePath] = useState<number[]>([])

  const parseActivityDataSources = useCallback(
    (activityDataSources: ActivityDataSourceWithChildren[]): CascaderOption[] => {
      const parsedActivityDataSources = []
      for (const ads of activityDataSources) {
        parsedActivityDataSources.push({
          label: ads.name,
          value: ads.id,
          children: ads?.children?.length ? parseActivityDataSources(ads.children) : [],
          isLeaf: !ads?.children?.length,
        })
      }
      return orderBy(parsedActivityDataSources, ['label'], ['asc'])
    },
    [],
  )

  const adsOptions = useMemo(
    () => parseActivityDataSources(activityDataSources ?? []),
    [activityDataSources, parseActivityDataSources],
  )

  return (
    <Cascader
      value={adsStructurePath}
      disabled={disabled}
      options={adsOptions}
      placeholder={placeholder}
      changeOnSelect
      loading={loading ?? !activityDataSources}
      suffixIcon={<HiChevronDown size={15} color={CINDER_BLUE_60} />}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      onChange={(value) => {
        onChange && onChange(value as number[])
        setAdsStructurePath(value as number[])
      }}
      showSearch={true}
      dropdownRender={(menu) => {
        if (loading && !activityDataSources) {
          return (
            <div className={classes.loadingContainer}>
              <LoadingSpinner size="xs" color="grey" className={classes.spinnerino} />
            </div>
          )
        }

        return (
          <div>
            {loading && (
              <div className={classes.loadingContainer}>
                <span className={classes.flex1}>{t('cozero.activityDataSource.loading')}</span>
                <div style={{ paddingRight: 8 }}>
                  <LoadingSpinner size="2xs" color="grey" />
                </div>
              </div>
            )}
            {menu}
          </div>
        )
      }}
    />
  )
}

export default ActivityDataSourceLazyLoadedCascader
