import { useTranslation } from 'react-i18next'

import Tag from '@/atoms/Tag'

import PaywallPill from '../PaywallPill'

interface IntegrationStatusTagProps {
  active: boolean
  isPremium?: boolean
}

const IntegrationStatusTag = ({ active, isPremium }: IntegrationStatusTagProps): JSX.Element => {
  const { t } = useTranslation('common')

  if (isPremium) {
    return <PaywallPill />
  }

  return (
    <Tag showDot type={active ? 'success' : 'default'} shape="pill">
      {active
        ? t('settings.integrations.fields.active.title')
        : t('settings.integrations.fields.active.not')}
    </Tag>
  )
}

export default IntegrationStatusTag
