import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Popconfirm } from 'antd/es'
import { ButtonType } from 'antd/es/button'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import classes from './classes.module.less'

export const AdvancedOption = ({
  title,
  subtitle,
  question,
  popConfirmTitle,
  action,
  btnProperties,
}: {
  title: string
  subtitle: string | JSX.Element
  question?: string
  popConfirmTitle: string | JSX.Element
  action: () => void
  btnProperties: { category: AnalyticsCategories; style?: 'primary' | 'ghost'; icon?: JSX.Element }
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Card className={classes.dangerZone}>
      <Title size="xs" className={classes.dangerZoneTitle}>
        {title}
      </Title>
      <Text size="xl" className={classes.subtitle}>
        {subtitle}
      </Text>
      <Text size="xl" className={classes.subtitle}>
        {question}
      </Text>
      <Popconfirm
        title={popConfirmTitle}
        overlayClassName={classes.deletionPopover}
        onConfirm={action}
        okText={t('yes')}
        cancelText={t('no')}
      >
        <Button
          category={btnProperties.category}
          action="delete"
          type={btnProperties.style || 'primary'}
          color="danger"
          className={classes.dangerZoneButton}
          data-cy="delete-business-unit-btn"
          prefixIcon={btnProperties.icon}
        >
          {title}
        </Button>
      </Popconfirm>
    </Card>
  )
}
