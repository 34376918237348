export const TAG_LOG_ENTRY = 'LOG_ENTRY'
export const TAG_PAGINATED_LOG_ENTRIES = 'PAGINATED_LOG_ENTRIES'
export const TAG_SEARCH_LOG_ENTRIES = 'PAGINATED_LOG_ENTRIES_EMISSIONS'
export const TAG_LOG_ENTRY_OVERVIEW_ROWS_SUMS = 'LOG_ENTRY_OVERVIEW_ROWS_SUMS'

export default [
  TAG_LOG_ENTRY,
  TAG_PAGINATED_LOG_ENTRIES,
  TAG_SEARCH_LOG_ENTRIES,
  TAG_LOG_ENTRY_OVERVIEW_ROWS_SUMS,
]
