import { useCallback } from 'react'

import { useAppSelector } from '@/redux'
import { getFeaturesAllowed, getInternalFeatures } from '@/redux/auth'

export const usePricingFeature = (): {
  featuresAllowed: string[]
  isFeatureEnabled: (feature: string) => boolean
  isFeatureInternal: (feature: string) => boolean
} => {
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const internalFeatures = useAppSelector(getInternalFeatures)

  const isFeatureEnabled = useCallback(
    (feature: string) => featuresAllowed.includes(feature),
    [featuresAllowed],
  )

  const isFeatureInternal = useCallback(
    (feature: string) => internalFeatures?.includes(feature),
    [internalFeatures],
  )

  return {
    featuresAllowed,
    isFeatureEnabled,
    isFeatureInternal,
  }
}
