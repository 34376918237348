import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiPlus } from 'react-icons/hi'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useNavigate } from 'react-router'

import { Col, Row, Spin, message } from 'antd/es'

import { BusinessUnit, Location, Metadata, User } from '@cozero/models'
import { routes } from '@cozero/utils'

import LocationsTable from '@/molecules/LocationsTable'

import Button from '@/atoms/Button'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin, selectUser } from '@/redux/auth'
import { useEditBusinessUnitMutation, useGetBusinessUnitQuery } from '@/redux/businessUnits'
import { useCreateLocationMutation } from '@/redux/locations'

import classes from './classes.module.less'

const Locations = ({ id, disabled }: { id?: string; disabled: boolean }): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const { data: businessUnit, isLoading: businessUnitLoading } = useGetBusinessUnitQuery(
    { id: id ?? '' },
    { skip: !id },
  )

  const [updateBusinessUnit] = useEditBusinessUnitMutation()
  const [createLocation] = useCreateLocationMutation()

  const createTwinLocation = async (): Promise<void> => {
    if (!businessUnit) {
      return
    }
    const values: Partial<Location> = {
      name: businessUnit?.title,
      key: businessUnit?.key,
      responsible: { id: user?.id } as User,
      active: businessUnit?.active,
    }

    const newLocation = await createLocation({
      ...values,
      name: values.name ?? '',
      metadata: {
        tags: businessUnit.metadata?.tags,
        twins: [businessUnit.id],
        externalId: businessUnit.metadata?.externalId,
      },
      businessUnit: {
        id: businessUnit.id,
      } as BusinessUnit,
      ...(values.responsible?.id ? { responsible: values.responsible } : { responsible: null }),
    }).unwrap()

    if (newLocation) {
      const existingTwins = (businessUnit?.metadata as Metadata)?.twins || []
      const newMetadata = Object.assign({}, businessUnit.metadata)
      newMetadata.twins = [...existingTwins, newLocation.id]
      await updateBusinessUnit({
        id: `${newLocation.businessUnitId}`,
        data: {},
      }).unwrap()
    }

    message.success(t('settings.bu.success-create-location'))
  }

  return (
    <Spin spinning={businessUnitLoading}>
      <Row>
        <Col span={24}>
          <Row className={classes.header}>
            <Col>
              <Title size="xs" className={classes.tableTitle}>
                {t('settings.bu.locations')}
              </Title>
            </Col>
          </Row>
          <Row justify="end" className={classes.header}>
            <Col>
              <Button
                category={AnalyticsCategories.BUSINESS_UNIT}
                action="twin-location"
                type="secondary"
                onClick={createTwinLocation}
                disabled={disabled}
                className={classes.twinButton}
              >
                {t('settings.bu.twin-location')}
              </Button>
            </Col>
            <Col>
              <Button
                category={AnalyticsCategories.BUSINESS_UNIT}
                action="create-location"
                type="primary"
                prefixIcon={<HiPlus />}
                disabled={disabled}
                onClick={() =>
                  navigate(
                    `${routes.organizationSettings.locations.add}?businessUnitId=${businessUnit?.id}`,
                  )
                }
              >
                {t('settings.bu.create-location')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <LocationsTable
                loading={businessUnitLoading}
                locations={(businessUnit?.locations as Location[]) ?? []}
                businessUnitsAllowed={true}
                isManagerOrAdmin={isManagerOrAdmin}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  )
}

export default Locations
