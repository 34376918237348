import { CalculationData, Page } from '@cozero/models'
import { calculationsApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

const methodologyChangelogApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCalculations: builder.query<
      Page<CalculationData>,
      { organisationId: number; businessUnitId: number; limit?: number }
    >({
      query: ({ businessUnitId, organisationId, limit = -1 }) => ({
        url: calculationsApiGatewayClient.methodologyChangelog.GET_MANY_V2,
        method: 'GET',
        params: {
          businessUnitId,
          organisationId,
          limit,
        },
      }),
    }),
  }),
})

export const { useFetchCalculationsQuery } = methodologyChangelogApiSlice

export default methodologyChangelogApiSlice
