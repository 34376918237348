import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineInformationCircle } from 'react-icons/hi'

import { FormItemProps as AntFormItemProps, Form, Space, Tooltip } from 'antd/es'

import Icon from '@ant-design/icons'
import classNames from 'classnames'

import Tag from '@/atoms/Tag'

import { COZERO_BLUE_80 } from '../../styles/variables'
import Label from '../Label'
import Text from '../Text'

import classes from './FormItem.module.less'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormItemProps<T = any> = Omit<AntFormItemProps<T>, 'tooltip'> & {
  description?: string
  classNameWrapper?: string
  tooltip?: string
  optional?: boolean
  name?: string | string[]
}

/** Component to wrap around ant design form item, to be able to add a description under the input (without antd margin) and with the same label styling used in `InputField` component */
export const FormItem = ({
  classNameWrapper,
  tooltip,
  optional,
  ...props
}: FormItemProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className={classNames(classes.formItemWrapper, classNameWrapper)}>
      <Form.Item
        {...props}
        label={
          props.label && (
            <Space>
              <Label className={classes.label} color="cinderBlue">
                {props.label}
              </Label>
              {optional && (
                <Tag size="xs">
                  <Text fontWeight="medium">{t('optional')}</Text>
                </Tag>
              )}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <Icon component={() => <HiOutlineInformationCircle color={COZERO_BLUE_80} />} />
                </Tooltip>
              )}
            </Space>
          )
        }
      >
        {props.children}
      </Form.Item>
      {props?.description && (
        <Text color="secondary" className={classes.description}>
          {props?.description}
        </Text>
      )}
    </div>
  )
}
