import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Result } from 'antd/es'

import { routes } from '@cozero/utils'

import Button from '@/atoms/Button'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

function PaymentCancelled(): JSX.Element {
  const { t } = useTranslation('common')

  return (
    <Result
      status="error"
      title={t('subscription.cancel.title')}
      subTitle={t('subscription.cancel.subtitle')}
      extra={[
        <Link to={routes.accountBilling.subscription} key="1">
          <Button category={AnalyticsCategories.SUBSCRIPTIONS} action={'cancel'}>
            {t('subscription.cancel.button')}
          </Button>
        </Link>,
      ]}
    />
  )
}

export default PaymentCancelled
