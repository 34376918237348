import { HasPermissionsRequestDto, HasPermissionsResponseDto } from '@cozero/dtos'
import { permissionsServiceClient } from '@cozero/uris'

import apiSlice from '../api'

import { TAG_PERMISSIONS } from './tags'

const permissionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    hasPermissions: builder.query<HasPermissionsResponseDto, HasPermissionsRequestDto>({
      query: (params) => ({
        url: permissionsServiceClient.permissions.HAS,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: 60,
      providesTags: [TAG_PERMISSIONS],
    }),
  }),
})

export const { useHasPermissionsQuery } = permissionsApiSlice

export default permissionsApiSlice
