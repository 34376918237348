import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { Col, Row } from 'antd/es'

import { Product } from '@cozero/models'
import { routes } from '@cozero/utils'

import { useBackListener } from '@/pages/GenericLifecycleSteps/hooks/back-button-listener'
import {
  GenericFieldKey,
  LifecycleStepsKey,
  Step,
  useStep,
} from '@/pages/GenericLifecycleSteps/hooks/steps'
import {
  ProductConfigurationValues,
  SupplierProduct,
} from '@/pages/GenericLifecycleSteps/hooks/useProductOutletContext'

import CustomHeader from '@/organisms/CustomModalHeader'
import ProductSidebar from '@/organisms/ProductSidebar'

import LayoutFooter from '@/molecules/LayoutFooter'
import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'

import incompleteData from '@/assets/lifecycle-steps/incomplete-data.svg'
import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useFactorContext } from '@/contexts/factor'
import { useLifecycleContext } from '@/contexts/lifecycle'
import useLog from '@/hooks/useLog'
import useProducts from '@/hooks/useProducts'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useFindFactorRequestByIdQuery } from '@/redux/factors-requests'
import { useGetProductQuery } from '@/redux/products'
import { formatNumber } from '@/utils/number'

import classes from './classes.module.less'

const encodeB64 = (data: string[]): string => {
  try {
    return window.btoa(encodeURIComponent(JSON.stringify(data)))
  } catch (error) {
    return JSON.stringify([])
  }
}

const decodeB64 = (str: string): string[] => {
  try {
    return JSON.parse(decodeURIComponent(window.atob(str)))
  } catch (error) {
    return []
  }
}

const UpsertProductPage = (): JSX.Element => {
  const { id, factorRequestId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const productId = useRef(id)
  const childRef = useRef({
    onNext: () => undefined,
    onReturn: () => undefined,
    onClearData: () => undefined,
  })
  const navigate = useNavigate()

  const { t } = useTranslation('common')
  const [product, setProduct] = useState<Product>()
  const [supplierProduct, setSupplierProduct] = useState<SupplierProduct>()
  const [incompleteModalOpen, setIncompleteModalOpen] = useState<boolean>(false)
  const [action, setAction] = useState<'back' | 'next' | number | undefined>()
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)

  const { saveFactorRequest, factorRequest, setFactorRequest } = useFactorContext()

  const { data: factorRequestData, isSuccess: isFRFetchSuccess } = useFindFactorRequestByIdQuery(
    factorRequestId?.toString() ?? '',
    {
      skip: !!factorRequest || !factorRequestId,
    },
  )

  const { data: productData, isSuccess: isProductFetchSuccess } = useGetProductQuery(
    id?.toString() ?? '',
    {
      skip: !!product || !id,
    },
  )

  const isMountingComponentRef = useRef(true)
  const { stepList, genericSteps } = useStep()
  const location = useLocation()

  const { getProduct, createProductConfiguration, updateProduct } = useProducts()

  const {
    currentStep,
    selectedSteps,
    stepData,
    isDataValid,
    isNewProduct,
    setCurrentStep,
    setStepsToDisplay,
    setSelectedSteps,
    setIsDataValid,
    setTotalEmissionFactor,
    setStepData,
    setIsNewProduct,
    from,
  } = useLifecycleContext()

  const { getProductEmissions } = useLog()

  const listenerForBackButton = useCallback(() => {
    setCurrentStep(currentStep - 1)
    navigateToStep(currentStep - 1)
  }, [currentStep])

  useBackListener(listenerForBackButton)

  const handleLifecycleChange = (value: boolean, index: number, isActive: boolean): void => {
    if (value) {
      if (selectedSteps.find((el) => el.index === index)) {
        return
      }
      setSelectedSteps((prev) => {
        if (!prev.find((el) => el.index === index)) {
          prev.push({ index, isActive })
        }
        return [...prev]
      })
    } else {
      setSelectedSteps((prev) => {
        const indexToRemove = prev.findIndex((el) => el.index === index)
        if (indexToRemove > -1) {
          prev.splice(indexToRemove, 1)
        }
        return [...prev]
      })
    }
  }

  const STEPS: Step[] = useMemo(() => {
    const stepKeys = Object.values(LifecycleStepsKey) as LifecycleStepsKey[]
    const selectedSteps: LifecycleStepsKey[] = []
    const mandatorySupplierStepKeys = (factorRequest?.productLifecycleSteps ?? []).map(
      (step) => step.key as LifecycleStepsKey,
    )
    if (product) {
      selectedSteps.push(
        ...stepKeys.filter((step) => {
          return product?.selectedSteps?.includes(step)
        }),
      )
    }

    if (location && location.state) {
      selectedSteps.push(
        ...stepKeys.filter((step) => {
          return location.state?.selectedSteps?.includes(step)
        }),
      )
    }

    const _selectedStepsFromQuery = searchParams?.get('selectedSteps')

    if (_selectedStepsFromQuery) {
      const _selectedStepsFromQueryParsed = decodeB64(_selectedStepsFromQuery)
      selectedSteps.push(
        ...stepKeys.filter((step) => {
          return _selectedStepsFromQueryParsed?.includes(step) && !selectedSteps.includes(step)
        }),
      )
    }

    return stepList({
      productIdToEdit: product?.id,
      childRef,
      selectedSteps,
      handleLifecycleChange,
      hideOptionalSteps: !!factorRequest,
      mandatorySupplierStepKeys,
    })
  }, [product, searchParams, location, factorRequest])

  const onUpdateProduct = async (
    productId: string,
    data: Partial<ProductConfigurationValues>,
  ): Promise<void> => {
    const updatedProduct = await updateProduct(productId, {
      version: product?.version as string,
      code: product?.code as string,
      startDate: product?.startDate as Date,
      endDate: product?.endDate as Date,
      ...data,
    })
    setProduct(updatedProduct)
  }
  const onCreateProduct = async (
    data: ProductConfigurationValues,
  ): Promise<Product | undefined> => {
    const newProduct = await createProductConfiguration({
      ...data,
      businessUnitId: selectedBusinessUnit?.id,
    })
    setProduct(newProduct)
    setIsNewProduct(true)
    productId.current = newProduct?.id?.toString()

    if (factorRequest && newProduct && factorRequest.denominatorUnitId) {
      const updateFactorRequest = await saveFactorRequest(factorRequest.id, {
        productId: newProduct.id,
        denominatorUnitId: factorRequest.denominatorUnitId,
      })

      if (updateFactorRequest?.productId) {
        setFactorRequest((prev) => {
          if (prev) {
            return {
              ...prev,
              productId: updateFactorRequest.productId,
            }
          }
          return prev
        })
      }
    }

    return newProduct
  }

  const onHasFinished = (): void => {
    if (currentStep + 1 >= selectedSteps.length) {
      onClose(true)
      return
    }
    setCurrentStep(currentStep + 1)
    navigateToStep(currentStep + 1)
  }

  const handleAction = (action: 'next' | 'back' | number): void => {
    setAction(action)
    const areFieldsEmpty = stepData
      .filter((data) => !!data)
      .every((data) =>
        Object.entries(data)
          .filter(([key]) => Object.values(GenericFieldKey).includes(key as GenericFieldKey))
          .every(([, value]) => !value || (Array.isArray(value) && !value.length)),
      )
    if (
      genericSteps.includes(STEPS[currentStep]?.stepKey) &&
      !isDataValid &&
      !areFieldsEmpty &&
      STEPS[selectedSteps[currentStep]?.index]?.stepKey !== 'quantities'
    ) {
      setIncompleteModalOpen(true)
    } else {
      if (action === 'next') {
        onSubmit()
      } else if (action === 'back') {
        onGoBack()
      } else {
        childRef.current?.onClearData()
        navigateToStep(action)
        setCurrentStep(action)
      }
    }
  }

  const handleModalAction = (): void => {
    setIncompleteModalOpen(false)
    childRef.current?.onClearData()
    if (action === 'next') {
      childRef.current?.onNext()
    } else if (action === 'back') {
      onGoBack()
    } else if (typeof action === 'number') {
      setCurrentStep(action)
    }
  }

  const onGoBack = useCallback(
    (event?: MouseEvent): void => {
      if (currentStep < 0) {
        onClose()
        return
      }
      if (!event || (currentStep > -1 && event.currentTarget.nodeName === 'BUTTON')) {
        childRef.current?.onReturn()

        if (currentStep > -1) {
          navigateToStep(currentStep - 1)
          setCurrentStep(currentStep - 1)
        }
      } else {
        childRef.current?.onClearData()
        navigateToStep(-1)
        setCurrentStep(-1)
      }
    },
    [currentStep, childRef],
  )

  const onSubmit = useCallback(async (): Promise<void> => {
    if (currentStep === -1) {
      childRef.current?.onNext()

      setStepsToDisplay(
        selectedSteps.sort((x) => x.index).map((el) => ({ title: STEPS[el.index].title })),
      )
      setCurrentStep(currentStep + 1)
      navigateToStep(currentStep + 1)
    } else {
      childRef.current?.onNext()
      if (Number(productId.current)) {
        const emissionFactor = await getProductEmissions(Number(productId.current))
        if (Array.isArray(emissionFactor)) {
          setTotalEmissionFactor(Number(formatNumber(emissionFactor[0].carbonFootprint)))
        }
      }
    }
  }, [childRef, selectedSteps, currentStep, STEPS, isDataValid])

  const navigateToStep = (step: number): void => {
    setStepData([])
    const stepPath = selectedSteps[step] ? STEPS[selectedSteps[step].index]?.path : undefined
    // The following code is to build the url depending on if it's for Editing or Creating a product
    let path = ''
    if (step === -1) {
      if (productId.current) {
        path = routes.share.supplierEngagement.stepsEdit
          .replace(':factorRequestId', `${factorRequest?.id}`)
          .replace(':id', productId.current)
      } else {
        path = routes.share.supplierEngagement.stepsCreate.replace(
          ':factorRequestId',
          `${factorRequest?.id}`,
        )
      }
      navigate(`${path}${window.location.search}`, { replace: true })
    }
    if (stepPath) {
      // The following code is to build the url depending on if it's for Editing or Creating a product
      let path: string = routes.share.supplierEngagement.base
      if (productId.current) {
        path = `${path}/${factorRequest?.id}/${productId.current}/edit/${stepPath}`
      } else {
        path = `${path}/${factorRequest?.id}/create/${stepPath}`
      }
      navigate(`${path}${window.location.search}`, { replace: true })
    }
  }

  useEffect(() => {
    if (isFRFetchSuccess) {
      setFactorRequest(factorRequestData)
    }
  }, [isFRFetchSuccess])

  useEffect(() => {
    if (isProductFetchSuccess) {
      setProduct(productData)
    }
  }, [isProductFetchSuccess])

  useEffect(() => {
    if (!isMountingComponentRef.current) {
      navigateToStep(currentStep)
    }
  }, [currentStep])

  useEffect(() => {
    isMountingComponentRef.current = false
  }, [])

  const resetSteps = (): void => {
    childRef.current?.onClearData()
    setCurrentStep(-1)
  }

  const onClose = (isProductCompleted?: boolean): void => {
    setCurrentStep(-1)
    if (from) {
      if (!isProductCompleted) {
        navigate(from)
      } else {
        const path = routes.log.factors.requestsPage.base
        navigate(`${path}/${factorRequest?.id}`, {
          state: { showCompleteModal: true, step: 'STEP_2' },
        })
      }
    } else {
      const path = routes.log.factors.requestsPage.base
      navigate(
        !isNewProduct && productId.current
          ? `${path}/${factorRequest?.id}`
          : routes.log.factors.requestsPage.details.replace(':id', `${factorRequest?.id}`),
        {
          replace: true,
          state: {
            prevPath: location.pathname,
            showCompletedModal: isNewProduct && isProductCompleted,
            from,
            step: 'STEP_2',
          },
        },
      )
    }
  }

  useEffect(() => {
    const fetchProduct = async (): Promise<void> => {
      if (productId.current) {
        const data = await getProduct(Number(productId.current))
        const _selectedSteps = data?.selectedSteps.map((selectedKey) => ({
          index: STEPS.findIndex((x) => x.stepKey === selectedKey),
          isActive: true,
        }))
        if (_selectedSteps?.length) {
          const aux = _selectedSteps
            .sort((a, b) => (a.index > b.index ? 1 : -1))
            .map((el) => ({ title: STEPS[el.index].title }))
          setStepsToDisplay(aux)
          setSelectedSteps(_selectedSteps)
        }
        setProduct(data)
      }
    }
    setProduct(undefined)
    fetchProduct()
  }, [productId.current])

  useEffect(() => {
    if (currentStep > selectedSteps.length) {
      resetSteps()
    }
  }, [currentStep])

  useEffect(() => {
    const step = STEPS.findIndex((x) => x.path && window.location.pathname.includes(x.path))
    const currentStep = selectedSteps.findIndex((x) => x.index === step)
    let _selectedSteps = [...selectedSteps]

    if (!_selectedSteps.length && step === 0) {
      _selectedSteps = [{ index: 0, isActive: true }]
      const aux = _selectedSteps
        .sort((a, b) => (a.index > b.index ? 1 : -1))
        .map((el) => ({ title: STEPS[el.index].title }))
      setStepsToDisplay(aux)
      setSelectedSteps(_selectedSteps)
    }

    setCurrentStep(step === 0 ? step : currentStep)
  }, [product])

  useEffect(() => {
    const _selectedStepsFromQuery = searchParams.get('selectedSteps')

    if (_selectedStepsFromQuery) {
      const _selectedStepsFromQueryParsed = decodeB64(_selectedStepsFromQuery)
      const _selectedSteps = _selectedStepsFromQueryParsed.map((selectedKey: string) => ({
        index: STEPS.findIndex((x) => x.stepKey === selectedKey),
        isActive: true,
      }))
      if (_selectedSteps) {
        const aux = _selectedSteps
          .sort((a, b) => (a.index > b.index ? 1 : -1))
          .map((el: { index: number; isActive: boolean }) => ({ title: STEPS[el.index].title }))

        setStepsToDisplay(aux)
        setSelectedSteps(_selectedSteps)
      }
    }
  }, [searchParams])

  useEffect(() => {
    if (selectedSteps?.length && selectedSteps.length > 1) {
      setSearchParams({
        selectedSteps: encodeB64(selectedSteps.map((x) => STEPS[x.index].stepKey)),
      })
    }
  }, [selectedSteps])
  useEffect(() => {
    const checkInputs = (
      inputs: {
        inputKey: string
        value: number
        unitId: number
      }[],
    ): boolean => inputs.every((el) => el.value !== undefined && el.value !== null)
    if (stepData) {
      const nonEmptyProductEntries = stepData
        .filter(Boolean)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((acc: Array<[string, any]>, curr) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fields: Array<[string, any]> = Object.entries(curr)
          const relevantFields = fields.filter(
            ([key]) => key !== 'supplierId' && key !== 'productlifecycleStep',
          )
          const isProductEmpty = relevantFields.every(([, value]) =>
            Array.isArray(value) ? value.every((e) => !e) : !value,
          )
          return isProductEmpty ? [...acc] : [...acc, ...relevantFields]
        }, [])
      const isValid = nonEmptyProductEntries.every(([key, value]) => {
        if (key === 'inputs') {
          return checkInputs(value)
        }
        return Array.isArray(value) ? value.every((el) => !!el) : !!value
      })
      setIsDataValid(!stepData.length || isValid)
    }
  }, [stepData])

  useEffect(() => {
    if (location.state) {
      setSupplierProduct({
        denominatorUnitId: location.state.denominatorUnitId,
        productName: location.state.productName,
      })
    }
  }, [location])

  return (
    <>
      <div className={classes.container}>
        <ProductSidebar handleAction={handleAction} product={product} />
        <div className={classes.mainSection}>
          <CustomHeader
            showArrow={currentStep !== -1}
            title={!productId.current ? t('product.new-product') : t('products.edit-product')}
            onClose={() => onClose()}
            goBack={() => {
              onGoBack()
            }}
          />
          <div className={classes.content}>
            {(product || window.location.pathname.includes('create') || supplierProduct) && (
              <Outlet
                context={{
                  product: product,
                  supplierProduct,
                  childRef,
                  factorRequest,
                  STEPS,
                  updateProduct: onUpdateProduct,
                  createProductConfiguration: onCreateProduct,
                  onHasFinished,
                }}
              />
            )}
          </div>
          <LayoutFooter
            hideCancelButton={currentStep === -1}
            categoryOkButton={AnalyticsCategories.PRODUCTS}
            categoryCancelButton={AnalyticsCategories.PRODUCTS}
            isDataValid={
              genericSteps.includes(STEPS[selectedSteps[currentStep]?.index]?.stepKey)
                ? isDataValid
                : true
            }
            onCancelClick={() => {
              handleAction('back')
            }}
            onSaveClick={() => {
              handleAction('next')
            }}
            saveButtonText={t('actions.save-continue')}
            cancelButtonText={t('actions.back')}
            saveButtonDisabled={false}
          />
        </div>
      </div>
      <Modal
        className={classes.incompleteDataModal}
        visible={incompleteModalOpen}
        onCancel={() => setIncompleteModalOpen(false)}
        hideFooter
        customHeader={<></>}
      >
        <Row>
          <Col span={24} className={classes.content}>
            <img src={incompleteData} className={classes.icon} alt="logo" />
          </Col>
          <Col span={24} className={`${classes.content} ${classes.title}`}>
            {t('product.lifecycle-steps.discard-modal.title')}
          </Col>
          <Col span={24} className={`${classes.content} ${classes.subtitle}`}>
            {t('product.lifecycle-steps.discard-modal.subtitle')}
          </Col>
          <Col span={24} className={classes.content}>
            <Row align="middle">
              <Col span={12}>
                <Button
                  category={AnalyticsCategories.PRODUCTS}
                  action={'ok'}
                  type="secondary"
                  data-cy="ok-modal-button"
                  color={'blue'}
                  className={classes.button}
                  onClick={() => setIncompleteModalOpen(false)}
                >
                  {t('product.lifecycle-steps.discard-modal.back-btn')}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  category={AnalyticsCategories.PRODUCTS}
                  action={'ok'}
                  type="primary"
                  data-cy="ok-modal-button"
                  color={'blue'}
                  className={classes.button}
                  onClick={handleModalAction}
                >
                  {t('product.lifecycle-steps.discard-modal.discard-btn')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default UpsertProductPage
