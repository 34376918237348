import { useCallback, useState } from 'react'

export const useCenteredTree = (): {
  translate: { x: number; y: number }
  containerRef: (containerElem: HTMLDivElement) => void
} => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 })

  const containerRef = useCallback((containerElem: HTMLDivElement) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect()
      setTranslate({ x: width / 2, y: height / 2 })
    }
  }, [])
  return { translate, containerRef }
}
