import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaChevronDown } from 'react-icons/fa'
import {
  HiExternalLink,
  HiOutlineArrowSmRight,
  HiOutlineRefresh,
  HiOutlineTrendingDown,
  HiOutlineTrendingUp,
} from 'react-icons/hi'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import { Col, Collapse, Row } from 'antd'
import { List } from 'antd/es'

import _ from 'lodash'
import moment from 'moment'

import { CalculationData, ChangelogStatus, Page } from '@cozero/models'

import { ListCard } from '@/atoms/ListCard/ListCard'
import Tag, { TagType } from '@/atoms/Tag'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { useFormatNumber } from '@/hooks/i18nFormatting/useFormatNumber'

import classes from './CalculationChanges.module.less'

const SectionHeader = ({
  icon,
  notificationCount,
  translationPrefix,
}: {
  icon: ReactElement
  translationPrefix: string
  notificationCount?: number | null
}): JSX.Element => {
  const { t } = useTranslation('common')
  const notes = t(`${translationPrefix}.notes`)

  return (
    <Row align="middle" gutter={[8, 4]} wrap className={classes.headerRow}>
      <Col flex="none">
        <Row align="middle" gutter={8}>
          <Col className={classes.verticalCenter}>{icon}</Col>
          <Col>
            <Title size="sm" as="h3" className={classes.statusTitle}>
              {t(`${translationPrefix}.title`)}
            </Title>
          </Col>
          {notificationCount && (
            <Col>
              <Tag shape="pill" type="info">
                {notificationCount}
              </Tag>
            </Col>
          )}
        </Row>
      </Col>
      {notes && (
        <Col flex="auto" md={24}>
          <Text size="xl" className={classes.subtitle} fontWeight="medium" color="secondary">
            {notes}
          </Text>
        </Col>
      )}
    </Row>
  )
}

const formatDate = (date: Date): string => {
  return moment(date).format('D MMMM YYYY')
}

const DataDescription = ({
  label,
  children,
}: {
  label: string
  children: ReactNode
}): ReactElement => (
  <Col className={classes.dataDescriptionCol}>
    <Row className={classes.dataDescriptionLabel}>{label}</Row>
    <Row className={classes.dataDescriptionValue}>{children}</Row>
  </Col>
)

const SmallTag = ({
  type,
  className = '',
  children,
}: {
  type: TagType
  className?: string
  children: ReactNode
}): ReactElement => (
  <Tag
    disableMargins
    shape="pill"
    type={type}
    hideBackground={type === 'success'}
    className={`${classes.smallTag} ${className}`}
  >
    {children}
  </Tag>
)

const getTagProps = (changePercent: number): { type: TagType; icon: ReactElement } => {
  if (changePercent <= 0.02) {
    return { type: 'success', icon: <HiOutlineTrendingDown size={15} /> }
  } else if (changePercent >= 0.05) {
    return { type: 'danger', icon: <HiOutlineTrendingUp size={15} /> }
  } else {
    return {
      type: 'warning',
      icon: <HiOutlineArrowSmRight size={18} className={classes.warningArrow} />,
    }
  }
}

const EmissionDeltaTag = ({ changePercent }: { changePercent: number }): ReactElement => {
  const format = useFormatNumber()

  const { type, icon } = getTagProps(changePercent)
  const polarityIcon = changePercent > 0 ? '+' : ''
  const amount = format(changePercent, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'percent',
  })
  return (
    <SmallTag type={type} className={classes.iconTag}>
      {icon}
      {polarityIcon}
      {amount}
    </SmallTag>
  )
}

const EmissionDeltaList = ({
  calculationData,
}: {
  calculationData: CalculationData
}): ReactElement => {
  const { t } = useTranslation()
  const format = useFormatNumber({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return (
    <>
      <Row align="middle" gutter={[16, 18]}>
        <Col xl={8} lg={24} sm={24} className={classes.customColBreakpoint}>
          <Row gutter={[32, 8]} align="middle" className={classes.dateRow}>
            <DataDescription label={t(`calculation-changes-v2.card.notified-on`)}>
              {formatDate(calculationData.notifiedOn)}
            </DataDescription>
            <DataDescription label={t(`calculation-changes-v2.card.effective-on`)}>
              <SmallTag type="info">{formatDate(calculationData.effectiveOn)}</SmallTag>
            </DataDescription>
            <div className={classes.divider}></div>
          </Row>
        </Col>

        <Col xl={16} lg={24} sm={24} className={classes.customColBreakpoint}>
          <Row gutter={[16, 8]} justify={{ lg: 'start' }} wrap>
            {calculationData.emissionDeltas.map((item) => {
              const beforeAmount = format(item.originalValue)
              const afterAmount = format(item.updatedValue)
              return (
                <Col xl={8} key={`${calculationData.id}-${item.affectedYear.toString()}`}>
                  <Row>
                    <DataDescription
                      label={t('calculation-changes-v2.card.impact', {
                        year: moment(item.affectedYear).format('YYYY'),
                      })}
                    >
                      <del>{beforeAmount}</del>
                      <HiOutlineArrowSmRight size="16" className={classes.arrow} /> {afterAmount}{' '}
                      {t('calculation-changes-v2.card.co2e')}
                      <EmissionDeltaTag changePercent={item.changePercent} />
                    </DataDescription>
                  </Row>
                </Col>
              )
            })}
          </Row>
        </Col>
      </Row>
    </>
  )
}

const RefreshCalculationButton = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <Col className={`${classes.iconTag} ${classes.verticalCenter}`}>
      <HiOutlineRefresh size="16" />
      <span className={classes.cinderBlue80}>{t(`calculation-changes-v2.card.refresh`)}</span>
    </Col>
  )
}

const ViewDetailsCta = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <Col className={`${classes.iconTag} ${classes.blue800} ${classes.verticalCenter}`}>
      <HiExternalLink size="18" />
      <span>{t(`calculation-changes-v2.card.view-details`)}</span>
    </Col>
  )
}

const RunningCalculationButton = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <SmallTag type="info" className={`${classes.iconTag} ${classes.runningCalculationButton}`}>
      <HiOutlineRefresh size="14" className={classes.spin} />
      {t(`calculation-changes-v2.card.running-recalculation`)}
    </SmallTag>
  )
}

const CalculationPreviewControls = ({
  calculationData,
}: {
  calculationData: CalculationData
}): ReactElement => {
  const { t } = useTranslation()

  return (
    <Row className={classes.previewControlRow}>
      <Row className={classes.columnGap}>
        <Col
          xs={{ order: 2 }}
          sm={{ order: 2 }}
          md={{ order: 2 }}
          lg={{ order: 2 }}
          xl={{ order: 1 }}
        >
          <span className={`${classes.cinderBlue60} ${classes.fontSmall}`}>
            {t(`calculation-changes-v2.card.last-updated`)}{' '}
            <span className={classes.cinderBlue80}>
              {moment(calculationData.previewCalculationOn).format('D MMMM [at] hh:mm A')}
            </span>
          </span>
        </Col>
        <Col
          xs={{ order: 1 }}
          sm={{ order: 1 }}
          md={{ order: 1 }}
          lg={{ order: 1 }}
          xl={{ order: 2 }}
        >
          <RefreshCalculationButton />
        </Col>
      </Row>
      <Col>
        <ViewDetailsCta />
      </Col>
    </Row>
  )
}

export enum CalculationListType {
  TO_REVIEW,
  REJECTED,
  COMPLETED,
}

const ShowMoreCards = ({
  translationPrefix,
  children,
}: {
  translationPrefix: string
  children: React.ReactNode
}): ReactElement => {
  const { t } = useTranslation()

  const [internalExpanded, setInternalExpanded] = useState(false)
  const activeKey = internalExpanded ? 'panel' : undefined

  const seeLessText = t(`${translationPrefix}.expander.see-less`)
  const seeMoreText = t(`${translationPrefix}.expander.see-more`)
  const label = internalExpanded ? seeLessText : seeMoreText

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Collapse
        className={classes.collapsable}
        activeKey={activeKey}
        ghost={true}
        onChange={() => setInternalExpanded((current) => !current)}
        expandIcon={({ isActive }) => (
          <FaChevronDown
            style={{
              transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease',
            }}
          />
        )}
      >
        <Collapse.Panel
          className={classes.seeMoreHeader}
          header={<span className={classes.cinderBlue60}>{label}</span>}
          key="panel"
        >
          {children}
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

const CardList = ({
  changelogs,
  readonly,
}: {
  changelogs: CalculationData[]
  readonly?: boolean
}): ReactElement => {
  const { t } = useTranslation('common')

  return (
    <List
      dataSource={changelogs}
      renderItem={(item) => {
        const calculationInProgress = item.status === ChangelogStatus.CALCULATING
        return (
          <div className={classes.listItem}>
            <ListCard>
              <Row className={classes.cardHeader}>
                <Col>
                  <Row
                    className={`${classes.verticalCenter} ${classes.calculationStatusContainer}`}
                    gutter={16}
                  >
                    <Col className={classes.cardTitle}>
                      {t(`calculation-changes.changes.${item.changeReason}`)}
                    </Col>
                    {calculationInProgress && (
                      <Col>
                        <RunningCalculationButton />
                      </Col>
                    )}
                  </Row>
                </Col>
                {!readonly && (
                  <Col className={classes.previewControls}>
                    {!calculationInProgress && (
                      <CalculationPreviewControls calculationData={item} />
                    )}
                  </Col>
                )}
              </Row>

              <ListCard.Content>
                <EmissionDeltaList calculationData={item} />
              </ListCard.Content>

              <ListCard.Collapsable>
                <p>big table</p>
              </ListCard.Collapsable>
            </ListCard>
          </div>
        )
      }}
    />
  )
}

const CalculationCardList = ({
  changelogs,
  status,
  limit,
  readonly,
  translationPrefix,
  icon,
  emptyState,
}: {
  changelogs: Page<CalculationData> | undefined
  status: ChangelogStatus | ChangelogStatus[]
  limit?: number
  readonly?: boolean
  translationPrefix: string
  icon: ReactElement
  emptyState?: ReactElement
}): ReactElement => {
  const filteredChangelogs = _.filter(changelogs?.data, (item) => status.includes(item.status))
  const showDefaultEmptyState = filteredChangelogs.length <= 0 && !emptyState
  if (showDefaultEmptyState) {
    return <></>
  }

  const showCustomEmptyState = emptyState && filteredChangelogs.length <= 0

  const primaryChangelogs = limit ? _.slice(filteredChangelogs, 0, limit) : filteredChangelogs
  const secondayChangelogs = _.slice(filteredChangelogs, limit)

  const numberOfChangelogs = filteredChangelogs.length
  const showSeeMoreButton = numberOfChangelogs && limit && numberOfChangelogs > limit

  return (
    <Row className={classes.calculatiionCardList}>
      <Col span={24}>
        <SectionHeader
          icon={icon}
          translationPrefix={translationPrefix}
          notificationCount={!readonly ? numberOfChangelogs : null}
        />
        {showCustomEmptyState ? (
          emptyState
        ) : (
          <>
            <CardList readonly={readonly} changelogs={primaryChangelogs} />
            {showSeeMoreButton && (
              <ShowMoreCards translationPrefix={translationPrefix}>
                <div className={classes.extraCards}>
                  <CardList readonly={readonly} changelogs={secondayChangelogs} />
                </div>
              </ShowMoreCards>
            )}
          </>
        )}
      </Col>
    </Row>
  )
}

export default CalculationCardList
