import React, { ReactNode } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import PageNotFound from '@/pages/404'
import Integrations from '@/pages/Settings/Integrations/Integrations'
import ConfigureIntegration from '@/pages/Settings/Organization/ConfigureIntegration'
import PaymentCancelled from '@/pages/Settings/Organization/Payment/Cancel'
import PaymentSuccess from '@/pages/Settings/Organization/Payment/Success'
import UserProfile from '@/pages/Settings/UserProfile'

import AppProvider from '@/contexts/app'
import LogProvider from '@/contexts/log'

import AdminSettingsRoute from '../AdminSettingsRoute'
import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'

export const AdminSettingsWrapper = ({
  feature,
  children,
  level,
}: {
  feature?: string
  children: ReactNode
  level?: 'admin' | 'manager'
}): ReactElement => (
  <AppProvider>
    <LogProvider>
      <AdminSettingsRoute level={level}>
        <PrivateRoute>
          <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
        </PrivateRoute>
      </AdminSettingsRoute>
    </LogProvider>
  </AppProvider>
)

const SettingsWrapper = ({
  feature,
  children,
}: {
  feature?: string
  children: ReactNode
}): ReactElement => (
  <LogProvider>
    <PrivateRoute>
      <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
    </PrivateRoute>
  </LogProvider>
)

const settingsRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.settings.base,
    children: [
      {
        path: routes.settings.profile,
        breadcrumb: t('layout.profile'),
        element: (
          <SettingsWrapper>
            <UserProfile />
          </SettingsWrapper>
        ),
      },
      {
        path: routes.settings.integrations,
        breadcrumb: t('layout.integrations'),
        element: (
          <AdminSettingsWrapper level="admin">
            <Integrations />
          </AdminSettingsWrapper>
        ),
      },
      {
        path: routes.settings.installIntegration,
        element: (
          <AdminSettingsWrapper level="admin">
            <ConfigureIntegration />
          </AdminSettingsWrapper>
        ),
      },
      {
        path: `${routes.settings.base}/*`,
        element: (
          <SettingsWrapper>
            <PageNotFound />
          </SettingsWrapper>
        ),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
  {
    path: routes.checkout.paymentCancel,
    element: (
      <AdminSettingsWrapper level="admin">
        <PaymentCancelled />
      </AdminSettingsWrapper>
    ),
  },
  {
    path: routes.checkout.paymentSuccess,
    element: (
      <AdminSettingsWrapper level="admin">
        <PaymentSuccess />
      </AdminSettingsWrapper>
    ),
  },
]

export default settingsRoutes
