import React, { useEffect, useMemo, useState } from 'react'

import { Checkbox, Col, Image, Row } from 'antd/es'

import classnames from 'classnames'

import Card from '@/atoms/Card'

import classes from './LifecycleStepCard.module.less'

export interface LifecycleStepCardProps {
  imageSrc: string
  title: string
  description: string
  index: number
  selectedLifecycle: (value: boolean, index: number, isActive: boolean) => void
  initialValue?: boolean
  isDisabled?: boolean
  component?: JSX.Element
}

const LifecycleStepCard = ({
  imageSrc,
  title,
  description,
  index,
  isDisabled,
  selectedLifecycle,
  initialValue,
  component,
}: LifecycleStepCardProps): JSX.Element => {
  const [checkBoxValue, setCheckBoxValue] = useState<boolean>(!!initialValue)

  const stepClassNames = classnames(classes.step, isDisabled && classes.disabled, {
    [classes.selected]: checkBoxValue,
  })

  const onStepClick = (): void => {
    if (!isDisabled) {
      setCheckBoxValue((prev) => !prev ?? true)
    }
  }

  useEffect(() => {
    if (checkBoxValue !== undefined) {
      selectedLifecycle(!!checkBoxValue, index, !!component)
    }
  }, [checkBoxValue])

  useEffect(() => {
    setCheckBoxValue(!!initialValue)
  }, [initialValue])

  return (
    <Card shadow="none" className={stepClassNames} onClick={onStepClick}>
      <Checkbox checked={checkBoxValue} disabled={isDisabled} className={classes.checkBox} />
      <Row className={classes.contentWrapper}>
        <Col>
          <Row justify="center">
            <Image
              preview={false}
              className={`${classes.image} ${classes.textAlign}`}
              src={imageSrc}
            />
          </Row>
          <Row>
            <Col className={`${classes.detailsContainer} ${classes.textAlign}`}>
              <Col className={classes.title}>{title}</Col>
              <Col className={classes.description}>{description}</Col>
            </Col>
            <div className={`${classes.detailsContainer} ${classes.textAlign}`}></div>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default LifecycleStepCard
