import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { routes } from '@cozero/utils'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useLazyGetStrategyBuilderStateQuery } from '@/redux/strategyBuilder'

import { MenuItem, ModuleItem, PageItem } from './NavSidebar'
import { useMenuFeature } from './NavSidebar.useMenuFeature'

const getActDisabledTooltip = (item: MenuItem): string | undefined => {
  if (item.key === 'targets') {
    return 'act.targets.onboarding.targetsDisabledTooltip'
  }

  if (item.key === 'analytics') {
    return 'act.targets.onboarding.analyticsDisabledTooltip'
  }
}

export const useMenuItemChildren = (): {
  getMappedActItemChildren: (menuItems: PageItem) => ModuleItem[] | undefined
} => {
  const { t } = useTranslation()
  const { canUseMenuFeature } = useMenuFeature()
  const canUseAct = canUseMenuFeature({ feature: 'act' })

  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const hasSelectedBusinessUnit = typeof selectedBusinessUnit?.id === 'number'

  const [fetchStrategyBuilderState, { data: strategyBuilderState }] =
    useLazyGetStrategyBuilderStateQuery()

  useEffect(() => {
    if (hasSelectedBusinessUnit && canUseAct) {
      fetchStrategyBuilderState({ businessUnitId: selectedBusinessUnit.id })
    }
  }, [canUseAct, fetchStrategyBuilderState, hasSelectedBusinessUnit, selectedBusinessUnit?.id])

  const getMappedActItemChildren = useCallback(
    (item: PageItem): ModuleItem[] | undefined => {
      if (!item.children) {
        return undefined
      }

      if (strategyBuilderState?.forecast) {
        return item.children
      }

      return item.children.map((item) => {
        if ([routes.act.targets, routes.act.analytics].includes(item.route)) {
          const disabledTooltipKey = getActDisabledTooltip(item)

          return {
            ...item,
            isDisabled: true,
            disabledTooltip: disabledTooltipKey && t(disabledTooltipKey),
          }
        }

        return item
      })
    },
    [strategyBuilderState, t],
  )

  return { getMappedActItemChildren }
}
