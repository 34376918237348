import React, { ReactNode, Suspense, lazy } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import ClimateActionsCrumb from '@/molecules/Breadcrumbs/ClimateActionsCrumb'

import LogProvider from '@/contexts/log'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

const PageNotFound = lazy(() => import('@/pages/404'))
const Targets = lazy(() => import('@/pages/Act/Targets/TargetPage'))
const StrategyBuilder = lazy(() => import('@/pages/Act/StrategyBuilder/StrategyBuilder'))
const ClimateAction = lazy(() => import('@/pages/Act/ClimateAction'))
const Analytics = lazy(() => import('@/pages/Act/Analytics/Analytics'))

const Wrappers = ({ children }: { children: ReactNode }): ReactElement => (
  <LogProvider>
    <PrivateRoute>
      <FeatureRoute requiredFeature="act">
        <Suspense fallback={<SuspenseSpinner />}>{children}</Suspense>
      </FeatureRoute>
    </PrivateRoute>
  </LogProvider>
)

const getActRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.act.base,
    children: [
      {
        path: routes.act.base,
        element: (
          <Wrappers>
            <StrategyBuilder />
          </Wrappers>
        ),
      },
      {
        path: routes.act.climateActions,
        element: (
          <Wrappers>
            <StrategyBuilder />
          </Wrappers>
        ),
        breadcrumb: t('act.climateActions.title'),
      },
      {
        path: routes.act.analytics,
        element: (
          <Wrappers>
            <Analytics />
          </Wrappers>
        ),
      },
      {
        path: routes.act.viewClimateAction,
        element: (
          <Wrappers>
            <ClimateAction />
          </Wrappers>
        ),
        breadcrumb: ClimateActionsCrumb,
      },
      {
        path: routes.act.targets,
        element: (
          <Wrappers>
            <Targets />
          </Wrappers>
        ),
        breadcrumb: t('act.targets.nav-title'),
      },
      {
        path: `${routes.act.base}/*`,
        element: (
          <Wrappers>
            <PageNotFound />
          </Wrappers>
        ),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
]

export default getActRoutes
