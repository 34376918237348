import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'

import { routes } from '@cozero/utils'

import NavSubMenu from '@/molecules/NavSubMenu'

import { usePermissions } from '@/hooks/usePermissions'

import classes from './OrganizationMenu.module.less'

export const OrganizationMenu = (): React.ReactElement => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState(true)
  const { hasPermissions: hasAccountBillingAccess } = usePermissions(['ACCOUNT_BILLING_ACCESS'])

  return (
    <div className={classes.wrapper}>
      <NavSubMenu
        base="organization"
        title={t('layout.organization')}
        icon={<HiOutlineOfficeBuilding />}
        menuOpen={isOpen}
        onOpen={(base) => setIsOpen(!!base)}
        items={[
          {
            key: 'organization-settings',
            title: t('layout.organizationSettings'),
            feature: 'organization',
            route: routes.organizationSettings.base,
          },
          ...(hasAccountBillingAccess
            ? [
                {
                  key: 'account-billing',
                  title: t('layout.accountBilling'),
                  feature: 'organization',
                  route: routes.accountBilling.base,
                },
              ]
            : []),
        ]}
      />
    </div>
  )
}
