import { DateRangeFilter, PageFilter } from '@cozero/models'

export const getDatesFromPageFilters = (filters: PageFilter[]): undefined | [Date, Date] => {
  if (!filters) {
    return undefined
  }

  const currentFilter = filters.filter((filter) => filter.type === 'dateRange')[0]

  if (!currentFilter) {
    return undefined
  }

  const start = (currentFilter as DateRangeFilter).filters.find(
    (filter) => filter.key === 'startDate',
  )?.value

  const end = (currentFilter as DateRangeFilter).filters.find(
    (filter) => filter.key === 'endDate',
  )?.value

  if (!start || !end) {
    return undefined
  }

  return [start, end]
}
