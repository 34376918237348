import React from 'react'
import { useTranslation } from 'react-i18next'
import { Location, useNavigate } from 'react-router-dom'

import { message } from 'antd'

import _ from 'lodash'

import { FactorRequestStatus, Product, ProductEmission } from '@cozero/models'
import { routes } from '@cozero/utils'

import { LifecycleStepsKey } from '@/pages/GenericLifecycleSteps/hooks/steps'
import { ISignedFilesProps } from '@/pages/Share/SupplierEngagement/Cbam/components/FileUploader/FileUploader'

import { useSubscriptionContext } from '@/contexts/subscription'
import useAppContext from '@/hooks/useApp'
import useLog from '@/hooks/useLog'
import useProducts from '@/hooks/useProducts'
import { useUpdateOneFactorRequestMutation } from '@/redux/factors-requests'
import { useCreateGhgFactorRequestResponseMutation } from '@/redux/factors-requests-responses'
import { useGetProductCountQuery } from '@/redux/products'

import useSupplierOnboarding, {
  CommonSupplierOnboardingProps,
  IProductLifecycleEmission,
} from './useSupplierOnboarding'

const calculateEmissionFactor = (emissions: number, mass: number): number => {
  const TONNES_TO_KG = 1000
  return (emissions / mass) * TONNES_TO_KG
}

export type UseGhgOnboardingArgs = Pick<
  ReturnType<typeof useSupplierOnboarding>,
  'factorRequest' | 'comment' | 'fileList' | 'organization'
> & {
  saveFactorRequest: ReturnType<typeof useUpdateOneFactorRequestMutation>[0]
  uploadAttachments: () => Promise<ISignedFilesProps[]>
}

export interface UseGhgOnboardingReturn extends CommonSupplierOnboardingProps {
  shareProductLifecycleSteps: boolean
  totalProductEmissions: number | undefined
  mass: number | null | undefined
  selectedProduct: Product | undefined
  productLifecycleEmissions: IProductLifecycleEmission[] | undefined
  setShareProductLifecycleSteps: (value: boolean) => void
}

export const useGhgOnboarding = ({
  location,
  factorRequest,
  comment,
  organization,
  uploadAttachments,
  saveFactorRequest,
}: UseGhgOnboardingArgs & { location: Location }): UseGhgOnboardingReturn => {
  const { getProductEmissions } = useLog()
  const { getProductLogEntry, getProduct } = useProducts()
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const { getLifecycleSteps } = useAppContext()
  const { data: productsCount } = useGetProductCountQuery()

  const navigate = useNavigate()
  const { t } = useTranslation()

  const [createFactorRequestResponse] = useCreateGhgFactorRequestResponseMutation()

  const [productLifecycleEmissions, setProductLifecycleEmissions] = React.useState<
    IProductLifecycleEmission[] | undefined
  >()
  const [shareProductLifecycleSteps, setShareProductLifecycleSteps] = React.useState(true)
  const [totalProductEmissions, setTotalProductEmissions] = React.useState<number | undefined>()
  const [mass, setMass] = React.useState<number | null | undefined>()
  const [selectedProduct, setSelectedProduct] = React.useState<Product | undefined>()

  React.useEffect(() => {
    const fetchProduct: () => void = async () => {
      if (!factorRequest?.productId) {
        return
      }

      const product = await getProduct(factorRequest.productId)
      setSelectedProduct(product)
    }

    fetchProduct()
  }, [factorRequest])

  const onSubmit = async (): Promise<void> => {
    try {
      if (factorRequest && totalProductEmissions) {
        const attachments = await uploadAttachments()

        await saveFactorRequest({
          ...factorRequest,
          denominatorUnitId: factorRequest.denominatorUnitId,
          value: calculateEmissionFactor(totalProductEmissions, mass ?? 1),
          status: 'SUBMITTED' as FactorRequestStatus,
        })

        const sharedLifecycleDetails: { id: number; value: number }[] = shareProductLifecycleSteps
          ? productLifecycleEmissions
              ?.filter((lifecycleStep) => lifecycleStep?.value !== undefined)
              ?.map((lifecycleStep) => ({
                id: lifecycleStep.id,
                value: lifecycleStep.value as number,
              })) || []
          : []
        if (factorRequest?.id && selectedProduct?.id) {
          await createFactorRequestResponse({
            factorRequestId: factorRequest.id,
            productId: selectedProduct?.id,
            value: totalProductEmissions / (mass ?? 1),
            message: comment,
            productLifecycleStepDetails: sharedLifecycleDetails,
            attachments: attachments.map(({ path, name }) => ({ path, name })),
          })
        }
      }
    } catch (error) {
      message.error('suppliers.error')
    }
  }

  React.useEffect(() => {
    const fetchProductEmissions = async (): Promise<void> => {
      if (selectedProduct) {
        const productEmissions = await getProductEmissions(selectedProduct.id)
        setTotalProductEmissions(productEmissions?._sum?.value)
        setMass(selectedProduct.mass)

        const productLogEntries = await getProductLogEntry(selectedProduct.id)
        const lifecycleSteps = await getLifecycleSteps([
          LifecycleStepsKey.PRODUCT_CONFIGURATION,
          LifecycleStepsKey.RAW_MATERIALS_ACQUISITION,
          LifecycleStepsKey.PRODUCTION,
          LifecycleStepsKey.PACKAGING,
          LifecycleStepsKey.DISTRIBUTION_AND_STORAGE,
          LifecycleStepsKey.USAGE,
          LifecycleStepsKey.QUANTITIES,
          LifecycleStepsKey.END_OF_LIFE,
        ])
        if (lifecycleSteps) {
          // Lifecycyle steps from product lifecycle emissions
          const newProductLifecycleEmissions = productLogEntries.map((logEntry) => ({
            key: (lifecycleSteps.find((step) => step.id == logEntry.productlifecycleStepId)?.key ||
              '') as string,
            value: (logEntry.productEmissions as ProductEmission[]).reduce(
              (acc: number, curr: ProductEmission) => acc + curr.value,
              0,
            ),
            id: logEntry.productlifecycleStepId,
          })) as IProductLifecycleEmission[]

          // merge the two arrays and remove lifecycle steps from factor request that are already in product lifecycle emissions
          // The order of the merging is important
          const mergedProductLifecycleEmissions = newProductLifecycleEmissions.reduce(
            (acc, curr) => {
              if (!acc[curr.key]) {
                acc[curr.key] = { key: curr.key, value: curr.value || 0, id: curr.id }
              } else {
                acc[curr.key].value = (acc[curr.key].value || 0) + (curr.value || 0)
              }
              return acc
            },
            {} as Record<string, IProductLifecycleEmission>,
          )

          // Sort final results by id
          const finalProductLifecycleEmissions = _.orderBy(
            mergedProductLifecycleEmissions,
            'id',
            'asc',
          )
          setProductLifecycleEmissions(finalProductLifecycleEmissions)
        }
      }
    }
    fetchProductEmissions()
  }, [selectedProduct])

  React.useEffect(() => {
    // Set lifecycle steps based on the factor request if not set already
    if (factorRequest && !productLifecycleEmissions) {
      const lifecycleSteps = factorRequest?.productLifecycleSteps?.map((step) => ({
        key: step.key,
        value: undefined,
        id: step.id,
      }))
      setProductLifecycleEmissions(lifecycleSteps)
    }
  }, [factorRequest])

  const goToCreatePage = async (): Promise<void> => {
    const navigationState = {
      from: `${location.pathname}${location.search}`,
      selectedSteps: factorRequest?.productLifecycleSteps?.map(
        (productLifecycleStep) => productLifecycleStep.key,
      ),
      denominatorUnitId: factorRequest?.denominatorUnit?.id,
      productName: factorRequest?.activityDataSource?.name,
    }

    if (selectedProduct) {
      navigate(
        routes.share.supplierEngagement.stepsEdit
          .replace(':id', selectedProduct.id.toString())
          .replace(':factorRequestId', `${factorRequest?.id}`),
        {
          state: { ...navigationState, from: `${location.pathname}${location.search}` },
        },
      )
    } else {
      const limit = getLimit(organization, 'products')
      if (limit && productsCount && productsCount >= limit.max) {
        setSubscribeModalSettings({
          closable: true,
          title: t('subscription.upgrade-modal.title-limit', {
            limit: limit.max,
            item: t('products.title'),
          }),
          visible: true,
        })
        return
      }

      navigate(
        routes.share.supplierEngagement.stepsCreate.replace(
          ':factorRequestId',
          `${factorRequest?.id}`,
        ),
        {
          state: navigationState,
        },
      )
    }
  }

  return {
    shareProductLifecycleSteps,
    productLifecycleEmissions,
    totalProductEmissions,
    mass,
    selectedProduct,
    setShareProductLifecycleSteps,
    goToCreatePage,
    onSubmit,
  }
}
