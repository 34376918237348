import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ImgCrop from 'antd-img-crop'
import { Col, Form, Row, Spin, Upload, UploadProps, message } from 'antd/es'
import { RcFile, UploadFile } from 'antd/es/upload/interface'

import { isEqual } from 'lodash-es'

import { Organization } from '@cozero/models'
import { routes } from '@cozero/utils'

import Button from '@/atoms/Button'
import CustomInputNumber from '@/atoms/CustomInputNumber'
import InputField from '@/atoms/InputField'
import Select from '@/atoms/Select'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAdminContext } from '@/contexts/admin'
import { useAppSelector } from '@/redux'
import { getIsAdmin, selectUserOrganization } from '@/redux/auth'
import { useGetSignedUrlMutation, useUploadFileToSignedUrlMutation } from '@/redux/files'
import { useGetIndustriesQuery } from '@/redux/industries/api'
import { useUpdateOrganizationMutation } from '@/redux/organizations'

import classes from './classes.module.less'

interface OrganizationFormInputFields {
  name: string | undefined
  address: string | null
  industry: {
    id: number | undefined
  }
  totalEmployees: number | null
  url: string | null
}

const OrganizationInfo = (): JSX.Element => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const organization = useAppSelector(selectUserOrganization)
  const isAdmin = useAppSelector(getIsAdmin)
  const { data: industries } = useGetIndustriesQuery()
  const [updateOrganization] = useUpdateOrganizationMutation()
  const { loading } = useAdminContext()
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [isImageTouched, setIsImageTouched] = useState(false)
  const [getSignedUrl] = useGetSignedUrlMutation()
  const [uploadImageToSignedUrl] = useUploadFileToSignedUrlMutation()
  const [initialOrganizationFormInfo, setInitialOrganizationFormInfo] =
    useState<OrganizationFormInputFields>()
  const [hasOrganizationInfoChanged, setHasOrganizationInfoChanged] = useState(false)

  const getImageSignedUrl = async (
    newFileList: UploadFile<unknown>[],
  ): Promise<{ name: string; fileObj?: RcFile; signedUrl: string; path: string }[]> => {
    if (!isImageTouched || newFileList.length === 0) {
      return []
    }
    const urls = await getSignedUrl(newFileList.map(({ name }) => name)).unwrap()
    return [
      ...newFileList.map((x, index) => ({
        name: x.name,
        fileObj: x.originFileObj,
        signedUrl: urls?.[index].signedUrl ?? '',
        path: urls?.[index].path ?? '',
      })),
    ]
  }

  const onUpload = ({ fileList: newFileList }: { fileList: UploadFile<unknown>[] }): void => {
    if (newFileList.length > 1) {
      newFileList.shift()
    }
    setFileList(newFileList)
    setIsImageTouched(true)
  }

  const onSubmitOrganizationInfo = async (values: Organization): Promise<void> => {
    try {
      const filesToUpload = isImageTouched
        ? await getImageSignedUrl(fileList.filter((x) => x.status === 'done'))
        : []

      if (filesToUpload.length) {
        await uploadImageToSignedUrl(filesToUpload[0])
      }

      const organization = await updateOrganization({
        name: values.name,
        address: values.address ?? '',
        url: values.url ?? '',
        industryId: values.industry?.id,
        totalEmployees: values.totalEmployees ?? 0,
        image: filesToUpload.length ? filesToUpload[0].path : undefined,
      }).unwrap()

      if (organization) {
        setHasOrganizationInfoChanged(false)
        setIsImageTouched(false)
        return message.success(t('settings.organization.success'))
      }
    } catch (error) {
      return message.error(t('settings.organization.update-failed'))
    }
  }

  const onRemove = (): void => {
    setIsImageTouched(true)
  }

  function beforeUpload(file: RcFile): boolean {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(t('photo.upload-format'))
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error(t('photo.upload-size'))
    }
    return isJpgOrPng && isLt2M
  }

  const onPreview = async (file: UploadFile<unknown>): Promise<void> => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as Blob)
        reader.onload = () => resolve(reader.result as string)
      })
    } else {
      const image = new Image()
      image.src = src
      const imgWindow = window.open(src)
      imgWindow?.document.write(image.outerHTML)
    }
  }

  const onOrganizationInfoChange = (): void => {
    const formValuesEqualToInitial = isEqual(form.getFieldsValue(), initialOrganizationFormInfo)
    if (!formValuesEqualToInitial || isImageTouched) {
      setHasOrganizationInfoChanged(true)
    } else {
      setHasOrganizationInfoChanged(false)
    }
  }

  // To circumvent default behavior of Upload from antd
  const dummyRequest: UploadProps['customRequest'] = ({ file, onSuccess }) => {
    // Sending to the end of the stack to make sure the component state is already up to date
    setTimeout(() => {
      onSuccess && onSuccess(file)
    })
  }
  useEffect(() => {
    if (organization) {
      const { address, industry, name, totalEmployees, url } = organization
      setInitialOrganizationFormInfo({
        address,
        industry: { id: industry?.id },
        name,
        totalEmployees,
        url,
      })
      if (organization.logo?.url) {
        setFileList([
          {
            uid: organization.logo.id?.toString(),
            url: organization.logo.url || '',
            size: organization.logo.size ?? 0,
            name: organization.logo.name || '',
            type: '',
            originFileObj: {} as RcFile,
          },
        ])
      }
    }
  }, [organization])

  useEffect(() => {
    if (!isAdmin) {
      navigate(routes.organizationSettings.users.base, { replace: true })
    }
  }, [])

  useEffect(() => {
    form.resetFields()
  }, [industries])

  useEffect(() => {
    if (isImageTouched) {
      onOrganizationInfoChange()
    }
  }, [isImageTouched])

  return (
    <Spin spinning={!organization}>
      <Row>
        <Col span={24}>
          <Form
            layout="vertical"
            onValuesChange={onOrganizationInfoChange}
            initialValues={organization}
            form={form}
            onFinish={onSubmitOrganizationInfo}
          >
            <Row className={classes.section}>
              <Col span={24}>
                <Title as="h1" size="sm">
                  {t('settings.organization.title')}
                </Title>
              </Col>
            </Row>
            <Row className={classes.section}>
              <Col xs={24} xl={12}>
                <Row gutter={16}>
                  <Col span={18}>
                    <Form.Item
                      label={t('name')}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: t('settings.organization.name-required'),
                        },
                      ]}
                    >
                      <InputField />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={t('settings.organization.id')}>
                      <InputField disabled value={organization?.id} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label="Logo">
                  <ImgCrop rotate minZoom={0.1}>
                    <Upload
                      accept=".png,.jpeg,.jpg"
                      fileList={fileList}
                      onChange={onUpload}
                      onRemove={onRemove}
                      name="logo"
                      listType="picture-card"
                      beforeUpload={beforeUpload}
                      onPreview={onPreview}
                      multiple={false}
                      customRequest={dummyRequest}
                    >
                      + {t('photo.upload')}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
                <Form.Item label={t('settings.organization.address')} name="address">
                  <InputField />
                </Form.Item>
                <Form.Item label={t('settings.organization.url')} name="url">
                  <InputField />
                </Form.Item>
                <Form.Item label={t('settings.organization.employees')} name="totalEmployees">
                  <CustomInputNumber />
                </Form.Item>
                <Form.Item label={t('settings.organization.industry')} name={['industry', 'id']}>
                  <Select
                    optionLabelProp="label"
                    options={industries?.map((industry) => ({
                      value: industry.id,
                      label: industry.name,
                    }))}
                  />
                </Form.Item>
                {isAdmin && (
                  <Form.Item>
                    <Button
                      loading={loading}
                      disabled={!hasOrganizationInfoChanged}
                      type="primary"
                      htmlType="submit"
                      action="save organization info"
                      category={AnalyticsCategories.ORGANISATION}
                    >
                      {t('actions.save')}
                    </Button>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  )
}

export default OrganizationInfo
