import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineCalendar } from 'react-icons/hi'

import { Row } from 'antd'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import paywallModalImg from '@/assets/paywall/paywall-modal-image.svg'
import { AnalyticsCategories } from '@/constants/analyticsCategories'

import Modal, { ModalProps } from '../Modal'

import classes from './PaywallModal.module.less'

type Props = ModalProps & {
  modalTitle: string
  description: string | ReactNode
  onClick: () => void
  image?: string
}

const PaywallModal = ({ modalTitle, description, image, onClick, ...rest }: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Modal hideFooter {...rest} className={classes.modal}>
      <div className={classes.wrapper}>
        <Row justify={'center'} align={'middle'}>
          <img src={image ?? paywallModalImg} />
        </Row>
        <div className={classes.content}>
          <Row justify={'center'}>
            <Title size="xs"> {modalTitle}</Title>
          </Row>
          <Row justify={'center'}>
            <Text color={'secondary'} size="xl" className={classes.description}>
              {description}
            </Text>
          </Row>
        </div>
        <Row justify={'center'}>
          <Button
            type="primary"
            prefixIcon={<HiOutlineCalendar />}
            onClick={onClick}
            category={AnalyticsCategories.FEATURE_LOCK}
            action={'schedule-call-cta'}
          >
            {t('paywall.schedule-call')}
          </Button>
        </Row>
      </div>
    </Modal>
  )
}

export default PaywallModal
