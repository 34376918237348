import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HiOutlineBookOpen,
  HiOutlineCheckCircle,
  HiOutlineEye,
  HiOutlineXCircle,
} from 'react-icons/hi'

import { Empty, Row, Spin } from 'antd'

import { CalculationData, ChangelogStatus, Page } from '@cozero/models'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'

import emptyTable from '@/assets/empty-table.svg'
import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useFetchCalculationsQuery } from '@/redux/methodologyChangelogsV2'

import CalculationCardList from './CalculationCardList'
import classes from './classes.module.less'

const ChangelogGroups = ({
  changelogs,
}: {
  changelogs: Page<CalculationData> | undefined
}): ReactElement => (
  <>
    <CalculationCardList
      changelogs={changelogs}
      icon={<HiOutlineEye size={21} color="2871FF" />}
      translationPrefix="calculation-changes-v2.to-review"
      status={[ChangelogStatus.NEW, ChangelogStatus.CALCULATING]}
      limit={10}
      emptyState={<ToReviewEmptyState />}
    />
    <CalculationCardList
      changelogs={changelogs}
      icon={<HiOutlineCheckCircle size={21} color="2AA803" />}
      translationPrefix="calculation-changes-v2.recalculated"
      status={ChangelogStatus.CALCULATED}
      limit={10}
      readonly
    />
    <CalculationCardList
      changelogs={changelogs}
      icon={<HiOutlineXCircle size={21} color="EF4444" />}
      translationPrefix="calculation-changes-v2.rejected"
      status={ChangelogStatus.REJECTED}
      limit={10}
      readonly
    />
  </>
)

const ToReviewEmptyState = (): ReactElement => {
  const { t } = useTranslation('common')
  return (
    <Empty
      image={emptyTable}
      imageStyle={{ height: 108 }}
      className={classes.categoryEmptyStateContainer}
      description={
        <Row justify="center" className={classes.emptyStateItems}>
          <Text fontWeight="semibold" color="secondary" className={classes.title}>
            {t('calculation-changes-v2.to-review-empty-state.title')}
          </Text>
          <Text mode="text" color="secondary" size="lg">
            {t('calculation-changes-v2.to-review-empty-state.description')}
          </Text>
          <Button
            type="primary"
            prefixIcon={<HiOutlineBookOpen size={16} />}
            iconClass={classes.buttonIcon}
            onClick={() => window.open(t(`intercom.recalculations.calculation-changes`), '_blank')}
            className={classes.noEventButton}
          >
            {t('calculation-changes-v2.learn-more-button.title')}
          </Button>
        </Row>
      }
    ></Empty>
  )
}

const CalculationPageEmptyState = (): ReactElement => {
  const { t } = useTranslation('common')

  return (
    <div className={classes.fullPageEmptyStateContainer}>
      <h2>{t('calculation-changes-v2.page-empty-state.title')}</h2>
      <p>{t('calculation-changes-v2.page-empty-state.description')}</p>
      <Button
        type="primary"
        prefixIcon={<HiOutlineBookOpen size={16} />}
        iconClass={classes.buttonIcon}
        onClick={() => window.open(t(`intercom.recalculations.calculation-changes`), '_blank')}
        className={classes.noEventButton}
      >
        {t('calculation-changes-v2.learn-more-button.title')}
      </Button>
    </div>
  )
}

export const CalculationChangesV2 = (): ReactElement => {
  const user = useAppSelector(selectUser)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)

  if (!selectedBusinessUnit) {
    throw new Error('Business unit is not selected')
  }

  const {
    data: changelogs,
    isFetching: loadingNewItems,
    error,
  } = useFetchCalculationsQuery({
    businessUnitId: selectedBusinessUnit.id,
    organisationId: user?.organizationId as number,
  })

  if (error) {
    throw new Error('Failed to load recalculations')
  }

  const showEmptyState = changelogs?.total === 0

  return (
    <Spin spinning={loadingNewItems}>
      {showEmptyState ? <CalculationPageEmptyState /> : <ChangelogGroups changelogs={changelogs} />}
    </Spin>
  )
}
