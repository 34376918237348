import React, { ReactElement } from 'react'
import Tree from 'react-d3-tree'
import { useTranslation } from 'react-i18next'
import { HiOutlineExternalLink } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import { Col, Row, Space } from 'antd/es'

import Icon from '@ant-design/icons'

import { routes } from '@cozero/utils'

import DateRangePickerFilter from '@/molecules/DateRangePickerFilter'

import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { usePermissions } from '@/hooks/usePermissions'
import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetFullLogRangeQuery } from '@/redux/logs'

import classes from './Organigram.module.less'
import { useBusinessUnits } from './Organigram.useBusinessUnits'
import { useCenteredTree } from './Organigram.useCenteredTree'
import { useOrganigramData } from './Organigram.useOrganigramData'
import { OrganigramExportDropdown } from './OrganigramExportDropdown/OrganigramExportDropdown'
import { OrganigramWrapper } from './OrganigramWrapper'
import RectSvgNode from './RectSvgNode'

const Organigram = (): ReactElement => {
  const { t } = useTranslation('common')
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const { hasPermissions: hasOrganizationPermissions } = usePermissions([
    'ORGANIZATION_SETTINGS_EDIT',
  ])

  const {
    graphData,
    isFetchingBusinessUnit,
    isErrorBusinessUnit,
    refetchBusinessUnit,
    isFetchingEmissions,
    isErrorEmissions,
    filters = [],
    setFilters,
  } = useOrganigramData()

  const { data: logRange } = useGetFullLogRangeQuery(
    { selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1 },
    { skip: !selectedBusinessUnit },
  )
  const { translate, containerRef } = useCenteredTree()
  const { onBusinessUnitChange } = useBusinessUnits()

  return (
    <>
      <Row className={classes.section}>
        <Col span={24}>
          <Title as="h1" size="sm">
            {t('organigram.title')}
          </Title>
        </Col>
        <Col xxl={12} xl={12} lg={16} span={24}>
          <Text size="xl" color="secondary">
            {t('organigram.subtitle')}
          </Text>
        </Col>
        {hasOrganizationPermissions && (
          <Col span={24} className={classes.linkWrapper}>
            <Link to={routes.settings.organization}>
              <Space align="center">
                <Icon component={() => <HiOutlineExternalLink size={16} />} />
                {t('organigram.organizationLink')}
              </Space>
            </Link>
          </Col>
        )}
      </Row>

      <Row align="middle" justify="end" className={classes.filters} gutter={[16, 8]}>
        <Col>
          <DateRangePickerFilter
            disabled={isFetchingEmissions || isErrorEmissions}
            saveFilters={setFilters}
            filters={filters}
            logRange={logRange}
          />
        </Col>

        <Col>
          <Row align={'middle'}>
            <OrganigramExportDropdown
              disabled={
                isFetchingBusinessUnit ||
                isErrorBusinessUnit ||
                isFetchingEmissions ||
                isErrorEmissions
              }
              data={graphData}
              filters={filters}
            />
          </Row>
        </Col>
      </Row>
      <div data-cy="view-organigram-table" className={classes.contentSection} ref={containerRef}>
        <OrganigramWrapper
          isErrorBusinessUnit={isErrorBusinessUnit}
          isLoadingBusinessUnit={isFetchingBusinessUnit || !graphData.length || !translate}
          refetchBusinessUnit={refetchBusinessUnit}
        >
          <Tree
            data={graphData}
            orientation="vertical"
            pathFunc="step"
            separation={{ siblings: 1, nonSiblings: 3 }}
            pathClassFunc={() => classes.nodeLink}
            shouldCollapseNeighborNodes
            collapsible
            translate={translate}
            renderCustomNodeElement={(nodeProps) => (
              <RectSvgNode
                {...nodeProps}
                isFetchingEmissions={isFetchingEmissions}
                isErrorEmissions={isErrorEmissions}
                onBusinessUnitChange={onBusinessUnitChange}
              />
            )}
            zoomable
            initialDepth={1}
            zoom={0.75}
            scaleExtent={{ min: 0.1, max: 1 }}
            nodeSize={{ x: 370, y: 300 }}
          />
        </OrganigramWrapper>
      </div>
    </>
  )
}

export default Organigram
