import React, { ReactNode, memo } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import { Col, Row, Skeleton, Space } from 'antd'

import classNames from 'classnames'

import Text from '@/atoms/Text'

import classes from './Pill.module.less'

interface Props {
  children: ReactNode
  color?:
    | 'blue'
    | 'blue-10'
    | 'red'
    | 'green'
    | 'orange'
    | 'cinder-blue'
    | 'cinder-blue-10'
    | 'cinder-blue-80'
    | 'cinder-blue-10-80'
    | 'yellow-20'
    | 'gray'
  size?: 'xs' | 'sm' | 'md'
  className?: string
  icon?: JSX.Element
  textClasses?: string
  isLoading?: boolean
}

const Pill = ({
  children,
  color = 'blue',
  size = 'md',
  className,
  icon,
  textClasses,
  isLoading = false,
}: Props): ReactElement => {
  const wrapperClassNames = classNames(
    classes.pillWrapper,
    classes[color],
    { [classes.withIcon]: icon },
    className,
  )

  const textClassNames = classNames(classes.pill, classes[color], classes[size], textClasses)

  return (
    <>
      {!isLoading ? (
        <Row className={wrapperClassNames}>
          {icon ? (
            <Space size={4}>
              <Col className={textClassNames}>
                <span>{icon}</span>
              </Col>
              {children && <Text className={textClassNames}>{children}</Text>}
            </Space>
          ) : (
            <Text className={textClassNames}>{children}</Text>
          )}
        </Row>
      ) : (
        <Skeleton.Input className={classes.loading} size="small" active={true}></Skeleton.Input>
      )}
    </>
  )
}

export default memo(Pill)
